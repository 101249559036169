import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { changeLogin } from "../../redux/actions/auth";
import { createProfile, getProfile } from "../../redux/actions/profile";
import { PROFILE_UPDATED } from "../../redux/actionTypes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProfileInput from "./ProfileInput";
import { Redirect } from "react-router-dom";
import SelectAvatar from "./SelectAvatar";
import Particles from "react-particles-js";

const Profile = (props) => {
  let {
    profileAvatar,
    profileName,
    profilePosition,
    profileLocation,
    isAuthenticated,
    history,
  } = props;

  // console.log(props);

  let nameStorage;
  let emailStorage = localStorage.email;

  useEffect(() => {
    getProfile();
    // console.log("get profile");
  }, []);

  // console.log("prpofile loaded");

  let avatarUser, positionUser, locationUser, websiteUser, skillsUser, bioUser;

  if (profileAvatar !== undefined) avatarUser = profileAvatar;
  if (profileName !== undefined) nameStorage = profileName;
  if (profilePosition !== undefined) positionUser = profilePosition;
  if (profileLocation !== undefined) locationUser = profileLocation;

  const [profileUpdated, setProfileUpdated] = useState(false);

  // fix
  // websiteUser = user.user.website;
  // skillsUser = user.user.skills;
  //    bioUser = user.user.bio;

  const [name, setName] = useState(nameStorage);
  const [email, setEmail] = useState(emailStorage);
  const [website, setWebsite] = useState(websiteUser);
  const [skills, setSkills] = useState(skillsUser);
  const [location, setLocation] = useState(locationUser);
  const [bio, setBio] = useState(bioUser);
  const [position, setPosition] = useState(positionUser);
  const [avatar, setAvatar] = useState(avatarUser);

  const [skillsError, setSkillsError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [positionError, setPositionError] = useState("");

  const errors = () => {
    let errors = false;

    if (name == "") {
      setNameError("name");
      errors = true;
    } else {
      setNameError("");
    }
    if (position == "") {
      setPositionError("position");
      errors = true;
    } else {
      setPositionError("");
    }
    if (location == "") {
      setLocationError("location");
      errors = true;
    } else {
      setLocationError("");
    }

    if (email == "") {
      setEmailError("noemail");
      errors = true;
    } else {
      setEmailError("");
    }

    // console.log(errors);
    return errors;
  };

  // create profile
  const submitProfile = async (formSubmitEvent) => {
    formSubmitEvent.preventDefault();

    if (!errors()) {
      // console.log("submit profile");

      try {
        // console.log(
        //   website,
        //   skills,
        //   location,
        //   bio,
        //   position,
        //   avatar,
        //   name,
        //   email
        // );

        if ((name !== undefined, email !== undefined)) changeLogin(name, email);

        createProfile(website, skills, location, bio, position, avatar);

        history.push("/Panel");
      } catch (err) {
        // console.error(err);
      }
    }
  };

  const styleWrapper = {
    position: "relative",
  };

  const styleContent = {
    position: " relative",
    zIndex: "100",
  };

  const styleBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "0",
  };

  return (
    <div style={styleWrapper}>
      <motion.div
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 0,
        }}
        animate={{ opacity: 1 }}
        transition={{
          opacity: { duration: 0.5 },
        }}
        className="h-full w-full flex justify-center"
        style={styleContent}
      >
        <form
          onSubmit={(e) => submitProfile(e)}
          style={{ maxWidth: "1200px" }}
          className="w-full text-left m-20"
        >
          <div className="tracking-wide font-bold text-white text-5xl ">
            Personal Information
          </div>

          <motion.LazyLoad
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            transition={{
              opacity: { duration: 0.5 },
            }}
            className="mt-20 ml-1"
          >
            <div className="tracking-wide font-bold text-white text-3xl">
              Contact Info
            </div>

            <div className="my-3">
              <div className=" tracking-wide font-thin text-white text-xl mx-1 my-3 overflow-hidden">
                Avatar
              </div>
              <div className="">
                <SelectAvatar avatar={avatar} setAvatar={setAvatar} />
              </div>
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-3">
              <ProfileInput
                name="Name"
                placeholder="name"
                value={name}
                setValue={setName}
                required={true}
                error={nameError}
                startValue=""
              />

              <ProfileInput
                name="Email"
                placeholder="email"
                value={email}
                setValue={setEmail}
                type={"email"}
                required={true}
                error={emailError}
              />
            </div>
          </motion.LazyLoad>

          <motion.LazyLoad
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            transition={{
              opacity: { duration: 0.5 },
            }}
            className="mt-20 ml-1"
          >
            <div className="tracking-wide font-bold text-white text-3xl">
              Basic Info
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-3">
              <ProfileInput
                name="Position"
                placeholder="position"
                value={position}
                setValue={setPosition}
                type={"text"}
                required={true}
                error={positionError}
              />

              <ProfileInput
                name="Website"
                placeholder="website"
                value={website}
                setValue={setWebsite}
              />
              <ProfileInput
                name="Skills"
                placeholder="skills (separate by commas)"
                value={skills}
                setValue={setSkills}
                type={"text"}
                error={skillsError}
              />
              <ProfileInput
                name="Location"
                placeholder="location"
                value={location}
                setValue={setLocation}
                type={"text"}
                required={true}
                error={locationError}
              />
              <ProfileInput
                name="Bio"
                placeholder="bio"
                value={bio}
                setValue={setBio}
              />
            </div>
            <button
              className=" m-8 mt-10 h-16 flex-grow tracking-wide bg-primary-red-200 text-lg font-bold  text-white rounded-xl  shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none"
              type="submit"
              style={{ width: "90%" }}
            >
              Submit
            </button>
          </motion.LazyLoad>
        </form>
      </motion.div>

      <Particles style={styleBackground}></Particles>
    </div>
  );
};

const mapStateToProps = (state) => {
  let ProfileName, ProfileAvatar, ProfilePosition, ProfileLocation;

  if (state.profile.profile.user) {
    if (state.profile.profile.user.user)
      ProfileName = state.profile.profile.user.user.name;
    ProfileAvatar = state.profile.profile.user.avatar;
    ProfilePosition = state.profile.profile.user.position;
    ProfileLocation = state.profile.profile.user.location;
    // .log(
    //   "PROFLIE",
    //   ProfileAvatar,
    //   ProfileName,
    //   ProfilePosition,
    //   ProfileconsoleLocation
    // );
  }

  if (ProfileAvatar == undefined) ProfileAvatar = 0;

  return {
    profileAvatar: ProfileAvatar,
    profileName: ProfileName,
    profilePosition: ProfilePosition,
    profileLocation: ProfileLocation,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(withRouter(Profile));
