import React, { useState, useEffect } from "react";
import Heading from "./Heading/Heading";
import SearchBar from "./SearchBar/SearchBar";
import CardList from "./CardLists/CardList";
import Calendar from "./Calendar/Calendar";
import LazyLoad from "react-lazyload";
import User from "./User/User";
import Particles from "react-particles-js";
import { connect } from "react-redux";

import { getProfile } from "../../redux/actions/profile";
import { loadUser } from "../../redux/actions/auth";

const Panel = ({ setToken }) => {
  const [showColumn, setShowColumn] = useState(false);
  const [loadPanel, setLoadPanel] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (setToken) {
      loadUser();
      setLoadPanel(true)
    }
  }, [setToken]);

  const styleWrapper = {
    position: "relative",
  };

  const styleContent = {
    position: " relative",
    zIndex: "100",
  };

  const styleBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "0",
  };


  return (loadPanel ? <div style={styleWrapper}>
    <div
      className="h-full grid lg:grid-cols-3 xl:grid-cols-4 grid-cols-2"
      style={styleContent}
    >
      <LazyLoad className="col-start-1 col-end-4 xl:col-start-1 xl:col-end-4 mb-10 sm:mb-0">
        <User />
      </LazyLoad>

      {/* Heading */}
      <LazyLoad className="col-start-1 col-end-4 xl:col-start-1 xl:col-end-4">
        <Heading showColumnMenu={(show) => setShowColumn(show)} />
      </LazyLoad>

      {/* Search */}
      <LazyLoad className="col-start-1 col-end-4 xl:col-start-1 xl:col-end-4">
        <SearchBar />
      </LazyLoad>

      {/* Calendar */}
      <div className="xl:border-l border-opacity-50 border-white col-start-1 col-end-4 xl:col-start-4 xl:col-end-5 lg:col-end-4 xl:row-start-1 xl:row-end-6 mb-5">
        <Calendar />
      </div>

      {/* CardList */}
      <div className="col-start-1 col-end-4 flex justify-center">
        <CardList
          showColumn={showColumn}
          hideShowColumn={(show) => setShowColumn(show)}
        />
      </div>
    </div>

    <Particles style={styleBackground}></Particles>
  </div> : <div></div>)


};

const mapStateToProps = (state) => {
  return {
    setToken: state.auth.setToken,
  };
};

export default connect(mapStateToProps)(Panel);
