import React from "react";
import { motion } from "framer-motion";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { register, login } from "../../redux/actions/auth";
import "./style.css";
import Checkbox from "./Checkbox";
import Particles from "react-particles-js";

const items = ["Remember Me"];

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount = () => {
    this.selectedCheckboxes = new Set();
  };

  demoLogin = () => {
    // console.log("DEMO LOGIN")
    login({ email: "danish@danishanwer.com", password: "123123" });
  };

  static getDerivedStateFromProps(props, state) {
    if (state.prevPropsList !== props) {
      if (props.emailError == true)
        return {
          prevPropsList: props,
          emailError: true,
          propsEmailError: props.emailError,
          passwordError: false,
          propsPasswordError: props.passwordError,
        };

      if (props.passwordError == true) {
        return {
          prevPropsList: props,
          emailError: false,
          propsEmailError: props.emailError,
          passwordError: true,
          propsPasswordError: props.passwordError,
        };
      }

      if (props.emailError == false || props.passwordError == false) {
        return {
          prevPropsList: props,
          emailError: false,
          propsEmailError: props.emailError,
          passwordError: false,
          propsPasswordError: props.passwordError,
        };
      }
    }

    return {
      prevPropsList: props,
    };
  }

  toggleCheckbox = (label, isChecked) => {
    this.setState({ rememberMe: isChecked });
  };

  handleFormSubmit = async (formSubmitEvent) => {
    formSubmitEvent.preventDefault();

    try {
      login({
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.rememberMe,
      });

      this.setState({
        password: "",
        emailError: "",
        passwordError: "",
      });
    } catch (err) {
      // console.error(err);
    }
  };

  createCheckboxes = () => items.map(this.createCheckbox);

  createCheckbox = (label) => (
    <Checkbox
      label={label}
      handleCheckboxChange={this.toggleCheckbox}
      key={label}
    />
  );

  render() {
    if (this.props.isAuthenticated == true) this.props.history.push("/Panel");

    const styleWrapper = {
      position: "relative",
      height: "800px",
      width: "",
    };

    const styleContent = {
      position: " relative",
      zIndex: "100",
      height: "100vh",
      width: "",
    };

    const styleBackground = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "0",
    };

    return (
      <div style={styleWrapper}>
        <motion.div
          initial={{
            y: 15,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
          }}
          style={styleContent}
        >
          <div className="flex flex-col items-center justify-center mt-36 mb-52">
            <div className=" mb-24 tracking-wide font-bold text-white text-5xl w-96">
              Login
            </div>
            <div className=" flex items-center">
              <form onSubmit={this.handleFormSubmit}>
                {/* Email */}
                <div className="mb-3">
                  <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Email
                  </div>
                  <input
                    value={this.state.email}
                    type="email"
                    className="flex-grow w-96 bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="email"
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        emailError: false,
                        passwordError: false,
                      })
                    }
                    required
                  />
                  {this.state.emailError && (
                    <div>
                      <div className="font-extralight p-1 text-red-500">
                        There is no account associated with the email address
                      </div>

                      <Link
                        to="/SignUp"
                        className="hvr-underline-from-left p-1 font-extralight text-white cursor-pointer	"
                      >
                        Sign Up
                      </Link>
                    </div>
                  )}
                </div>

                {/* Password */}
                <div className="mb-12">
                  <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Password
                  </div>
                  <input
                    value={this.state.password}
                    type="password"
                    className="flex-grow w-96 bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        passwordError: false,
                      })
                    }
                    required
                  />
                  {this.state.passwordError && (
                    <div className="font-extralight p-1 text-red-500 outline-none">
                      wrong password
                    </div>
                  )}
                </div>

                {this.createCheckboxes()}

                <div className="flex flex-col">
                  <button
                    className="tracking-wide bg-primary-red-200 text-lg text-white rounded shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none flex-grow-0 px-10 py-5 my-5 w-96"
                    type="submit"
                  >
                    Login
                  </button>

                  <button
                    className="tracking-wide bg-primary-red-200 text-lg text-white rounded shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none flex-grow-0 px-10 py-5 mt-3 mb-5 w-96"
                    onClick={() => this.demoLogin()}
                  >
                    Demo Login
                  </button>
                </div>
                <div className="flex-grow tracking-wide font-extralight  pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none flex items-center">
                  Create an account
                  <Link
                    to="/SignUp"
                    className="ml-3 text-white bg-transparent py-1 px-2 rounded-lg hover:bg-white hover:text-primary-red-200 hover:shadow-white-lg duration-200 cursor-pointer	"
                  >
                    Sign Up
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </motion.div>

        <Particles
          width={"95vw"}
          height={"800px"}
          style={styleBackground}
        ></Particles>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    passwordError: state.auth.passwordError,
    emailError: state.auth.emailError,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { register })(Login);
