import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = (props) => {
  let { path, isAuthenticated, component } = props;
  
  if (!isAuthenticated) return <Redirect to="/login" />;
  else return <Route path={path} component={component} />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
