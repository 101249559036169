import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import mapStyle from "./MapStyle";
import { motion } from "framer-motion";

const mapStyles = {
  width: "100%",
  height: "100%",
};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false, // Hides or shows the InfoWindow
    activeMarker: {}, // Shows the active marker upon click
    selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: mapStyle,
    });
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    return (
      <div style={{ zIndex: 10 }} className="  h-screen bg-primary-purple-500">
        <motion.div
          initial={{
            opacity: 0,
            y: 30,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            opacity: { duration: 0.5, delay: 1 },
          }}
          className="absolute w-full h-full z-30"
        >
          <div className="flex items-center justify-center w-full h-full">
            <div className="text-primary-purple-200 font-bold text-center text-xl p-3 bg-primary-purple-100 rounded-2xl absolute w-44 mb-40">
              Todoser Office
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ opacity: 1 }}
          transition={{
            opacity: { duration: 0.5, delay: 0.5 },
          }}
          className="absolute w-full h-full z-30"
        >
          <div className="flex items-center justify-center w-full h-full">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                className="h-24 w-24 z-40"
                fill="#bba2db"
              >
                <g>
                  <path d="M142.752,437.13c30.45,8.602,70.669,13.34,113.248,13.34s82.798-4.737,113.248-13.34   c37.253-10.523,56.142-25.757,56.142-45.275c0-19.519-18.889-34.751-56.142-45.274c-17.023-4.809-37.108-8.403-58.944-10.644   c42.761-72.986,85.136-155.627,85.136-196.498C395.44,62.552,332.888,0,256,0S116.56,62.552,116.56,139.439   c0,40.87,42.374,123.509,85.135,196.495c-21.836,2.241-41.918,5.837-58.942,10.647c-37.253,10.523-56.142,25.756-56.142,45.274   C86.61,411.373,105.499,426.606,142.752,437.13z M256,30c60.346,0,109.44,49.094,109.44,109.439   c0,30.996-38.352,112.248-102.59,217.35c-2.122,3.472-5.498,3.846-6.851,3.846c-1.352,0-4.729-0.374-6.851-3.846   c-64.238-105.102-102.59-186.353-102.59-217.35C146.56,79.094,195.654,30,256,30z M156.355,373.988   c17.885-4.555,39.314-7.752,62.413-9.415c1.603,2.644,3.198,5.266,4.783,7.859c6.966,11.398,19.096,18.201,32.448,18.201   c13.353,0,25.482-6.804,32.448-18.201c1.585-2.594,3.181-5.216,4.784-7.86c23.099,1.663,44.527,4.861,62.413,9.416   c27.416,6.983,37.432,14.844,39.491,17.866c-2.06,3.023-12.074,10.884-39.49,17.866c-27.213,6.932-62.602,10.749-99.646,10.749   s-72.433-3.817-99.645-10.749c-27.416-6.982-37.431-14.844-39.49-17.866C118.924,388.832,128.939,380.972,156.355,373.988z" />
                  <path d="M468.329,358.972c-7.263-3.989-16.382-1.336-20.369,5.924c-3.989,7.261-1.337,16.381,5.924,20.369   C471.752,395.081,482,405.963,482,415.121c0,11.201-15.87,28.561-60.413,43.694C377.582,473.767,318.775,482,256,482   s-121.582-8.233-165.587-23.185C45.87,443.683,30,426.322,30,415.121c0-9.158,10.248-20.04,28.116-29.857   c7.261-3.988,9.913-13.108,5.924-20.369c-3.989-7.26-13.106-9.913-20.369-5.924C23.749,369.916,0,388.542,0,415.121   c0,20.374,14.012,49.422,80.762,72.1C127.794,503.2,190.028,512,256,512s128.206-8.8,175.238-24.779   c66.75-22.678,80.762-51.726,80.762-72.1C512,388.542,488.251,369.916,468.329,358.972z" />
                  <path d="M312.047,129.865c0-30.903-25.143-56.045-56.047-56.045s-56.047,25.142-56.047,56.045   c0,30.904,25.143,56.046,56.047,56.046S312.047,160.77,312.047,129.865z M229.953,129.865c0-14.361,11.685-26.045,26.047-26.045   s26.047,11.684,26.047,26.045c0,14.361-11.685,26.046-26.047,26.046S229.953,144.227,229.953,129.865z" />
                </g>
              </svg>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: 50,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            opacity: { duration: 0.5, delay: 1.5 },
          }}
          className="absolute w-full h-full z-30"
        >
          <div className="flex items-center justify-center w-full">
            <div
              className="absolute -bottom-1 sm:bottom-5 rounded-2xl bg-primary-purple-100 p-8 "
              style={{ maxWidth: 600 }}
            >
              <div className="text-primary-purple-200 font-bold text-xl mb-3">
                Todoser Office
              </div>
              <div className="text-white  font-thin text-lg">
                In the heart of germany's automotive centre we have a small but
                lively office only a 5-minute walk from the Stuggart station.
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ opacity: 1 }}
          transition={{
            opacity: { duration: 0.5, delay: 0.1 },
          }}
          className="block"
          style={{ width: "100%", height: "100vh" }}
        >
          <Map
            google={this.props.google}
            zoom={14}
            style={mapStyles}
            onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
            initialCenter={{
              lat: 48.78232,
              lng: 9.17702,
            }}
            scrollwheel={false}
            disableDefaultUI={true}
            draggable={false}
          ></Map>
        </motion.div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBdtGtpoPMK4xU8N7p0IKYXUdgfBO5UmT0",
})(MapContainer);
