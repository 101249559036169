import React from "react";
import Task from "./Task/Task";

const CalendarDay = ({ width, tasks, month, date }) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let calendarDate = monthNames[month] + " " + date;

  let tasksArr = tasks.map((taskCalendar) => {

    let { category, color, time, summary, task, date, user, id } = taskCalendar;
    
    return (
      <Task
        id={id}
        user={user}
        width={width}
        date={date}
        task={task}
        time={time}
        summary={summary}
        color={color}
        category={category}
      />
    );
  });

  return (
    <div key={month} className="flex-1 ml-5 my-5">
      <div className="flex justify-between items-center">
        <div className="font-bold text-lg text-white text-opacity-60 capitalize">
          {calendarDate}
        </div>
      </div>

      {/* Tasks */}
      <div className="mt-5">{tasksArr}</div>
      <div className="mt-5 mx-1 border-white border-b border-opacity-10"></div>
    </div>
  );
};

export default CalendarDay;
