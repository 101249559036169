import React from "react";
import { getCardArray } from "./CardAnimationDisplay";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import classNames from "classnames";
import CardColumn from "./Card/CardColumn";
import CardColumnEdit from "./Card/CardColumnEdit";

export const CardMotion = (
  index,
  cardNextPrev,
  firstCardDisplayIndex,
  lastCardDisplayIndex,
  cardListWidth,
  widthCol,
  cardDisplay,
  displayCards
) => {
  let x;
  if (index == firstCardDisplayIndex) {
    x = 22;
  } else if (index == lastCardDisplayIndex) {
    x = cardListWidth - widthCol;
  } else {
    let i = cardDisplay.findIndex((e) => index == e);
    x = (cardListWidth / displayCards) * i + 20;
  }

  let card = getCardArray(index);

  // console.log("CARD ", card);

  
  // console.log("width col " + widthCol);

  // console.log(
  //   "X ",
  //   x,
  //   " WIDTHCOL ",
  //   widthCol,
  //   " DISPLAYCARDS ",
  //   displayCards,
  //   " CARDNEXTPREV ",
  //   cardNextPrev
  // );

  // console.log("CARDNEXTPREV ", cardNextPrev);

  if (cardNextPrev == 0) {
    return (
      <motion.div
        key={index}
        initial={{
          x: x,
          opacity: 0,
          zIndex: 1,
        }}
        exit={{
          x: -widthCol * displayCards,
          opacity: 1,
          zIndex: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          opacity: {
            duration: 0.5,
          },
        }}
        style={{
          position: "absolute",
          left: 0,
          overflowY: "scroll",
          overflowX: "hidden",
          height: " 800px",
          width: widthCol,
        }}
      >
        <div style={{ width: widthCol }} className={classNames(card.className)}>
          <CardColumn
            width={widthCol}
            data={card.data}
            colTitle={card.colTitle}
            colTitleNum={card.colTitleNum}
          />
        </div>
      </motion.div>
    );
  }

  if (cardNextPrev == 1)
    return (
      <motion.div
        key={index}
        initial={{
          x: widthCol * displayCards,
          opacity: 1,
          zIndex: 0,
        }}
        exit={{
          x: -widthCol * displayCards,
          opacity: 1,
          zIndex: 0,
        }}
        animate={{
          x: x,
          zIndex: 1,
          opacity: 1,
        }}
        transition={{
          x: {
            type: "spring",
            stiffness: 300,
            damping: 30,
          },
          opacity: {
            duration: 0.2,
          },
        }}
        style={{
          position: "absolute",
          left: 0,
          overflowY: "scroll",
          overflowX: "hidden",
          height: " 800px",
          width: widthCol,
        }}
      >
        <div style={{ width: widthCol }} className={classNames(card.className)}>
          <CardColumn
            width={widthCol}
            data={card.data}
            colTitle={card.colTitle}
            colTitleNum={card.colTitleNum}
          />
        </div>
      </motion.div>
    );

  if (cardNextPrev == -1)
    return (
      <motion.div
        key={index}
        initial={{
          x: -widthCol * displayCards,
          opacity: 1,
          zIndex: 0,
        }}
        exit={{
          x: widthCol * displayCards,
          opacity: 1,
          zIndex: 0,
        }}
        animate={{
          x: x,
          zIndex: 1,
          opacity: 1,
        }}
        transition={{
          x: {
            type: "spring",
            stiffness: 300,
            damping: 30,
          },
          opacity: {
            duration: 0.2,
          },
        }}
        style={{
          position: "absolute",
          left: 0,
          overflowY: "scroll",
          overflowX: "hidden",
          height: " 800px",
          width: widthCol,
        }}
      >
        <div style={{ width: widthCol }} className={classNames(card.className)}>
          <CardColumn
            width={widthCol}
            data={card.data}
            colTitle={card.colTitle}
            colTitleNum={card.colTitleNum}
          />
        </div>
      </motion.div>
    );
};

export default CardMotion;
