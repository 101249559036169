import {
  SEARCH_RESULTS,
  SEARCH_RESULTS_FAIL,
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_RECEIVED,
} from "../actionTypes";

const initialState = {
  searchResults: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_RESULTS:
      return {
        ...state,
        searchResults: payload.data,
        searchQuery: payload.searchQuery,
      };

    case SEARCH_RESULTS_FAIL:
      return {
        ...state,
        searchResults: payload,
      };

    case GET_SEARCH_RESULTS:
      return {
        ...state,
        getSearchResults: true,
      };

    case GET_SEARCH_RESULTS_RECEIVED:
      return {
        ...state,
        getSearchResults: false,
      };

    default:
      return state;
  }
}
