import React, { useState } from "react";
import { connect } from "react-redux";
import Card from "./Card";
import LazyLoad from "react-lazyload";
import OutsideAlerter from "./OutsideAlerter";
import CreateCard from "./CreateCard";
import { motion, AnimatePresence } from "framer-motion";
import CardColumnEdit from "./CardColumnEdit";
import { deleteColumn } from "../../../../redux/actions/card";
import { editColumn } from "../../../../redux/actions/card";
import { v4 as uuidv4 } from "uuid";

// GET FROM STATE THE COLUMN CARDS

// dispatch to  column name getColumnData:
// props: propsColumnData

const CardColumn = ({ colTitle, colTitleNum, data, width }) => {
  colTitle = colTitle.replace("_", " ");
  colTitleNum = 0;

  const [showAddCard, setShowAddCard] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [showEditCard, setShowEditCard] = useState(false);

  let cards = [];

  const submitEditMenu = (originalColumn, task) => {
    setShowEditMenu(false);
    setShowEditCard(false);

    // console.log(showEditMenu);
    editColumn(originalColumn, task);
  };

  // console.log(data);

  if (data) {
    data.forEach(
      (
        { avatars, title, summary, tags, clips, _id, user, dateAdd, dateDue },
        index
      ) => {
        if (data.length === 1) {
          colTitleNum = 0;

          cards.push(
            <div
              className="border-opacity-50 bg-opacity-20 bg-white rounded-2xl	border-white border p-10 mt-8 m-5 text-xl text-white"
              style={{ width: width - 50 }}
            >
              No Cards
            </div>
          );
        } else {
          /**
           * All cards for column
           */
          if (title) {
            colTitleNum++;

            cards.push(
              <AnimatePresence key={uuidv4()}>
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 100,
                  }}
                  exit={{
                    opacity: 0,
                    y: 20,
                  }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    opacity: { duration: 0.1 },
                  }}
                >
                  <Card
                    key={uuidv4()}
                    colTitle={colTitle}
                    width={width}
                    key={index}
                    className="ml-5"
                    avatarNum={avatars}
                    dateDue={dateDue}
                    dateAdd={dateAdd}
                    title={title}
                    summary={summary}
                    tags={tags}
                    clips={clips}
                    id={_id}
                    user={user}
                  />
                </motion.div>
              </AnimatePresence>
            );
          }
        }
      }
    );
  }

  return (
    <div
      className="relative overflow-y-scroll overflow-x-hidden"
      style={{
        width: width,
        height: "800px",
      }}
      key={colTitle + "" + colTitleNum}
    >
      <div className="flex justify-between items-center mb-3 mt-5 ml-10 mr-10 h-10">
        <div
          className="font-bold text-white text-2xl whitespace-nowrap	overflow-hidden	overflow-ellipsis"
          style={{ width: width - 180 }}
        >
          {colTitle}
          <span className="text-white opacity-50"> {colTitleNum}</span>
        </div>

        {showEditMenu && (
          <AnimatePresence>
            <OutsideAlerter display={() => setShowEditMenu(false)}>
              <CardColumnEdit
                originalColumn={colTitle}
                submitEditMenu={submitEditMenu}
                width={width}
              />
            </OutsideAlerter>
          </AnimatePresence>
        )}

        {showEditCard && (
          <AnimatePresence>
            <OutsideAlerter display={() => setShowEditCard(false)}>
              <motion.div
                initial={{
                  opacity: 0,
                  y: 10,
                }}
                exit={{
                  opacity: 0,
                  y: 10,
                }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  opacity: { duration: 0.1 },
                }}
                className="w-10 h-10 -ml-20 -mt-10"
              >
                <div
                  onClick={() => {
                    //delete col
                    deleteColumn(colTitle);
                    setShowEditCard(false);
                    // console.log("DETELE COLUMN");
                  }}
                  className="hover:bg-red-200 hover:bg-opacity-30 duration-150 cursor-pointer  hover:text-opacity-100 hover:text-red-800 text-opacity-60 text-white border-white border-b bg-white bg-opacity-5 h-10 w-20 rounded-t-xl text-right font-semibold text-lg py-1 px-2"
                >
                  Delete
                </div>
                <div
                  className="hover:bg-opacity-30 duration-150 cursor-pointer  hover:text-opacity-100 text-opacity-60 text-white bg-white bg-opacity-5 h-10 w-20 text-lg font-semibold   rounded-b-lg text-right py-1 px-2"
                  onClick={() => {
                    // show edit
                    setShowEditMenu(true);
                  }}
                >
                  Edit
                </div>
              </motion.div>
            </OutsideAlerter>
          </AnimatePresence>
        )}

        {!showEditCard && (
          <div className="flex items-center">
            <div
              className="cursor-pointer"
              onClick={() => setShowEditCard(true)}
            >
              <svg
                id="Capa_1"
                enableBackground="new 0 0 515.555 515.555"
                height="512"
                viewBox="0 0 515.555 515.555"
                width="512"
                className="fill-white w-4 h-4 opacity-75 mr-5 hover:opacity-100 transition duration-200"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
                <path d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
                <path d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
              </svg>
            </div>

            {/* / plus */}
            {!showEditCard && (
              <div>
                <div
                  className="rounded-full h-8 w-8 bg-primary-red-200 flex items-center justify-center hover:shadow-red transition duration-200 cursor-pointer"
                  onClick={() => setShowAddCard(true)}
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    className="h-3 w-3 fill-white"
                  >
                    <g>
                      <g>
                        <path
                          d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>

                {/* <OutsideAlerter display={() => setShowAddCard(false)}>
                  <AnimatePresence>
                    {showAddCard && (
                      <CreateCard
                        setShowAddCard={setShowAddCard}
                        width={width}
                        colTitle={colTitle}
                      />
                    )}
                </OutsideAlerter> */}

                {showAddCard && (
                  <OutsideAlerter display={() => setShowAddCard(false)}>
                    <AnimatePresence>
                      <CreateCard
                        setShowAddCard={setShowAddCard}
                        width={width}
                        colTitle={colTitle}
                      />
                    </AnimatePresence>
                  </OutsideAlerter>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div>{cards}</div>
    </div>
  );
};

export default CardColumn;
