import api from "../../utils/api";
import {
  GET_PROFILE,
  CREATE_PROFILE,
  GET_PROFILE_FAIL,
  CREATE_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  GET_PROFILE_ID,
  GET_PROFILE_ID_FAIL,
} from "../actionTypes";
import store from "../store";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Get User Profile
export const getProfile = async () => {
  try {
    // console.log("GET PROFILE");
    const res = await api.get("/profile/me");

    store.dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });

    // console.log("PROFILE GET");
  } catch (err) {
    // console.log(err.response);

    store.dispatch({
      type: GET_PROFILE_FAIL,
      payload: err.response,
    });
  }
};

// Create Profile
export const createProfile = async (
  website,
  skills,
  location,
  bio,
  position,
  avatar
) => {
  const body = JSON.stringify({
    website,
    skills,
    location,
    bio,
    position,
    avatar,
  });
  // console.log(body);

  try {
    const res = await api.post("/profile", body);
    store.dispatch({
      type: CREATE_PROFILE,
      payload: res.data,
    });

    // console.log("CRETE PROFILE");
  } catch (err) {
    // console.log("ERR", err.response);

    store.dispatch({
      type: CREATE_PROFILE_FAIL,
    });
  }
};

let maxFailedUpdateProfileRequests = 10;
let totalFailedUpdateProfileRequests = 0;
let callUpdateProfileRequests = true;

// update profile
export const updateProfile = async (
  website,
  skills,
  bio,
  position,
  location,
  avatar
) => {
  if (totalFailedUpdateProfileRequests < maxFailedUpdateProfileRequests) {
    if (callUpdateProfileRequests) {
      const body = JSON.stringify({
        website,
        skills,
        bio,
        position,
        location,
        avatar,
      });
      try {
        // console.log("UPDATE PROFILE");
        const res = await api.post("/profile/register", body);
        // console.log(res.data);
        store.dispatch({
          type: UPDATE_PROFILE,
          payload: res.data,
        });
        // console.log(res.data);
      } catch (err) {
        // console.log(err);
        store.dispatch({
          type: UPDATE_PROFILE_FAIL,
        });
        totalFailedUpdateProfileRequests = totalFailedUpdateProfileRequests + 1;
      }
      callUpdateProfileRequests = false;
      await sleep(300);
      callUpdateProfileRequests = true;
    }
  }
};

// profile id
export const getProfileId = async (id) => {
  try {
    // console.log(" PROFILE ID");
    const res = await api.get(`/profile/user/${id}`);

    // console.log(res.data);

    store.dispatch({
      type: GET_PROFILE_ID,
      payload: { profile: res.data.profile[0], user: res.data.user[0] },
    });
  } catch (err) {
    // console.log(err);
    store.dispatch({
      type: GET_PROFILE_ID_FAIL,
    });
  }
};
