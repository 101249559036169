import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="z-50 bg-primary-purple-500 flex flex-col lg:flex-row items-center justify-center w-full md:h-40 h-52">
      <div className="flex flex-col md:flex-row items-center justify-center md:-mt-5 md:mb-3">
        <Link to="/" className="flex items-center mb-1">
          {/* logo */}
          <div className="relative mt-3 mr-4 w-8 h-6">
            <div className="absolute -top-2 w-8 h-8 bg-primary-red-200 border-primary-red-200 rounded-lg border"></div>
            <div className="absolute top-2 left-4 w-8 h-8 border-primary-yellow-100 rounded-lg border filter-blur"></div>
          </div>
          {/* Todoser */}
          <div className="ml-5 mt-3 tracking-wide font-bold text-3xl text-white">
            Todo<span className="text-primary-purple-100">ser</span>
          </div>
        </Link>

        <div className="md:ml-10 flex justify-center tracking-wide text-lg mt-8 mb-5">
          <div className="text-white text-opacity-80 hover:text-opacity-100">
            <Link className="mx-2" to="/Products">
              Products
            </Link>
          </div>
          <div className="text-white text-opacity-80 hover:text-opacity-100">
            <Link className="mx-2" to="/Board">
              Board
            </Link>
          </div>

          <div className="text-white text-opacity-80 hover:text-opacity-100">
            <Link className="mx-2" to="/Map">
              Map
            </Link>
          </div>

          <div className="text-white text-opacity-80 hover:text-opacity-100">
            <Link className="mx-2" to="/Chronology">
              Chronology
            </Link>
          </div>

          <div className="text-white text-opacity-80 hover:text-opacity-100">
            <Link className="mx-2" to="/Panel">
              Panel
            </Link>
          </div>
          <div className="text-white text-opacity-80 hover:text-opacity-100">
            <Link to="/Account" className="mx-2">
              Account
            </Link>
          </div>
        </div>
      </div>

      <div className="text-white text-opacity-80 text-xl lg:ml-10 lg:mb-5">
        Danish Dev &#169; 2021
      </div>
    </div>
  );
};

export default Footer;
