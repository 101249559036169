import React, { useEffect } from "react";
import { getProfile } from "../../../redux/actions/profile";
import { getFaces } from "../../../redux/actions/faces";
import { motion } from "framer-motion";
import { connect } from "react-redux";

const User = ({ avatar, faces, name, position, location, setToken }) => {
  useEffect(() => {
    getProfile();
    getFaces();
  }, [setToken]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let date = new Date();
  let month = date.getMonth();
  let day = date.getDate();
  let year = date.getFullYear();

  return (
    <motion.div
      initial={{
        y: 10,
        opacity: 0,
      }}
      exit={{
        opacity: 1,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 1,
        delay: 0,
      }}
      style={{ height: "250px" }}
      className="block md:flex flex-col mt-10 ml-10 mr-10 "
    >
      <div className="flex justify-between">
        <div className="text-white tracking-wide font-bold text-5xl mb-3">
          Welcome to Todoser
        </div>
        <div className="tracking-wide font-bold text-white text-xl">
          {monthNames[month] + " " + day + ", " + year}
        </div>
      </div>

      <div className="text-white tracking-wide font-bold text-3xl mt-3">
        Hello {name},
      </div>

      <motion.div
        initial={{
          y: 10,
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 1,
          delay: 0,
        }}
        className="flex justify-between items-end"
      >
        <div className="tracking-wide font-bold mt-5 text-white text-3xl flex flex-col">
          <div>{position}</div>
          <div className="mt-3">{location}</div>
        </div>

        <div className="flex-shrink-0 mx-1 w-28 h-28 bg-white	overflow-hidden text-white bg-transparent rounded-full  text-primary-red-200  hover:shadow-purple shadow-purple-lg duration-200  border-purple-500 border-4">
          <img className="w-28 h-28" src={faces[avatar].avatar} />
        </div>
      </motion.div>
    </motion.div>
  );
  // else return null;
};

const mapStateToProps = (state) => {
  let name = "";

  if (state.auth)
    if (state.auth.user)
      if (state.auth.user.name) {
        name = state.auth.user.name;
      } else if (state.auth.user.name.name) name = state.auth.user.name.name;

  let avatar = 0;
  let position;
  let location;

  if (state.profile)
    if (state.profile.profile)
      if (state.profile.profile.user) {
        if (state.profile.profile.user.avatar)
          avatar = state.profile.profile.user.avatar;
        if (state.profile.profile.user.position) {
          position = state.profile.profile.user.position;
          location = state.profile.profile.user.location;
        }
      }

  if (typeof name !== "string") name = "";

  // user.user.name
  // user.avatar
  //user.position
  //user.location

  // console.log(state);

  let ProfileName, ProfileAvatar, ProfilePosition, ProfileLocation;

  if (state.profile.profile.user) {
    if (state.profile.profile.user.user)
      ProfileName = state.profile.profile.user.user.name;
    ProfileAvatar = state.profile.profile.user.avatar;
    ProfilePosition = state.profile.profile.user.position;
    ProfileLocation = state.profile.profile.user.location;
    // console.log(
    //   "PROFLIE",
    //   ProfileAvatar,
    //   ProfileName,
    //   ProfilePosition,
    //   ProfileLocation
    // );
  }

  if (ProfileAvatar == undefined) ProfileAvatar = 0;

  return {
    avatar: ProfileAvatar,
    name: ProfileName,
    position: ProfilePosition,
    location: ProfileLocation,
    isAuthenticated: state.auth.isAuthenticated,
    faces: state.faces.faces,
    setToken: state.auth.setToken,
    error: state.profile.error,
  };
};

export default connect(mapStateToProps)(User);
