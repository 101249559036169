import React, { useState, useRef, useEffect, useFocus } from "react";
import { motion } from "framer-motion";
import "./cardStyle.css";
import { createColumn } from "../../../../redux/actions/card";

const CreateColumn = ({ width, showColumnMenu }) => {
  const inputRef = useRef(null);
  const [column, setColumn] = useState("");

  // console.log("CREATECOLUMN", width);
  width = width - 50;

  useEffect(() => {
    inputRef.current.addEventListener("load", inputFocus());
  }, [inputRef.current]);

  const inputFocus = () => {
    // console.log("inputfocus");
    inputRef.current.focus();
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(event);
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (column !== "") {
      createColumn(column);
      // console.log("create column");
      showColumnMenu(false);
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 100,
      }}
      exit={{
        opacity: 0,
        y: 100,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        opacity: { duration: 0.1 },
      }}
      style={{
        backdropFilter: "blur(5px)",
        width: width - 100 + 25,
        height: "250px",
      }}
      className=" z-20 rounded-b-2xl rounded-t-2xl  bg-white bg-opacity-20 border-opacity-50 rounded-2xl	border-white border p-5"
    >
      <div
        className="justify-self-end absolute top-3 rounded-b-2xl rounded-t-2xl shadow-2xl left-3 z-20 text-white	bg-white bg-opacity-10 flex flex-col justify-center items-center"
        style={{
          backdropFilter: "blur(10px)",
          width: width - 100,
          height: "225px",
        }}
      >
        <div className="m-2 my-3 text-3xl font-bold">Create Column</div>
        <input
          style={{ width: width - 150 }}
          type="text"
          className="focus:outline-none my-1 p-2 bg-transparent border text-lg border-white rounded-md"
          value={column}
          onChange={(e) => {
            setColumn(e.target.value);
          }}
          ref={inputRef}
          onKeyDown={onKeyDown}
        />
        <button
          className="text-center tracking-wide bg-primary-red-200 text-xl font-bold py-2 mt-6 ml-2 text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none"
          type="submit"
          style={{ width: "260px" }}
          onClick={(e) => onSubmit(e)}
        >
          Submit
        </button>
      </div>
    </motion.div>
  );
};

export default CreateColumn;
