import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Numbers from "./Numbers";
import ProductDescription from "./ProductDescription";
import ProductCompanies from "./ProductCompanies";
import ProductServices from "./ProductServices";
import LazyLoad from "react-lazyload";
import PageScroll from "./PageScroll";
import { useHistory } from "react-router-dom";
import Particles from "react-particles-js";
import { ReactComponent as ArrowIcon } from "./icons/ArrowIcon.svg";

const Products = () => {
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showProductCompanies, setShowProductCompanies] = useState(false);
  const [showProductServices, setShowProductServices] = useState(false);

  const history = useHistory();
  const handleOnClick = () => history.goBack();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styleWrapper = {
    position: "relative",
  };

  const styleContent = {
    position: " relative",
    zIndex: "100",
  };

  const styleBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "0",
  };

  return (
    <div style={styleWrapper}>
      <div className="flex flex-col" style={styleContent}>
        <div>
          {/* Heading */}
          <motion.div
            initial={{
              y: 20,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0,
            }}
            className="m-12 tracking-wide font-bold text-white text-5xl flex items-center"
          >
            <div className="mr-5" onClick={() => handleOnClick()}>
              <ArrowIcon />
            </div>
            Products
          </motion.div>

          <LazyLoad className="mt-20">
            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              className="font-thin tracking-wide text-white text-3xl m-12 text-left md:text-center"
            >
              Trusted by millions of entrepreneurs and creatives
            </motion.div>

            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: 0.4,
              }}
            >
              <Numbers />
            </motion.div>
          </LazyLoad>

          <LazyLoad>
            <ProductDescription />
          </LazyLoad>
          <LazyLoad>
            <ProductCompanies />
          </LazyLoad>
          <LazyLoad>
            <ProductServices />
          </LazyLoad>
        </div>
      </div>

      <Particles style={styleBackground}></Particles>

      <PageScroll
        setShowProductDescription={setShowProductDescription}
        showProductDescription={showProductDescription}
        setShowProductCompanies={setShowProductCompanies}
        showProductCompanies={showProductCompanies}
        setShowProductServices={setShowProductServices}
        showProductServices={showProductServices}
      />
    </div>
  );
};

export default Products;
