import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import "./cardStyle.css";
import { editCard } from "../../../../redux/actions/card";

const CreateCardEdit = ({
  width,
  CardTask,
  CardSummary,
  CardTags,
  CardDate,
  showEditMenu,
  colTitle,
  id,
}) => {
  const [task, setTask] = useState(CardTask);
  const [summary, setSummary] = useState(CardSummary);
  const [currDate, setCurrDate] = useState();
  const [tags, setTags] = useState(CardTags);
  const [taskRequired, setTaskRequired] = useState(false);
  const [summaryRequired, setSummaryRequired] = useState(false);
  const [focus, setFocus] = useState(0);

  const taskRef = useRef(null);
  const summaryRef = useRef(null);
  const tagsRef = useRef(null);
  const dateRef = useRef(null);
  const submitRef = useRef(null);

  // console.log("CREATECOLUMN", width);
  width = width - 50;

  useEffect(() => {
    taskRef.current.addEventListener("load", taskRef.current.focus());
  }, [taskRef.current]);

  useEffect(() => {
    // console.log("CURRDAET", CardDate);
    let date = new Date(CardDate);
    let year = date.getFullYear();
    let month = minTwoDigits(date.getMonth() + 1);
    let day = minTwoDigits(date.getDate());
    date = year + "-" + month + "-" + day;
    setCurrDate(date);
  }, []);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      if (focus === 0) {
        setFocus(1);
        summaryRef.current.focus();
      } else if (focus === 1) {
        setFocus(2);
        tagsRef.current.focus();
      } else if (focus === 2) {
        setFocus(3);
        dateRef.current.focus();
      } else if (focus === 3) {
        setFocus(4);
        onSubmit(event);
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let date = new Date(currDate);
    date.setMinutes(10);
    date.setHours(1);
    date.setDate(date.getDate() + 1);
    // console.log(date);
    let dateISO = date.toISOString();
    // console.log(dateISO);

    if (task !== "" && summary !== "") {
      // console.log(colTitle, task, summary, dateISO, tags);
      editCard(colTitle, task, summary, dateISO, tags, id);
      showEditMenu(false);
    } else {
      if (task === "") {
        // console.log("Task is required");
        setTaskRequired(true);
      }
      if (summary === "") {
        // console.log("Summary is required");
        setSummaryRequired(true);
      }
    }
  };

  const minTwoDigits = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 100,
      }}
      exit={{
        opacity: 0,
        y: 20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        opacity: { duration: 0.1 },
      }}
      style={{
        backdropFilter: "blur(5px)",
        width: width + 10,
        height: "580px",
      }}
      className="justify-self-end absolute left-5 top-20 mt-1 z-10 rounded-b-2xl rounded-t-2xl  bg-white bg-opacity-20 border-opacity-50 rounded-2xl	border-white border p-5"
    >
      <div
        className="justify-self-end absolute top-3 rounded-b-2xl rounded-t-2xl shadow-2xl left-3 z-20 text-white	bg-white bg-opacity-10"
        style={{
          backdropFilter: "blur(10px)",
          width: width - 15,
          height: "550px",
        }}
      >
        <div className="m-2 mt-5 b-5 text-3xl font-bold">Edit Card</div>
        <div className="m-3">
          <div className="flex justify-between items-center">
            <div className="ml-3 text-xl font-semibold">Task</div>
            {taskRequired && (
              <div className="font-light p-1 text-red-500 mr-1">
                Task is Required
              </div>
            )}
          </div>
          <input
            onClick={() => setFocus(0)}
            onKeyDown={onKeyDown}
            ref={taskRef}
            style={{ width: width - 60 }}
            type="text"
            className="focus:outline-none m-1 p-2 bg-transparent border text-lg border-white rounded-md"
            value={task}
            onChange={(e) => {
              setTask(e.target.value);
              setTaskRequired(false);
            }}
          />
        </div>
        <div className="ml-3 mb-2">
          <div className="flex justify-between items-center">
            <div className="ml-3 text-xl font-semibold">Summary</div>
            {summaryRequired && (
              <div className="font-light p-1  text-red-500 mr-1">
                Summary is Required
              </div>
            )}
          </div>
          <textarea
            onClick={() => setFocus(1)}
            onKeyDown={onKeyDown}
            ref={summaryRef}
            style={{ width: width - 60 }}
            rows="2"
            className="resize-none text-lg focus:outline-none -ml-2 p-2 bg-transparent border border-white rounded-md"
            value={summary}
            onChange={(e) => {
              setSummary(e.target.value);
              setSummaryRequired(false);
            }}
          />
        </div>
        <div className="m-3 mb-5">
          <div className="flex justify-between items-center">
            <div className="ml-3 text-xl font-semibold">Tags</div>
            <div className="font-light text-lg p-1 text-white mr-1">
              (separate by comma)
            </div>
          </div>
          <input
            onClick={() => setFocus(2)}
            onKeyDown={onKeyDown}
            ref={tagsRef}
            style={{ width: width - 60 }}
            type="text"
            className="focus:outline-none text-lg m-1 p-2 bg-transparent border border-white rounded-md"
            value={tags}
            onChange={(e) => {
              setTags(e.target.value);
            }}
          />
        </div>

        <div className="ml-3 text-xl text-left">
          <label htmlFor="date" className="ml-3  font-semibold">
            Due Date
          </label>
        </div>

        <input
          onClick={() => setFocus(3)}
          onKeyDown={onKeyDown}
          ref={dateRef}
          style={{ width: width - 60 }}
          className="focus:outline-none m-1 p-2 bg-transparent border text-lg border-white rounded-md ml-2 px-2 py-1 w-52"
          type="date"
          id="date"
          value={currDate}
          onChange={(e) => setCurrDate(e.target.value)}
          name="date"
          ref={dateRef}
        />

        <button
          onKeyDown={onKeyDown}
          ref={submitRef}
          className="text-center tracking-wide bg-primary-red-200 text-lg font-bold py-2 mt-6 ml-2 text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none"
          style={{ width: width - 60 }}
          onClick={(e) => onSubmit(e)}
        >
          Submit
        </button>
      </div>
    </motion.div>
  );
};

export default CreateCardEdit;
