import { GET_FACES, GET_FACES_FAIL } from "../actionTypes";

export default function (state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FACES:
      return {
        ...state,
        faces: payload,
      };
    case GET_FACES_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
