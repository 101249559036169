import React, { useState } from "react";
import { Link } from "react-router-dom";

const ProfileInput = ({
  name,
  placeholder,
  value,
  setValue,
  type = "text",
  required = false,
  error,
}) => {
  return (
    <div className="my-1 md:m-5">
      <div className="col-start-1 col-end-2 tracking-wide font-thin text-white text-xl mx-1 my-3">
        {name}
      </div>

      <input
        value={value}
        className="w-full bg-primary-purple-500 font-extralight align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
      />
      {error && (
        <div className="flex items-center">
          <div className="font-extralight p-1 text-red-500 text-md">
            {error == "skills" && <div>skills is required </div>}
            {error == "location" && <div>location is required</div>}
            {error == "position" && <div>position is required</div>}
            {error == "name" && <div>name is required</div>}
            {error == "noemail" && <div>email is required</div>}
            {error == "email" && <div>User already exists</div>}
            {error == "status" && <div>status is required</div>}
            {error == "nopassword" && <div>password is required</div>}
            {error == "password" && <div>passwords do not match</div>}
          </div>
          {error == "email" && (
            <Link
              to="/Login"
              className="hvr-underline-from-left p-1 ml-1 font-extralight text-white cursor-pointer text-md"
            >
              Sign In
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileInput;
