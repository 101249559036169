import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { register } from "../../redux/actions/auth";
import { withRouter } from "react-router";
import { updateProfile } from "../../redux/actions/profile";
import { Redirect } from "react-router-dom";
import "./style.css";
import Particles from "react-particles-js";
import SelectAvatar from "./SelectAvatar";
import { getFaces } from "../../redux/actions/faces";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      website: "",
      skills: "",

      bio: "",
      position: "",
      location: "",

      positionError: false,
      locationError: false,

      avatar: "",
      faces: "",

      sentValue: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getFaces();
  }

  componentDidUpdate = () => {
    if (this.props.faces && this.state.faces == "") {
      this.setState({ faces: this.props.faces });
    }
  };

  setAvatar = (avatar) => {
    this.setState({ avatar: avatar });
  };

  handleFormSubmit = async (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    if (this.state.position === "" && this.state.location === "") {
      this.setState({
        developerError: true,
        locationError: true,
      });
    } else {
      updateProfile(
        this.state.website,
        this.state.skills,
        this.state.bio,
        this.state.position,
        this.state.location,
        this.state.avatar
      );

      console.log("UPDATE PROFILE");
      this.props.history.push("/Panel");
    }
  };

  render() {
    const styleWrapper = {
      position: "relative",
    };

    const styleContent = {
      position: " relative",
      textAlign: "center",
      zIndex: "100",
    };

    const styleBackground = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "0",
    };

    return (
      <div style={styleWrapper}>
        <motion.div
          initial={{
            y: 15,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
          }}
          style={styleContent}
        >
          <div className="mt-36 mb-52 flex flex-col justify-center items-center">
            {/* Register */}
            <div className=" mb-24 tracking-wide font-bold text-white text-5xl">
              Register
            </div>

            <form onSubmit={this.handleFormSubmit} style={{ width: "80%" }}>
              {/* Picture */}
              <div className="mb-12">
                <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 text-white rounded-xl focus:outline-none">
                  Avatar
                </div>
                {this.state.faces !== "" && (
                  <SelectAvatar
                    avatar={this.state.avatar}
                    faces={this.state.faces}
                    setAvatar={this.setAvatar}
                    sendValue={this.state.sendValue}
                  />
                )}
              </div>

              <div className="grid md:grid-cols-2 xl:grid-cols-3">
                {/* position */}
                <div className="m-3">
                  <div className="text-left flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Position
                  </div>
                  <input
                    value={this.state.position}
                    type="text"
                    className="flex-grow w-full bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="position"
                    onChange={(e) =>
                      this.setState({
                        position: e.target.value,
                        positionError: false,
                      })
                    }
                    required
                  />
                  {this.state.positionError && (
                    <div className="font-extralight p-1 text-red-500 outline-none">
                      Position is required
                    </div>
                  )}
                </div>

                {/* Location */}
                <div className="m-3">
                  <div className="text-left  flex-grow  tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Location
                  </div>
                  <input
                    value={this.state.location}
                    type="text"
                    className="flex-grow w-full  bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="location"
                    onChange={(e) =>
                      this.setState({
                        location: e.target.value,
                        locationError: false,
                      })
                    }
                    required
                  />
                  {this.state.developerError && (
                    <div className="font-extralight p-1 text-red-500 outline-none">
                      Location is required
                    </div>
                  )}
                </div>

                {/* website */}
                <div className="m-3">
                  <div className=" text-left flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Website
                  </div>

                  <input
                    value={this.state.website}
                    className="flex-grow w-full bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="website"
                    type="text"
                    onChange={(e) =>
                      this.setState({
                        website: e.target.value,
                        websiteError: false,
                      })
                    }
                  />
                </div>

                {/* skills */}
                <div className="m-3">
                  <div className="text-left flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Skills
                  </div>
                  <input
                    value={this.state.skills}
                    type="text"
                    className="flex-grow w-full  bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="skills"
                    onChange={(e) =>
                      this.setState({
                        skills: e.target.value,
                        skillsError: false,
                      })
                    }
                  />
                </div>

                {/* Bio */}
                <div className="m-3">
                  <div className="text-left flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Bio
                  </div>
                  <input
                    value={this.state.bio}
                    type="text"
                    className="flex-grow w-full bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="bio"
                    onChange={(e) =>
                      this.setState({
                        bio: e.target.value,
                        bioError: false,
                      })
                    }
                  />
                </div>
              </div>
              <button
                className="ml-5 mt-20 mr-16 h-16 tracking-wide bg-primary-red-200 text-lg font-bold  text-white rounded-xl  shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none"
                type="submit"
                style={{ width: "95%" }}
              >
                Register
              </button>
            </form>
          </div>
        </motion.div>

        <Particles
          width={"95vw"}
          height={"1000px"}
          style={styleBackground}
        ></Particles>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);

  return {
    faces: state.faces.faces,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.profile.error,
  };
};

export default connect(mapStateToProps)(withRouter(Register));
