import { CardMotion } from "./CardMotion";

export const getCardAnimationDisplay = (
  currCard,
  displayCards,
  cardNextPrev,
  cardListWidth,
  widthCol
) => {
  // console.log(
  //   "CURRCARD",
  //   currCard,
  //   "DISPLAYCARDS",
  //   displayCards,
  //   "CARDNEXTPREV",
  //   cardNextPrev
  // );
  // console.log("CARD ANIMATION DISPLAY GETCARDANIMATIONDISPLAY");

// console.log(currCard, displayCards, cardNextPrev)

  let cardDisplayStart = [];
  let cardDisplayEnd = [];
  let cardDisplay = [];

  let cardArray = localStorage.getItem("cardArray");
  cardArray = JSON.parse(cardArray);

  // console.log("CARDARRAY", cardArray);

  // create cardDisplayStart array and cardDisplayEnd array
  cardArray.forEach((card, index) => {
    let cardDisplayLength = cardDisplayStart.length + cardDisplayEnd.length;
    // console.log("index " + index);
    // console.log("currCard " + currCard + " displayCards " + displayCards);
    if (index >= currCard && cardDisplayLength < displayCards) {
      cardDisplayStart.push(index);
    }
    if (
      currCard + displayCards - cardArray.length > 0 &&
      index < currCard + displayCards - cardArray.length
    ) {
      cardDisplayEnd.push(index);
    }
  });

  // first and last index
  let firstCardDisplayIndex;
  let lastCardDisplayIndex;
  if (cardDisplayStart.length == displayCards) {
    cardDisplay = [...cardDisplayStart];
    firstCardDisplayIndex = cardDisplay[0];
    lastCardDisplayIndex = cardDisplay[cardDisplay.length - 1];
  } else if (cardDisplayEnd.length == displayCards) {
    cardDisplay = [...cardDisplayEnd];
    firstCardDisplayIndex = cardDisplay[0];
    lastCardDisplayIndex = cardDisplay[cardDisplay.length - 1];
  } else {
    cardDisplay = [...cardDisplayStart, ...cardDisplayEnd];
    firstCardDisplayIndex = cardDisplayStart[0];
    lastCardDisplayIndex = cardDisplayEnd[cardDisplayEnd.length - 1];
  }

  // console.log(
  //   "CARDDISPLAY",
  //   cardDisplay
  // );

  if (cardArray.length === 1) {
    firstCardDisplayIndex = 0;
    lastCardDisplayIndex = null;
  }

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  // console.log(cardDisplay);

  // console.log("CARD ANIMATION DISPLAY ARRAY ", cardDisplay);
  let cardAnimationDisplay;
  cardAnimationDisplay = cardArray.map((card, index) => {
    if (cardDisplay.includes(index)) {
      return CardMotion(
        index,
        cardNextPrev,
        firstCardDisplayIndex,
        lastCardDisplayIndex,
        cardListWidth,
        widthCol,
        cardDisplay,
        displayCards
      );
    }
  });

  // console.log(cardAnimationDisplay);

  return cardAnimationDisplay;
};

export const sendCardArray = (array) => {
  localStorage.setItem("cardArray", JSON.stringify(array));
  let cardArray = localStorage.getItem("cardArray");
  cardArray = JSON.parse(cardArray);
  // console.log("SAVE CARD ARRAY IN LOCALSTORAGE", cardArray);
};

export const getCardArray = (index) => {
  // console.log("get card array");
  let cardArray = localStorage.getItem("cardArray");
  cardArray = JSON.parse(cardArray);
  // console.log("CARDARRAY", cardArray[index].colTitle);
  return cardArray[index];
};
