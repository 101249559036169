import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import "./cardStyle.css";
import OutsideAlerter from "../Card/OutsideAlerter";
import { deleteCard, addClip, removeClip } from "../../../redux/actions/card";
import CreateCardEdit from "./CreateCardEdit";
import { motion, AnimatePresence } from "framer-motion";
import { getSearchResults } from "../../../redux/actions/search";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      showMenu: false,
      showEdit: false,
    };
  }

  clipsAddRemove = (id) => {
    if (this.props.clips.length === 0) addClip(id);
    else removeClip(id);

    getSearchResults(this.props.searchQuery);
  };

  setShowMenu = (show) => {
    this.setState({ showMenu: show });
  };

  showEditMenu = (show) => {
    this.setState({ showEdit: show });
  };

  render() {
    let {
      title,
      summary,
      avatar,
      tags,
      id,
      clips,
      dateDue,
      dateAdd,
      imgSrc,
      category,
      widthCol,
    } = this.props;

    let searchQuery;

    if (this.props.searchQuery !== undefined)
      searchQuery = this.props.searchQuery

    console.log(this.props)

    let date = new Date();
    let currDate = date.getDate();

    dateDue = new Date(dateDue);

    let barDateDue = new Date(dateDue);
    let barDateAdd = new Date(dateAdd);

    let totaldays = barDateDue.getDate() - barDateAdd.getDate();
    let leftdays = barDateDue.getDate() - currDate;
    let widthBar = widthCol * (leftdays / totaldays);

    if (leftdays == NaN) leftdays = 0;
    if (totaldays == NaN) totaldays = 0;

    if (leftdays <= 0) {
      leftdays = 0;
      widthBar = widthCol;
    }

    console.log("left days ", leftdays, "total days ", totaldays);

    console.log("Width bar", widthBar);

    // console.log("left days ", leftdays, "total days ", totaldays);

    // console.log("Width bar", widthBar);

    // for (let i = 0; i < avatarNum; i++) {
    //   let rndInteger = Math.floor(Math.random() * 6) + 0;
    //   leftDist = 2 + 6 * i;

    //   avatars.push(
    //     <img
    //       className={classNames(
    //         "absolute rounded-full h-8 w-8 mr-4 border-white border hover:shadow-white transition duration-200 fill-white",
    //         `left-${leftDist}`
    //       )}
    //       src={process.env.PUBLIC_URL + avatarImg[rndInteger]}
    //     />
    //   );
    // }

    // use user to get avatar

    let tagsCard = tags;

    if (tags) {
      if (tags[0] !== undefined && tags[0] !== "") tags = tags[0].split(",");
      let oddTag = false;
      tags = tags.map((tag, index) => {
        if (index % 2 !== 0) {
          oddTag = true;
        }
        if (tag !== "")
          return (
            <div
              className={classNames(
                "text-white inline-block p-1 text-lg bg-primary-blue-100 rounded-lg px-3 mt-6 hover:shadow-blue transition duration-200 m-3"
                // `${oddTag ? "ml-3" : ""}`
              )}
            >
              {tag}
            </div>
          );
      });
      tags.push(<div className="inline-block p-1 px-5 mt-6 "></div>);
    }

    return (
      <div
        ref={this.myInput}
        className="p-5"
        key={id}
        style={{ width: widthCol }}
      >
        <div>
          <AnimatePresence>
            {this.state.showEdit && (
              <div className="absolute w-screen" style={{ zIndex: 1000 }}>
                <div className="flex items-center justify-center">
                  <OutsideAlerter display={() => this.showEditMenu(false)}
                    className="relative"
                  >
                    <CreateCardEdit
                      searchQuery={searchQuery}
                      id={id}
                      width={widthCol}
                      category={category}
                      CardTask={title}
                      CardSummary={summary}
                      CardTags={tagsCard[0]}
                      CardDate={dateDue}
                      showEditMenu={this.showEditMenu}
                      id={id}
                    />
                  </OutsideAlerter>
                </div>
              </div>
            )}
          </AnimatePresence>
        </div>
        <div
          style={{ backdropFilter: "blur(5px)", zIndex: '-1000' }}
          className="border-opacity-50 bg-opacity-20 bg-white	rounded-2xl	border-white border p-5"
        >
          <div className="text-white font-bold text-2xl mt-1 mb-5">{title}</div>
          <div className="text-white text-xl opacity-50 mb-10">{summary}</div>
          {imgSrc ? (
            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + imgSrc})`,
              }}
              className="	h-32 mb-10 bg-cover "
            />
          ) : (
            ""
          )}
          <div
            className="relative h-2 rounded-full bg-primary-purple-200"
            style={{ width: `${widthCol - 100}px` }}
          >
            <div
              className={classNames(
                "absolute",
                "left-0",
                "h-2",
                "rounded-full",
                leftdays === 0 ? "bg-primary-red-100" : "bg-primary-yellow-100"
              )}
              style={{ width: `${widthBar - 100}px` }}
            ></div>
          </div>
          <div className="text-left text-lg text-white font-bold mt-3">
            {leftdays}
            <span className="text-white font-normal opacity-50">{" days"}</span>
          </div>

          {tags[0] !== undefined && (
            <div
              className="tags flex items-center overflow-x-scroll h-16 whitespace-nowrap "
              style={{ width: widthCol - 100 }}
            >
              {tags}
            </div>
          )}
          <div className="flex items-center justify-between mt-10">
            <div className="flex items-center">
              <div className="flex items-center w-12 h-6  border-r-2 border-primary-purple-100">
                <img
                  className={classNames(
                    "absolute rounded-full h-8 w-8 mr-4 border-white border hover:shadow-white transition duration-200 fill-white"
                  )}
                  src={avatar}
                />
              </div>
              <div className="flex items-center">
                {/* paper clip */}
                <div>
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 471.641 471.641"
                    className={classNames(
                      clips.length > 0 ? "opacity-100" : "opacity-50",
                      "fill-white w-6 h-6 ml-6 hover:opacity-100 cursor-pointer"
                    )}
                  >
                    <g>
                      <g>
                        <path
                          d="M431.666,49.412c-51.692-50.578-134.33-50.567-186.009,0.025L28.911,266.184c-39.192,40.116-38.443,104.407,1.673,143.599
  			c39.456,38.548,102.47,38.548,141.926,0l196.267-196.267c25.515-25.515,25.515-66.884,0-92.399
  			c-25.515-25.515-66.884-25.515-92.399,0L88.644,308.85c-6.548,6.78-6.36,17.584,0.42,24.132c6.614,6.388,17.099,6.388,23.713,0
  			L300.51,145.249c12.449-11.926,32.209-11.501,44.134,0.948c11.565,12.073,11.565,31.114,0,43.187L148.378,385.65
  			c-26.514,26.137-69.197,25.831-95.334-0.683c-25.873-26.246-25.873-68.405,0-94.651L269.79,73.569
  			c38.608-38.622,101.214-38.633,139.836-0.026c38.622,38.607,38.633,101.214,0.026,139.836L192.905,430.126
  			c-7.159,6.131-7.993,16.905-1.862,24.064c6.131,7.159,16.905,7.993,24.064,1.862c0.668-0.572,1.29-1.194,1.862-1.862
  			l216.747-216.747C485.073,184.954,484.156,100.769,431.666,49.412z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>
               
              </div>
              {/* menu icon */}
            </div>
            <div className="h-4 m-3">
              {!this.state.showMenu && (
                <div
                  className="col-start-3 col-end-4 justify-self-end cursor-pointer hover:opacity-100 transition duration-200 opacity-75"
                  onClick={() => this.setShowMenu(true)}
                >
                  <svg
                    id="Capa_1"
                    enable-background="new 0 0 515.555 515.555"
                    height="512"
                    viewBox="0 0 515.555 515.555"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white ml-6 w-4 h-4"
                  >
                    <path d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
                    <path d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
                    <path d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
                  </svg>
                </div>
              )}
              <OutsideAlerter display={() => this.setShowMenu(false)}>
                <AnimatePresence>
                  {this.state.showMenu && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        y: 0,
                      }}
                      exit={{
                        opacity: 0,
                        y: 0,
                      }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{
                        opacity: { duration: 0.1 },
                      }}
                      className="col-start-3 col-end-4 justify-self-end  absolute right-0 bottom-0 mt-1 "
                    >
                      <div
                        onClick={() => {
                          deleteCard(id);
                          this.setShowMenu(false);
                        }}
                        className="hover:bg-red-200 hover:bg-opacity-30 duration-150 cursor-pointer  hover:text-opacity-100 hover:text-red-800 text-opacity-60 text-white border-white border-b bg-white bg-opacity-5 h-10 w-20 rounded-t-xl text-right font-semibold text-lg py-1 px-2"
                      >
                        Delete
                      </div>
                      <div
                        className="hover:bg-opacity-30 duration-150 cursor-pointer  hover:text-opacity-100 text-opacity-60 text-white bg-white bg-opacity-5 h-10 w-20 text-lg font-semibold   rounded-b-lg text-right py-1 px-2"
                        onClick={() => {
                          // show edit
                          this.showEditMenu(true);
                        }}
                      >
                        Edit
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </OutsideAlerter>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("mapstatetoprops", state);
  return {
    faces: state.faces.faces,
    profile: state.profile.profile,
  };
};

export default connect(mapStateToProps)(Card);