import React from "react";
import { connect } from "react-redux";
import CalendarDay from "./CalendarDay";
import { motion } from "framer-motion";
import "./calendarStyle.css";
import { getCalendarDates } from "../../../redux/actions/calendar";
import { withRouter } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarHeight: null,
      calendarDates: [],
      monthTasksArr: [],
      sentRequest: false,
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.setToken) getCalendarDates();

    this.setupData();
    this.setWidth();
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  componentDidUpdate() {
    if (
      this.props.currRoute == "panel" &&
      this.props.setToken &&
      this.state.sentRequest == false
    ) {
      getCalendarDates();
      this.setState({ sentRequest: true });
    }

    if (
      this.props.currRoute == "panel" &&
      this.props.setToken &&
      this.props.calendarDates !== this.state.calendarDates
    ) {
      this.setState({ calendarDates: this.props.calendarDates });
      this.createMonthTasks();
    }

    if (
      this.state.calendarHeight === null &&
      this.state.monthTasksArr.length === 0
    ) {
      this.setWidth();
    }
  }

  setupData = () => {
    window.addEventListener("load", this.setWidth);
    window.addEventListener("resize", this.setWidth);
    this.calendarRef.current.addEventListener("load", this.setWidth);
    this.calendarRef.current.addEventListener("resize", this.setWidth);
  };

  setWidth = () => {
    let calendarHeight;

    if (this.props.calendarDates !== undefined)
      if (this.props.calendarDates.length === 0) {
        calendarHeight = 50;
      } else if (this.calendarRef.current !== null) {
        if (window.innerWidth > 1280 && this.state.calendarHeight !== 1350) {
          calendarHeight = 1350;
        } else if (
          window.innerWidth <= 1280 &&
          this.state.calendarHeight !== 500
        ) {
          calendarHeight = 500;
        }
        this.setState({
          calendarHeight: calendarHeight,
        });

        this.createMonthTasks();
      }
  };

  createMonthTasks = () => {
    if (this.props.calendarDates)
      if (this.props.calendarDates.length !== 0) {
        let dayArr = [];

        // arr of calendar dates
        for (let date of this.props.calendarDates) {
          let dayAdded = false;
          const time = new Date(date.date);

          let monthInArr;
          let monthIndex;

          dayArr.map((month, index) => {
            if (time.getMonth() === month.month) {
              monthInArr = true;
              monthIndex = index;
            }
          });

          // if month in arr
          if (monthInArr) {
            let dayInArray = false;

            // day in array
            dayArr[monthIndex].day.map((day) => {
              if (day.day === time.getDate()) {
                day.tasks.push({
                  category: date.category,
                  color: date.color,
                  summary: date.summary,
                  task: date.task,
                  time: date.date,
                  user: date.user,
                  id: date._id,
                });

                dayInArray = true;
              }
            });

            // add day to array
            if (!dayInArray) {
              dayArr[monthIndex].day.push({
                day: time.getDate(),
                tasks: [
                  {
                    category: date.category,
                    color: date.color,
                    summary: date.summary,
                    task: date.task,
                    time: date.date,
                    user: date.user,
                    id: date._id,
                  },
                ],
              });
            }
          } else {
            // add month
            dayArr.push({
              month: time.getMonth(),
              day: [
                {
                  day: time.getDate(),
                  tasks: [
                    {
                      category: date.category,
                      color: date.color,
                      summary: date.summary,
                      task: date.task,
                      time: date.date,
                      user: date.user,
                      id: date._id,
                    },
                  ],
                },
              ],
            });
          }
        }

        let calendarArray = [];
        let monthArray = [];

        let width = window.innerWidth;
        if (width > 1280) {
          width = width / 4 - 100;
        } else if (width > 768) {
          width = width / 2 - 100;
        } else {
          width = width - 110;
        }

        // arr of calendar tasks
        dayArr.map((month) => {
          monthArray.push(month.month);

          calendarArray.push(
            month.day.map((tasks) => {
              return (
                <CalendarDay
                  key={uuidv4()}
                  width={width}
                  tasks={tasks.tasks}
                  month={month.month}
                  date={tasks.day}
                />
              );
            })
          );
        });
        // console.log(calendarArray);

        this.setState({ monthTasksArr: calendarArray });
      }
  };

  render() {
    return (
      <motion.div
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{ opacity: 1 }}
        transition={{
          opacity: { duration: 0.5, delay: 1 },
        }}
        ref={this.calendarRef}
        style={{ height: `${this.state.calendarHeight}px` }}
        className="my-10"
      >
        <div className="ml-10 mr-10 xl:mr-5 my-5" style={{}}>
          <div className="font-bold text-white text-2xl mb-5">Calendar</div>
        </div>
        <div
          style={{
            height: `${this.state.calendarHeight}px`,
            overflowY: "scroll",
          }}
          className="mx-5 mt-5 mb-10"
        >
          {this.state.monthTasksArr.length === 0 ? (
            <div className="text-white text-xl text-opacity-60 m-5">
              No calendar dates
            </div>
          ) : (
            <div className="flex flex-row flex-wrap">
              {this.state.monthTasksArr}
            </div>
          )}
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currRoute: state.route.currRoute,
    calendarDates: state.calendar.calendarDates,
    setToken: state.auth.setToken,
  };
};

export default connect(mapStateToProps)(withRouter(Calendar));
