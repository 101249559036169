import { SHOW_COLUMN_EDIT, SHOW_CARD_CREATE } from "../actionTypes";

const initialState = {
  columnEdit: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_COLUMN_EDIT:
      return {
        ...state,
        columnEdit: payload,
      };

    case SHOW_CARD_CREATE:
      return {
        ...state,
        cardCreate: payload,
      };
    default:
      return state;
  }
}
