import React from "react";
import { motion } from "framer-motion";

const ProductCompanies = () => {
  let appleLogo = "/resources/images/apple-logo.png";
  let abcLogo = "/resources/images/abc-logo.png";
  let giletteLogo = "/resources/images/gilette-logo.png";
  let nokiaLogo = "/resources/images/nokia-logo.png";
  let linkedinLogo = "/resources/images/linkedin-logo.png";

  return (
    <motion.div
      initial={{
        y: 20,
        opacity: 0,
      }}
      exit={{
        opacity: 1,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: 0,
      }}
      className="-mt-5"
    >
      <div className="text-center font-thin m-20 tracking-wide  text-white text-5xl mb-28">
        We make your website to help you build your brand.
      </div>
      <div className="flex mt-20 mb-20 items-center justify-evenly bg-primary-purple-100 bg-opacity-50  h-60 overflow-hidden flex-wrap">
        <img
          style={{ width: "150px", height: "100px", objectFit: "contain" }}
          className="m-10"
          src={process.env.PUBLIC_URL + appleLogo}
        />

        <img
          style={{
            width: "150px",
            height: "150px",
            objectFit: "contain",
          }}
          className="m-10"
          src={process.env.PUBLIC_URL + nokiaLogo}
        />
        <img
          style={{
            width: "150px",
            height: "150px",
            objectFit: "contain",
          }}
          className="m-10"
          src={process.env.PUBLIC_URL + giletteLogo}
        />
        <img
          style={{
            width: "150px",
            height: "150px",
            objectFit: "contain",
          }}
          className="m-10"
          src={process.env.PUBLIC_URL + linkedinLogo}
        />
        <img
          style={{
            width: "150px",
            height: "100px",
            objectFit: "contain",
          }}
          className="m-10"
          src={process.env.PUBLIC_URL + abcLogo}
        />
      </div>
    </motion.div>
  );
};

export default ProductCompanies;
