import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

const CardColumnEdit = ({ originalColumn, width, submitEditMenu }) => {
  const [task, setTask] = useState(originalColumn);

  const taskRef = useRef(null);
  const submitRef = useRef(null);

  useEffect(() => {
    taskRef.current.addEventListener("load", taskRef.current.focus());
  }, [taskRef.current]);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(event);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitEditMenu(originalColumn, task);
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 100,
      }}
      exit={{
        opacity: 0,
        y: 100,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        opacity: { duration: 0.1 },
      }}
      style={{
        backdropFilter: "blur(5px)",
        width: width - 50,
        height: "230px",
      }}
      className="justify-self-end absolute left-5 top-20 mt-1 z-10 rounded-b-2xl rounded-t-2xl  bg-white bg-opacity-20 border-opacity-50 rounded-2xl	border-white border p-5"
    >
      <div
        className="justify-self-end absolute top-3 rounded-b-2xl rounded-t-2xl shadow-2xl left-3 z-20 text-white	bg-white bg-opacity-10"
        style={{
          backdropFilter: "blur(10px)",
          width: width - 75,
          height: "200px",
        }}
      >
        <div className="m-3">
          <div className="flex justify-between items-center my-3">
            <div
              className="text-2xl text-center  font-semibold"
              style={{ width: width - 120 }}
            >
              Column Title
            </div>
          </div>
          <input
            onKeyDown={onKeyDown}
            ref={taskRef}
            style={{ width: width - 120 }}
            type="text"
            className="focus:outline-none mx-1 p-2 mt-3 mb-5 bg-transparent border text-lg border-white rounded-md"
            value={task}
            onChange={(e) => {
              setTask(e.target.value);
            }}
          />
          <button
            onKeyDown={onKeyDown}
            ref={submitRef}
            className="text-center tracking-wide bg-primary-red-200 text-xl font-bold py-2  text-white mt-1 rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none"
            style={{ width: width - 130 }}
            onClick={(e) => onSubmit(e)}
          >
            Submit
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default CardColumnEdit;
