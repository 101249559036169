import React from "react";

const ProductItem = ({ heading, text1 }) => {
  return (
    <div className="bg-opacity-5 bg-white p-10	border-white border-opacity-20 border rounded-xl hover:bg-opacity-20 duration-200 hover:border-opacity-20">
      <div className="text-white font-medium text-2xl">{heading}</div>
      <div className="text-white my-5">{text1}</div>
    </div>
  );
};

export default ProductItem;
