import React, { useEffect } from "react";
import classNames from "classnames";
import { getProfileId } from "../../redux/actions/profile";
import { getAllCardsUser } from "../../redux/actions/card";
import { getFaces } from "../../redux/actions/faces";
import { useHistory, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import Card from './Card'

const User = (props) => {
  let {
    location,
    position,
    website,
    skills,
    email,
    name,
    avatar,
    isAuthenticated,
    user,
    faces,
    setToken,
    cards,
  } = props;

  if (avatar == undefined) avatar = 0;

  // console.log(cards);

  //... build cards with cards
 cards = cards.map(card=>{
  return <Card
 
  
  
  
  />

})



  location = "torotno";
  position = "develoepr";
  website = "www.danishanwer.com";
  skills = "qwsdasd,asd,asdas,das";
  email = "danish.asda.amkj@gmail.com";
  name = "Danish Anwer";
  avatar = 0;

  const { id } = useParams();

  useEffect(() => {
    getAllCardsUser(user);
    //  console.log(id);
    getProfileId(id);

    // console.log("GET PROFILE ID");
    getFaces();
  }, [setToken]);

  if (skills) {
    if (skills[0] !== undefined && skills[0] !== "")
      skills = skills[0].split(",");
    skills = skills.map((skill) => {
      if (skill !== "")
        return (
          <div
            className={classNames(
              "text-white text-lg inline-block p-1 bg-primary-blue-100 rounded-lg px-3 mt-6 hover:shadow-blue transition duration-200 m-1"
            )}
          >
            {skill}
          </div>
        );
    });
    skills.push(<div className="inline-block p-1 px-5 mt-6 "></div>);
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let date = new Date();
  let month = date.getMonth();
  let day = date.getDate();
  let year = date.getFullYear();

  if (faces)
    if (faces.length !== 0 && avatar !== undefined)
      return (
        <motion.div
          initial={{
            y: 10,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0,
          }}
          className="h-screen block md:flex flex-col mt-10 ml-10 mr-10 "
        >
          <div className="flex justify-between">
            <div
              className="text-white tracking-wide font-bold text-5xl mb-3 overflow-ellipsis overflow-hidden  "
              style={{ width: "60%" }}
            >
              {name}
            </div>
            <div className="tracking-wide font-bold text-white text-xl">
              {monthNames[month] + " " + day + ", " + year}
            </div>
          </div>

          <div className="text-white tracking-wide font-bold text-3xl mt-3">
            {position}
          </div>

          <motion.div
            initial={{
              y: 10,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            className="flex justify-between items-end"
          >
            <div
              className="tracking-wide font-bold mt-5 text-white text-3xl flex flex-col overflow-ellipsis overflow-hidden "
              style={{ width: "60%" }}
            >
              <div>{location}</div>
              <div className="mt-3 text-xl">{email}</div>
              <div className="text-xl my-3">{website}</div>
              <div className="my-3">{skills}</div>
            </div>

            <div className="flex-shrink-0 mx-1 w-28 h-28 bg-white	overflow-hidden text-white bg-transparent rounded-full  text-primary-red-200  hover:shadow-purple shadow-purple-lg duration-200  border-purple-500 border-4">
              <img className="w-28 h-28" src={faces[avatar].avatar} />
            </div>
          </motion.div>

          <div></div>
        </motion.div>
      );
    else return null;
  else return null;
};

const mapStateToProps = (state) => {
  if (state.profile.profileId) {
    return {
      location: state.profile.profileId.profile.location,
      position: state.profile.profileId.profile.position,
      website: state.profile.profileId.profile.website,
      skills: state.profile.profileId.profile.skills,
      email: state.profile.profileId.user.email,
      name: state.profile.profileId.user.name,
      user: state.profile.profileId.profile.user,
      avatar: state.profile.profileId.profile.avatar,
      isAuthenticated: state.auth.isAuthenticated,
      faces: state.faces.faces,
      setToken: state.auth.setToken,
      cards: state.card.userCards,
    };
  } else return {};
};
export default connect(mapStateToProps)(User);
