import React from "react";
import { connect, useDispatch } from "react-redux";
import * as ActionTypes from "../../../redux/actionTypes";
import { getCalendarDates } from "../../../redux/actions/calendar";

const CalendarButtons = ({ setMonth, date }) => {
  const dispatch = useDispatch();

  const getMonthFromNum = (num) => {
    const MonthEnum = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return MonthEnum[num];
  };

  getCalendarDates();

  date = new Date(date);

  let calendarMonth =
    getMonthFromNum(date.getMonth()) + " " + date.getFullYear();

  return (
    <div className="flex justify-between items-center my-10">
      <button
        onClick={() => {
          setMonth(-1);
        }}
        className="tracking-wide bg-primary-red-200 text-lg text-white rounded-md shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none flex-grow-0	px-10 py-2 ml-5 my-5"
        style={{ width: "200px" }}
      >
        Last {"Month"}
      </button>
      <div className="tracking-wide text-3xl font-bold text-white">
        {calendarMonth}
      </div>
      <button
        onClick={() => {
          setMonth(1);
        }}
        className="tracking-wide bg-primary-red-200 text-lg text-white rounded-md shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none flex-grow-0	px-10 py-2 mr-5 my-5"
        style={{ width: "200px" }}
      >
        Next {"Month"}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    date: state.calendarMonth.date,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMonth: (scroll) =>
      dispatch({
        type: ActionTypes.SET_MONTH,
        payload: scroll,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarButtons);
