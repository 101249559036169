import React, { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { deleteCalendarDate } from "../../../redux/actions/calendar";
import OutsideAlerter from "../OutsideAlerter";
import * as ActionTypes from "../../../redux/actionTypes";

const ColorsEnum = [
  "border-white border border-opacity-20 bg-white bg-opacity-10",
  "bg-primary-red-200 bg-opacity-80",
  "bg-primary-orange-100 bg-opacity-80",
  "bg-primary-yellow-100 bg-opacity-80",
  "bg-blue-300 bg-opacity-80",
];

const DisplayCards = ({
  id,
  x,
  y,
  color,
  task,
  day,
  summary,
  category,
  time,
  display,
  date,

  showEditMenu,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 10,
      }}
      exit={{
        opacity: 0,
        y: 20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        opacity: { duration: 0.1 },
      }}
      className="absolute shadow-2xl border-white border border-opacity-20	rounded-lg px-4 py-3 w-72 bg-primary-purple-300 bg-opacity-100 text-white grid grid-cols-1"
      style={{
        left: x,
        top: y,
        gridTemplateColumns: "minmax(20px, auto) 1fr 1fr",
      }}
    >
      <div
        className={classNames(
          "w-6 h-6 bg-opacity-80 text-sm rounded ml-1 mr-5 mt-2 col-start-0 col-end-1",
          `${ColorsEnum[color]}`
        )}
      ></div>

      <div className="col-start-1 col-end-2 row-start-0 row-end-1 mt-1 text-xl font-bold">
        {task}
      </div>

      <div
        className="col-start-3 col-end-4 justify-self-end cursor-pointer hover:opacity-100 transition duration-200 opacity-75"
        onClick={() => setShowMenu(true)}
      >
        <svg
          id="Capa_1"
          enable-background="new 0 0 515.555 515.555"
          height="512"
          viewBox="0 0 515.555 515.555"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-white ml-6 w-4 h-4 m-3 "
        >
          <path d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
          <path d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
          <path d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" />
        </svg>
      </div>

      <OutsideAlerter display={() => setShowMenu(false)}>
        {showMenu && (
          <div className="col-start-3 col-end-4 row-start-0 row-end-1 justify-self-end  absolute right-0 top-0 mt-1 -mr-3 bg-primary-purple-300">
            <div
              onClick={() => {
                deleteCalendarDate(id);
                setShowMenu(false);
                display(false);
              }}
              className="hover:bg-opacity-30 duration-150 cursor-pointer text-lg hover:text-opacity-100 text-opacity-60 text-white border-white border-b bg-white bg-opacity-5 h-8 w-16 rounded-t-lg text-right py-1 px-2"
            >
              Delete
            </div>
            <div
              className="hover:bg-opacity-30 duration-150 cursor-pointer text-lg hover:text-opacity-100 text-opacity-60 text-white bg-white bg-opacity-5 h-8 w-16 rounded-b-lg text-right py-1 px-2"
              onClick={() => {
                showEditMenu(
                  day,
                  summary,
                  category,
                  task,
                  color,
                  time,
                  id,
                  display,
                  date,
                  false
                );
                setShowMenu(false);
                display(false);
              }}
            >
              Edit
            </div>
          </div>
        )}
      </OutsideAlerter>

      <div className="col-start-1 col-end-2 row-start-1 row-end-2 text-white text-lg text-opacity-60 mb-2">
        {day}
      </div>

      <div className="mt-1 ml-1 col-start-0 col-end-1 row-start-2 row-end-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="401pt"
          viewBox="0 -1 401.52289 401"
          fill="white"
          className="h-6 w-6"
        >
          <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" />
          <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" />
        </svg>
      </div>

      <div className="col-start-1 col-end-2 row-start-2 row-end-3 text-white text-xl text-opacity-80">
        {summary}
      </div>

      {category === "" ? (
        ""
      ) : (
        <div className="col-start-0 col-end-1 row-start-3 row-end-4">
          <div
            className={classNames(
              "h-5 w-5 bg-opacity-50 rounded-xl mt-3 ml-2",
              `${ColorsEnum[color]}`
            )}
          ></div>
        </div>
      )}

      <div className="col-start-1 col-end-2 row-start-3 row-end-4 text-lg text-white text-opacity-60 my-2  ">
        {category === "" ? "" : category}
      </div>


    </motion.div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showEditMenu: (
      day,
      summary,
      category,
      task,
      color,
      time,
      id,
      display,
      date,
      empty
    ) =>
      dispatch({
        type: ActionTypes.SHOW_EDIT_CARD,
        payload: {
          day: day,
          summary: summary,
          category: category,
          task: task,
          color: color,
          time: time,
          id: id,
          display: display,
          date: date,
          empty: empty,
        },
      }),
  };
};

export default connect(null, mapDispatchToProps)(DisplayCards);
