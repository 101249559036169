import React, { useState } from "react";
import classNames from "classnames";
import DisplayCard from "./DisplayCard";
import OutsideAlerter from "../OutsideAlerter";
import { AnimatePresence } from "framer-motion";

const TaskCard = ({
  task,
  setClicked,
  day,
  month,
  year,
  setShowEditMenu,
  setDay,
  setEditSummary,
  setEditCategory,
  setEditTask,
  setEditColor,
  setTime,
  setId,
  setDisplay,
  setEditDate,
  setShowMenu,
}) => {
  const [displayTask, setDisplayTask] = useState(false);

  let color = task.color;
  let name = task.task;
  let summary = task.summary;
  let time = new Date(task.time);
  let id = task._id;
  let category = task.category;
  let date = task.date;
  date = new Date(date)

  const minTwoDigits = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  let cardTime;
  if (time)
    cardTime = year + "-" + minTwoDigits(month) + "-" + minTwoDigits(day);

  const WeekdayEnum = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const MonthEnum = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let cardDate =
    WeekdayEnum[date.getDay()] + ", " + MonthEnum[month] + " " + day;

  const ColorsEnum = [
    "border-white border border-opacity-20 bg-white bg-opacity-10",
    "bg-primary-red-200 bg-opacity-80",
    "bg-primary-orange-100 bg-opacity-80",
    "bg-primary-yellow-100 bg-opacity-80",
    "bg-blue-300 bg-opacity-80",
  ];

  const [displayX, setDisplayX] = useState();
  const [displayY, setDisplayY] = useState();

  const handleEvent = (event) => {
    if (event.clientX + 288 / 2 - window.innerWidth > 0) {
      setDisplayX(window.innerWidth - 300);
    } else if (event.clientX > 288 / 2) {
      setDisplayX(event.clientX - 288 / 2);
    } else if (event.clientX < 288 / 2) {
      setDisplayX(5);
    }

    if (event.clientY < 166) {
      setDisplayY(event.client + 166);
    } else if (window.innerHeight - event.clientY < 166) {
      setDisplayY(window.innerHeight + window.scrollY - 180);
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          setClicked(true);
          setDisplayTask(true);
        }}
        onMouseDown={handleEvent}
        key={time}
        className={classNames(
          "w-full text-sm rounded p-1",
          `${ColorsEnum[color]}`
        )}
      >
        <div className="font-medium">{name}</div>
        <div>{summary}</div>
      </div>

      <OutsideAlerter display={setDisplayTask}>
        <AnimatePresence>
          {displayTask && (
            <DisplayCard
              display={setDisplayTask}
              x={displayX}
              y={displayY}
              color={color}
              task={name}
              day={cardDate}
              category={category}
              summary={summary}
              id={id}
              time={cardTime}
              date={date}
              setShowEditMenu={(show) => setShowEditMenu(show)}
              setDay={setDay}
              setEditSummary={setEditSummary}
              setEditCategory={setEditCategory}
              setEditTask={setEditTask}
              setEditColor={setEditColor}
              setTime={setTime}
              setId={setId}
              setDisplay={setDisplay}
              setEditDate={setEditDate}
              setShowMenu={setShowMenu}
            />
          )}
        </AnimatePresence>
      </OutsideAlerter>
    </div>
  );
};

export default TaskCard;
