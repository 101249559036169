import React from "react";
import { motion } from "framer-motion";

const Heading = ({ showColumnMenu }) => {
  return (
    <motion.div
      initial={{
        y: 10,
        opacity: 0,
      }}
      exit={{
        opacity: 1,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 1,
        delay: 0,
      }}
      className="flex flex-col md:flex-row  items-start 
      md:items-center md:justify-between mx-10 mb-5"
    >
      {/* heading */}
      <div className="text-left tracking-wide font-bold text-white text-3xl">
        Corporate website for ULT Algency
      </div>

      {/* new category */}
      <a
        className="mt-5 md:mt-0 tracking-wide bg-primary-red-200 text-xl font-bold px-5 py-2 text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none h-12 w-full md:w-52 text-center"
        onClick={() => {
          console.log("show column menu");
          showColumnMenu(true);
        }}
        href="#newCategory"
      >
        New Category
      </a>
    </motion.div>
  );
};

export default Heading;
