import React from "react";
import * as ActionTypes from "../../redux/actionTypes";
import { connect } from "react-redux";
import CardList from "./CardLists/CardList";
import SearchBar from "./SearchBar";
import LazyLoad from "react-lazyload";
import { withRouter } from "react-router-dom";
import { motion } from "framer-motion";
import Particles from "react-particles-js";

class Board extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const styleWrapper = {
      position: "relative",
    };

    const styleContent = {
      position: " relative",
      zIndex: "100",
    };

    const styleBackground = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "0",
    };

    return (
      <div style={styleWrapper}>
        <div
          className="h-full grid lg:grid-cols-3 xl:grid-cols-4 grid-cols-2"
          style={styleContent}
        >
          <LazyLoad className="col-start-1 col-end-4 xl:col-start-1 xl:col-end-5 row-start-1 row-end-2">
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                opacity: {
                  duration: 0.5,
                },
              }}
              className="tracking-wide font-bold  text-white text-5xl mt-12 mb-8 ml-12 my-6 flex items-center"
            >
              <div className="mr-5" onClick={() => this.props.history.goBack()}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 219.151 219.151"
                  stroke="white"
                  fill="white"
                  className="w-10 h-10"
                >
                  <g>
                    <path
                      d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575
		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575
		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"
                    />
                    <path
                      d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
		c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"
                    />
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              Board
            </motion.div>
          </LazyLoad>
          <LazyLoad className="col-start-1 col-end-4 xl:col-start-1 xl:col-end-5 row-start-2 row-end-3">
            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: 0.1,
              }}
            >
              <SearchBar />
            </motion.div>
          </LazyLoad>
          <LazyLoad className="col-start-1 col-end-4  xl:col-start-1 xl:col-end-5 row-start-3 row-end-4 ml-3 mr-5 text-center">
            <CardList />
          </LazyLoad>
        </div>

        <Particles style={styleBackground}></Particles>
      </div>
    );
  }
}

export default withRouter(Board);
