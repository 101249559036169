import {
  PROFILE_ERRORS,
  PROFILE_INPUTS,
  GET_PROFILE,
  CREATE_PROFILE,
  GET_PROFILE_FAIL,
  CREATE_PROFILE_FAIL,
  PROFILE_UPDATED,
  ALL_PROFILE_INPUTS,
  UPDATE_PROFILE,
  INITAL_STATE,
  GET_PROFILE_ID,
  GET_PROFILE_ID_FAIL,
} from "../actionTypes";

const initialState = {
  profileUpdated: false,
  profile: {
    avatar: 0,
    date: new Date(),
    location: "",
    position: "",
    skills: [""],
    user: {
      avatar: 0,
      date: new Date(),
      location: "",
      position: "",
      skills: [""],
      user: {
        avatar: "",
        name: "",
      },
    },
  },
  profileInputs: {
    name: "",
    email: "",
    password: "",
    position: "",
    status: "",
    website: "",
    skills: "",
    location: "",
    bio: "",
    allProfileInputs: false,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROFILE_ERRORS:
      return {
        ...state,
        errors: payload,
      };

    case ALL_PROFILE_INPUTS:
      return {
        ...state,
        profileInputs: {
          ...state.profileInputs,
          allProfileInputs: false,
        },
      };

    case GET_PROFILE_ID:
      // console.log(payload);
      return {
        ...state,
        profileId: payload,
      };

    case PROFILE_INPUTS:
      let input = payload.input;
      let value = payload.value;

      var profileInputs = new Object();
      profileInputs[input] = value;

      let allProfileInputs = {
        allProfileInputs: false,
      };

      let profile = {
        ...state.profileInputs,
        ...profileInputs,
        ...allProfileInputs,
      };

      if (
        profile.name !== "" &&
        profile.email !== "" &&
        profile.password !== "" &&
        profile.position !== "" &&
        profile.status !== "" &&
        profile.website !== "" &&
        profile.skills !== "" &&
        profile.location !== "" &&
        profile.bio !== ""
      ) {
        allProfileInputs = {
          allProfileInputs: true,
        };
      }

      return {
        ...state,
        profileInputs: {
          ...state.profileInputs,
          ...profileInputs,
          ...allProfileInputs,
        },
      };

    case GET_PROFILE:
      // console.log(payload);

      state.profile.user = payload;

      // console.log(state.profile);

      return {
        ...state,
        profile: state.profile,
      };
    case CREATE_PROFILE:
      // console.log(payload);
      return {
        ...state,
        profileUpdated: true,
        profile: payload,
      };
    case PROFILE_UPDATED:
      return {
        ...state,
        profileUpdated: false,
      };
    case UPDATE_PROFILE:
      // console.log(payload);
      if (payload.avatar == undefined) payload.avatar = 0;
      // console.log(payload);
      return {
        ...state,
        profile: payload,
      };

    case CREATE_PROFILE_FAIL:
      return {
        ...state,
        profile: null,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
      };

    case INITAL_STATE:
      return {
        profileUpdated: false,
        profile: {},
        profileInputs: {
          name: "",
          email: "",
          password: "",
          position: "",
          status: "",
          website: "",
          skills: "",
          location: "",
          bio: "",
          allProfileInputs: false,
        },
      };
    case GET_PROFILE_ID_FAIL:
    default:
      return state;
  }
}
