import * as ActionTypes from "../actionTypes";

let date = new Date();
date = date.toJSON();

let calendarMonth = (
  state = {
    scroll: 0,
    date: date,
    createCard: false,
    editMenu: {
      empty: true,
    },
    editTask: {
      empty: true,
    },
  },
  action
) => {
  if (action.type === ActionTypes.SHOW_EDIT_TASK) {
    return {
      ...state,
      editTask: action.payload,
    };
  } else if (action.type === ActionTypes.SHOW_CREATE_CARD) {
    return {
      ...state,
      createCard: action.payload,
    };
  } else if (action.type === ActionTypes.SHOW_EDIT_CARD) {
    return {
      ...state,
      editMenu: action.payload,
    };
  } else if (action.type === ActionTypes.SET_MONTH) {
    let scroll = action.payload;
    let date = state.date;
    date = new Date(date);
    let month = date.getMonth();
    if (scroll == undefined) scroll = 0;
    let setmonth = month + scroll;
    date.setMonth(setmonth);
    date = date.toJSON();

    return {
      ...state,
      scroll: scroll,
      date: date,
    };
  }

  return state;
};

export default calendarMonth;
