import React from "react";
import ProductItem from "./ProductItem";
import { motion } from "framer-motion";

const ProductService = () => {
  let ProductItemArr = [
    {
      heading: "Online Store",
      text1: "Sell products and manage fulfillment from one eCommerce platform",
    },
    {
      heading: "Orders & Fulfillment",
      text1: "Manage orders, shipping, and fulfillment from a single dashboard",
    },
    {
      heading: "Online Payments",
      text1: "Give customers flexibility with multiple ways to pay.",
    },
    {
      heading: "Multichannel Sales",
      text1: "Sell across platforms like Facebook & Instagram",
    },
    {
      heading: "Dropshipping",
      text1: "Find print on demand and dropshipping products to sell",
    },
    {
      heading: "Point of Sale",
      text1:
        "Sell online and in-store seamlessly with a unified catalog and inventory",
    },
  ];

  const productArr = ProductItemArr.map((product, index) => {
    return (
      <motion.div
        initial={{
          y: 20,
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.1 * index,
        }}
      >
        <ProductItem
          heading={product.heading}
          text1={product.text1}
        ></ProductItem>
      </motion.div>
    );
  });

  return (
    <motion.div
      initial={{
        y: 20,
        opacity: 0,
      }}
      exit={{
        opacity: 1,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
    >
      <div className="m-12 tracking-wide text-white font-bold text-4xl">
        eCommerce Services
      </div>
      <div className="grid grid-cols-1 text-lg md:grid-cols-2 gap-10 m-20">
        {productArr}
      </div>
    </motion.div>
  );
};

export default ProductService;
