import * as ActionTypes from "../actionTypes";

let date = new Date();
date = date.toJSON();

let initialState = {
  scroll: 0,
  date: date,
  currDisplay: "month",
};

let calendarReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.SET_CATEGORY) {
    let category = action.payload;
    return {
      ...state,
      category: category,
    };
  } else if (action.type === ActionTypes.SET_CURR_DISPLAY) {
    return {
      ...state,
      currDisplay: action.payload,
    };
  } else {
    return state;
  }
};

export default calendarReducer;
