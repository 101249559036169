import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Footer/Footer";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Panel from "./Panel/Panel";
import Home from "./Home/Home";
import Login from "./Login/Login";
import MapContainer from "./Map/MapContainer";
import Chronology from "./Chronology/Chronology";
import Board from "./Board/Board";
import SignUp from "./Login/SignUp";
import store from "../redux/store";
import Register from "./Login/Register";
import Profile from "./Profile/Profile";
import Products from "./Products/Products";
import setAuthToken from "../utils/setAuthToken";
import { loadUser } from "../redux/actions/auth";
import Account from "./Account/Account";
import SearchResults from "./SearchResults/SearchResults";
import PrivateRoute from "./routing/PrivateRoute";
import ReactRouter from "./ReactRouter";
import User from "./User/User";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="bg-gradient-to-r from-primary-purple-700 via-primary-red-300 to-primary-purple-600 h-full">
          {/* NavigationBar */}
          <div className="col-start-1 col-end-4 x:col-start-1 xl:col-end-5">
            <NavigationBar />
          </div>

          <Route exact path="/" component={Home} />

          <Switch>
            <Route exact path="/Login" component={Login} />
            <Route exact path="/SignUp" component={SignUp} />
            <Route exact path="/Map" component={MapContainer} />
            <Route exact path="/Products" component={Products} />
            <PrivateRoute Route exact path="/Register" component={Register} />
            <PrivateRoute exact path="/Profile" component={Profile} />
            <PrivateRoute exact path="/Chronology" component={Chronology} />
            <PrivateRoute exact path="/Panel" component={Panel} />
            <PrivateRoute exact path="/Board" component={Board} />
            <PrivateRoute
              exact
              path="/SearchResults"
              component={SearchResults}
            />
            <PrivateRoute exact path="/Account" component={Account} />

            <PrivateRoute exact path="/users/:id" component={User} />
          </Switch>

          {/* Footer */}
          <div className="col-start-1 col-end-5">
            <Footer />
          </div>
        </div>
        <ReactRouter />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
