import react, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getFaces } from "../../redux/actions/faces";
import { v4 as uuidv4 } from "uuid";

const SelectAvatar = ({ avatar, setAvatar }) => {
  useEffect(() => {
    getFaces();
    // console.log(avatar);
  }, []);

  let faces = useSelector((state) => state.faces.faces);
  let avatarFaces;

  if (faces) {
    avatarFaces = faces.map((face, index) => {
      if (index == avatar) {
        return (
          <div
            key={uuidv4()}
            className="flex-shrink-0 mx-1 w-28 h-28 bg-white	overflow-hidden  text-white bg-transparent rounded-full  text-primary-red-200  hover:shadow-purple shadow-purple-lg duration-200 cursor-pointer  border-purple-500 border-4"
          >
            <img className="w-28 h-28" src={face.avatar} />
          </div>
        );
      } else
        return (
          <div
            key={uuidv4()}
            className="flex-shrink-0 mx-1 w-28 h-28 bg-white	overflow-hidden  text-white bg-transparent rounded-full hover:bg-white hover:text-primary-red-200 hover:shadow-white-lg duration-200 cursor-pointer border-white border-2"
            onClick={() => setAvatar(index)}
          >
            <img className="w-28 h-28" src={face.avatar} />
          </div>
        );
    });

    avatarFaces.push(<div key={uuidv4()} className="pr-5 h-28"></div>);
  }

  return (
    <div className=" flex md:justify-center items-center h-36 overflow-x-auto overflow-y-hidden py-20 px-3  m-5">
      {avatarFaces}
    </div>
  );
};

export default SelectAvatar;
