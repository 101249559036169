import React from "react";
import { connect } from "react-redux";
import Categories from "./Card/Categories";
import Calendar from "./Calendar/Task/Calendar";
import CalendarButtons from "./Card/CalendarButtons";
import Weekday from "./Card/Weekday";
import { motion } from "framer-motion";
import CalendarMonth from "./Card/CalendarMonth";
import {
  getCalendarDates,
  calendarDatesCreated,
} from "../../redux/actions/calendar";
import Particles from "react-particles-js";

class Chronology extends React.Component {
  state = {
    update: false,
    calendarDatesSent: false,
    calendar: [],
    category: null,

    calendarDatesLength: false,
    showCalendar: false,

    showAddCalendarTask: false,
  };

  componentDidMount = () => {
    window.addEventListener("load", () => {
      this.setShowCalendar();
    });
    window.addEventListener("resize", () => {
      this.setShowCalendar();
    });

    window.scrollTo(0, 0);
  };

  componentDidUpdate = () => {
    if (this.props.currRoute === "chronology") this.setShowCalendar();

    if (
      this.props.currRoute === "chronology" &&
      this.props.isAuthenticated &&
      !this.props.getCalendarDatesSent &&
      this.props.date
    ) {
      getCalendarDates();
    }

    if (this.props.calendarDateCreated) {
      getCalendarDates();
      calendarDatesCreated();
    }
  };

  setShowCalendar = () => {
    let showCalendar = window.innerWidth > 1280 ? true : false;
    if (this.state.showCalendar !== showCalendar)
      this.setState({
        showCalendar: showCalendar,
      });
  };

  setShowAddCalendarTask = () => {
    this.setState({ showAddCalendarTask: !this.state.showAddCalendarTask });
  };

  setCategory = (category) => {
    this.setState({ category: category });
  };

  render() {
    let calendar;

    if (this.props.calendarDates)
      if (this.props.calendarDates && this.state.showCalendar) {
        calendar = (
          <CalendarMonth
            category={this.state.category}
            calendarDates={this.props.calendarDates}
          />
        );
      }

    const styleWrapper = {
      position: "relative",
    };

    const styleContent = {
      position: " relative",
      zIndex: "100",
      height: "1400px",
    };

    const styleBackground = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "0",
    };

    return (
      <div style={styleWrapper}>
        <div style={styleContent}>
          <div className="flex flex-col sm:flex-row sm:items-center justify-start sm:justify-between m-10 xl:m-10">
            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: 0,
              }}
              className="tracking-wide font-bold text-white text-5xl flex items-center"
            >
              <div className="mr-5" onClick={() => this.props.history.goBack()}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 219.151 219.151"
                  stroke="white"
                  fill="white"
                  className="w-10 h-10"
                >
                  <g>
                    <path
                      d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575
		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575
		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"
                    />
                    <path
                      d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
		c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"
                    />
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              Calendar
            </motion.div>

            {!this.state.showCalendar && (
              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                exit={{
                  opacity: 1,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: 0,
                }}
                className="tracking-wide bg-primary-red-200 text-xl font-bold py-2 mt-10 sm:mt-0 mr-3 ml-2 h-12 text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none w-full sm:w-60 cursor-pointer flex items-center justify-center"
                onClick={() => this.setShowAddCalendarTask()}
              >
                Add Calendar Task
              </motion.div>
            )}
          </div>

          {this.state.showCalendar ? (
            <div className="mx-20 my-12">
              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                exit={{
                  opacity: 1,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: 0,
                }}
                className="tracking-wide font-bold text-white text-5xl flex items-center"
              >
                <Categories
                  setCategory={(category) => this.setCategory(category)}
                />
              </motion.div>

              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                exit={{
                  opacity: 1,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: 0,
                }}
              >
                <CalendarButtons />
              </motion.div>

              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                exit={{
                  opacity: 1,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: 0,
                }}
              >
                <Weekday />
              </motion.div>

              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                exit={{
                  opacity: 1,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: 0,
                }}
              >
                {calendar}
              </motion.div>
            </div>
          ) : (
            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: 0.5,
              }}
            >
              <Calendar
                showAddCalendarTask={this.state.showAddCalendarTask}
                setShowAddCalendarTask={() => this.setShowAddCalendarTask()}
                category={this.state.category}
              />
            </motion.div>
          )}
        </div>
        <Particles style={styleBackground}></Particles>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currRoute: state.route.currRoute,
    calendarDateCreated: state.calendar.calendarDateCreated,
    isAuthenticated: state.auth.isAuthenticated,
    getCalendarDatesSent: state.calendar.getCalendarDatesSent,
    calendarDates: state.calendar.calendarDates,
  };
};

export default connect(mapStateToProps)(Chronology);
