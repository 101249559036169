import React from "react";
import { motion } from "framer-motion";

const ProductDescription = () => {
  let imgWallpaper = "/resources/images/product-design.png";

  return (
    <div>
      <motion.div
        initial={{
          y: 20,
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.5,
        }}
        className="ml-12 mr-20 mb-16 mt-10  text-white font-bold text-4xl"
      >
        Turn your ideas into reality
      </motion.div>

      <motion.div
        initial={{
          y: 20,
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.8,
        }}
        className="m-12 flex flex-col lg:flex-row items-center justify-between"
      >
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: 0.6,
          }}
          className="m-5"
        >
          <div className="my-12">
            <div className="text-white text-opacity-50 font-medium text-2xl">
              Reach your audience
            </div>
            <div className="leading-relaxed text-white text-lg my-5">
              Stand out in every inbox - we have tools to make it easier to grow
              grow your audience across multiple channels.
            </div>
          </div>
          <div className="my-12">
            <div className="text-white text-opacity-50  font-medium text-2xl">
              Add the tools you need
            </div>
            <div className="text-white my-5 text-lg leading-relaxed">
              Explore which tools you need - whether it's setting up an online
              store, booking services, or adding you favorite extensions.
            </div>
          </div>
          <div className="my-12">
            <div className="text-white text-opacity-50  font-medium text-2xl">
              Choose your design
            </div>
            <div className="text-white my-5 text-lg leading-relaxed">
              Our industry-leading designers help fit your professional needs
            </div>
          </div>
        </motion.div>
        <div className="border-white border p-5 rounded-3xl bg-white bg-opacity-30 border-opacity-50 m-5">
          <img
            className="rounded-2xl"
            style={{ height: "350px", minWidth: "350px", objectFit: "cover" }}
            src={process.env.PUBLIC_URL + imgWallpaper}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default ProductDescription;
