import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { register } from "../../redux/actions/auth";
import { Link, Redirect } from "react-router-dom";
import "./style.css";
import Particles from "react-particles-js";

const items = ["Remember Me"];

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: false,
      name: "",
      email: "",
      password: "",
      password2: "",
      emailError: false,
      passwordError: false,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.prevPropsList !== props) {
      console.log("props");
      console.log(props);

      console.log(props.isAuthenticated + " props ");
      if (props.isAuthenticated == true) {
        return {
          prevPropsList: props,
          emailError: false,
          propsEmailError: props.emailError,
          isAuthenticated: true,
        };
      }

      if (props.emailError == true)
        return {
          prevPropsList: props,
          emailError: true,
          propsEmailError: props.emailError,
        };

      if (props.emailError == false || props.passwordError == false) {
        return {
          prevPropsList: props,
          emailError: false,
          propsEmailError: props.emailError,
        };
      }
    }

    return {
      prevPropsList: props,
    };
  }

  handleFormSubmit = async (formSubmitEvent) => {
    formSubmitEvent.preventDefault();

    if (this.state.password !== this.state.password2) {
      this.setState({
        passwordError: true,
        emailError: "",
        password: "",
        password2: "",
      });
    } else {
      register({
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
      });

      this.setState({
        password: "",
        password2: "",
        emailError: "",
      });
    }
  };

  render() {
    console.log(
      "ame" + this.props.name,
      "profle" + this.props.profile,
      this.props.faces,
      this.props.setToken,
      this.props.emailError,
      this.props.isAuthenticated
    );

    if (this.state.isAuthenticated == true) {
      console.log(this.props.setToken);
      return <Redirect to="/Register" />;
    }

    const styleWrapper = {
      position: "relative",
      minHeight: "1000px",
      width: "",
    };

    const styleContent = {
      position: " relative",
      zIndex: "100",
      height: "100vh",
      width: "",
    };

    const styleBackground = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "0",
    };

    return (
      <div style={styleWrapper}>
        <motion.div
          initial={{
            y: 15,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
          }}
          style={styleContent}
        >
          <div className="mt-36 mb-52 flex flex-col justify-center items-center">
            <div className="">
              {/* Login */}
              <div className=" mb-24 tracking-wide font-bold text-white text-5xl">
                Sign Up
              </div>

              <form onSubmit={this.handleFormSubmit}>
                <div className="flex-grow tracking-wide text-3xl font-bold pt-5 pb-6 pr-5 text-white rounded-xl focus:outline-none">
                  Create Your Account
                </div>

                {/* Name */}
                <div className="mb-3">
                  <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Name
                  </div>
                  <input
                    value={this.state.name}
                    className="flex-grow w-96 bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="name"
                    onChange={(e) =>
                      this.setState({
                        name: e.target.value,
                        emailError: false,
                        passwordError: false,
                      })
                    }
                    required
                  />
                </div>

                {/* Email */}
                <div className="mb-3">
                  <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Email
                  </div>
                  <input
                    value={this.state.email}
                    type="email"
                    className="flex-grow w-96 bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="email"
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        emailError: false,
                        passwordError: false,
                      })
                    }
                    required
                  />
                  {this.state.emailError && (
                    <div className="flex items-center">
                      <div className="font-extralight p-1 text-red-500 text-md">
                        User already exists
                      </div>
                      <Link
                        to="/Login"
                        className="hvr-underline-from-left p-1 ml-1 font-extralight text-white cursor-pointer text-md"
                      >
                        Sign In
                      </Link>
                    </div>
                  )}
                </div>

                {/* Password */}
                <div className="mb-3">
                  <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Password
                  </div>
                  <input
                    value={this.state.password}
                    type="password"
                    className="flex-grow w-96 bg-primary-purple-500 font-extralight  align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        emailError: false,
                        passwordError: false,
                      })
                    }
                    required
                  />
                  {this.state.passwordError && (
                    <div className="font-extralight p-1 text-red-500 outline-none">
                      passwords do not match
                    </div>
                  )}
                </div>

                {/* Confirm Password */}
                <div className="mb-12">
                  <div className="flex-grow tracking-wide font-extralight text-xl pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none">
                    Confirm Password
                  </div>
                  <input
                    value={this.state.password2}
                    type="password"
                    className="flex-grow w-96 bg-primary-purple-500 align-middle p-3 text-white text-lg placeholder-white outline-none placeholder-opacity-50"
                    placeholder="confirm password"
                    onChange={(e) =>
                      this.setState({
                        password2: e.target.value,
                        emailError: false,
                        passwordError: false,
                      })
                    }
                    required
                  />
                </div>

                <button
                  className="tracking-wide bg-primary-red-200 text-lg text-white rounded shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none flex-grow-0 px-10 py-5 my-5 w-96"
                  type="submit"
                >
                  Sign Up
                </button>
                <div className="flex-grow tracking-wide font-extralight  pt-3 pb-3 pr-5 text-white rounded-xl focus:outline-none flex items-center">
                  Have an account
                  <Link
                    to="/Login"
                    className="ml-3 text-white bg-transparent py-1 px-2 rounded-lg hover:bg-white hover:text-primary-red-200 hover:shadow-white-lg duration-200 cursor-pointer	"
                  >
                    Sign In
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </motion.div>

        <Particles
          width={"95vw"}
          height={"1000px"}
          style={styleBackground}
        ></Particles>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  let name = ""

  if (state.profile.profile)
    if (state.profile.profile.user)
      if (state.profile.profile.user.name)
        name = state.profile.profile.user.name

  return {
    name: name,
    profile: state.profile.profile,

    faces: state.faces.faces,

    setToken: state.auth.setToken,
    emailError: state.auth.emailError,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { register })(SignUp);
