import api from "./api";
import { connect } from "react-redux";
import * as ActionTypes from "../redux/actionTypes";
import store from "../redux/store";

export default function setAuthToken(token) {
  if (token) {
    api.defaults.headers.common["x-auth-token"] = token;

    store.dispatch({
      type: ActionTypes.SET_TOKEN,
    });
  } else {
    delete api.defaults.headers.common["x-auth-token"];
  }
}
