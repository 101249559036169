import {
  GET_ALL_CALENDAR_DATES,
  GET_ALL_CALENDAR_DATES_SENT,
  GET_ALL_CALENDAR_DATES_REQ,
  GET_ALL_CALENDAR_DATES_FAIL,
  CREATE_CALENDAR_DATE,
  CREATE_CALENDAR_DATE_FAIL,
  CALENDAR_DATES_CREATED,
  DELETE_CALENDAR_DATE,
  CALENDAR_CATEGORIES,
  CALENDAR_CATEGORIES_FAIL,
} from "../actionTypes";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CALENDAR_DATES:
      return {
        ...state,
        calendarDates: payload,
      };
    case GET_ALL_CALENDAR_DATES_SENT:
      return {
        ...state,
        getCalendarDatesSent: true,
      };
    case GET_ALL_CALENDAR_DATES_REQ:
      return {
        ...state,
        getCalendarDatesSent: false,
      };
    case DELETE_CALENDAR_DATE:
    case CREATE_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: payload,
        calendarDateCreated: true,
      };
    case CALENDAR_DATES_CREATED:
      return {
        ...state,
        calendarDateCreated: false,
      };

    case CALENDAR_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };

    case CALENDAR_CATEGORIES_FAIL:
    case GET_ALL_CALENDAR_DATES_FAIL:
    case CREATE_CALENDAR_DATE_FAIL:
    default:
      return state;
  }
}
