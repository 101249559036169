import api from "../../utils/api";
import {
  CREATE_CALENDAR_DATE,
  CREATE_CALENDAR_DATE_FAIL,
  GET_ALL_CALENDAR_DATES,
  GET_ALL_CALENDAR_DATES_SENT,
  GET_ALL_CALENDAR_DATES_REQ,
  GET_ALL_CALENDAR_DATES_FAIL,
  GET_CALENDAR_DATE,
  GET_CALENDAR_DATE_FAIL,
  DELETE_CALENDAR_DATE,
  DELETE_CALENDAR_DATE_FAIL,
  CALENDAR_DATES_CREATED,
  CALENDAR_CATEGORIES,
  CALENDAR_CATEGORIES_FAIL,
  EDIT_CALENDAR_DATE,
  EDIT_CALENDAR_DATE_FAIL,
} from "../actionTypes";
import store from "../store";

// Create calendar date
export const createCalendarDate = async (
  task,
  summary,
  color,
  category,
  date
) => {
  const body = JSON.stringify({
    task,
    summary,
    color,
    category,
    date,
  });
  try {
    const res = await api.post("/calendar", body);
    store.dispatch({
      type: CREATE_CALENDAR_DATE,
      payload: res.data,
    });
  } catch (err) {
    store.dispatch({
      type: CREATE_CALENDAR_DATE_FAIL,
      payload: err.response,
    });
  }
};

let maxFailedCalendarDatesRequests = 10;
let totalFailedCalendarDatesRequests = 0;
let callCalendarDatesRequests = true;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getCalendarDates = async () => {
  if (totalFailedCalendarDatesRequests < maxFailedCalendarDatesRequests) {
    if (callCalendarDatesRequests) {
      
      const body = JSON.stringify({
        month: store.getState().calendarReducer.date,
      });

      try {
        store.dispatch({
          type: GET_ALL_CALENDAR_DATES_SENT,
        });
        const res = await api.post("calendar/calendarDates", body);

        store.dispatch({
          type: GET_ALL_CALENDAR_DATES,
          payload: res.data,
        });

        getCalendarCategories();
      } catch (err) {
        

        store.dispatch({
          type: GET_ALL_CALENDAR_DATES_FAIL,
        });

        totalFailedCalendarDatesRequests = totalFailedCalendarDatesRequests + 1;
      }

      callCalendarDatesRequests = false;
      await sleep(300);
      callCalendarDatesRequests = true;
    }
  }
};

// Get calendar date by ID
export const getCalendarDateByID = async ({ calendarID }) => {
  try {
    const res = await api.get(`calendar/${calendarID}`);
    store.dispatch({
      type: GET_CALENDAR_DATE,
      payload: res.data,
    });
  } catch (err) {
    store.dispatch({
      type: GET_CALENDAR_DATE_FAIL,
      payload: err.response.data,
    });
  }
};

// Delete calendar date by ID
export const deleteCalendarDate = async (calendarID) => {
  try {
    store.dispatch({
      type: GET_ALL_CALENDAR_DATES_REQ,
    });
    const res = await api.delete(`calendar/${calendarID}`);
    store.dispatch({
      type: DELETE_CALENDAR_DATE,
      payload: res.data,
    });
  } catch (err) {
    store.dispatch({
      type: DELETE_CALENDAR_DATE_FAIL,
      payload: err.response.data,
    });
  }
};

// calendar dates created
export const calendarDatesCreated = async () => {
  try {
    store.dispatch({
      type: CALENDAR_DATES_CREATED,
    });
  } catch (err) {}
};

let maxFailedCategoriesRequests = 10;
let totalFailedCategoriesRequests = 0;
let callCategoriesRequests = true;

// get all categories
export const getCalendarCategories = async () => {
  if (totalFailedCategoriesRequests < maxFailedCategoriesRequests) {
    if (callCategoriesRequests) {

      const body = JSON.stringify({
        month: store.getState().calendarReducer.date,
      });

      try {
        const res = await api.post(`calendar/categories`);

        store.dispatch({
          type: CALENDAR_CATEGORIES,
          payload: res.data,
        });
      } catch (err) {

        store.dispatch({
          type: CALENDAR_CATEGORIES_FAIL,
        });

        totalFailedCategoriesRequests = totalFailedCategoriesRequests + 1;
      }

      callCategoriesRequests = false;
      await sleep(300);
      callCategoriesRequests = true;
    }
  }
};

// Edit calendar card
export const editCalendarCard = async (
  task,
  summary,
  color,
  category,
  date,
  id
) => {
  const body = JSON.stringify({
    task,
    summary,
    color,
    category,
    date,
    id,
  });

  try {
    const res = await api.post("/calendar/edit", body);

    store.dispatch({
      type: EDIT_CALENDAR_DATE,
      payload: res.data,
    });

    getCalendarDates();
  } catch (err) {
    store.dispatch({
      type: EDIT_CALENDAR_DATE_FAIL,
      payload: err.response,
    });
  }
};
