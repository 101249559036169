import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Link, Redirect } from "react-router-dom";
import Particles from "react-particles-js";
import { connect } from "react-redux";

const Home = ({ isAuthenticated }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styleWrapper = {
    position: "relative",
    height: "1000px",
    width: "",
  };

  const styleContent = {
    position: " relative",
    zIndex: "100",
    height: "1000px",
    width: "",
  };

  const styleBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "0",
  };

  if (isAuthenticated == true) {
    return (
      <Redirect
        to={{
          pathname: "/Panel",
        }}
      />
    );
  }

  return (
    <div style={styleWrapper}>
      <div style={styleContent}>
        {/* Logo */}
        <motion.div
          initial={{
            y: 10,
            scale: 1.5,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0,
          }}
          className="h-2/5 flex ml-20 items-center"
        >
          {/* logo */}
          <div className="relative mr-4 mt-10 h-16 w-16">
            <div className="absolute -top-8 lg:-top-10 lg:w-24 lg:h-24 h-16 w-16 bg-primary-red-200 border-primary-red-200 rounded-2xl lg:rounded-3xl border"></div>
            <div className="absolute lg:top-1 left-8 lg:left-12 lg:w-24 lg:h-24 h-16 w-16 border-primary-yellow-100 rounded-2xl lg:rounded-3xl border-2 filter-blur"></div>
          </div>
          {/* Todoser */}
          <div className="lg:ml-32 ml-12 lg:mt-6 tracking-wide font-bold lg:text-8xl text-6xl text-white">
            Todo<span className="text-primary-purple-100">ser</span>
          </div>
        </motion.div>
        {/* Heading */}
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          exit={{
            opacity: 1,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0.2,
          }}
          className="tracking-wide font-bold text-white text-5xl 
        flex items-center lg:text-6xl ml-20 h-1/5"
        >
          Corporate website for ULT Algency
        </motion.div>

        <div className="flex items-center ml-20 h-2/5">
          {/* Button */}
          <Link to="/Login">
            <motion.button
              initial={{
                y: 15,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 1,
                delay: 0.2,
              }}
              className="flex-grow tracking-wide bg-primary-red-200 text-2xl font-bold px-5 py-2 h-20 w-32 text-white rounded-xl mr-10 md:mr-10 shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none
              sm:w-52
              
              "
            >
              Login
            </motion.button>
          </Link>
          <Link to="/SignUp">
            <motion.button
              initial={{
                y: 15,
                opacity: 0,
              }}
              exit={{
                opacity: 1,
              }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 1,
                delay: 0.4,
              }}
              className="flex-grow tracking-wide bg-primary-red-200 text-2xl font-bold px-5 py-2 h-20 w-32 text-white rounded-xl mr-3 md:mr-10 shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none
              
              sm:w-52"
            >
              Signup
            </motion.button>
          </Link>
        </div>
      </div>

      <Particles
        width={"95vw"}
        height={"800px"}
        style={styleBackground}
      ></Particles>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (state.auth.isAuthenticated) ownProps.history.push("/Panel");
  return {};
};

export default connect(mapStateToProps)(Home);
