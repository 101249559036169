import api from "../../utils/api";
import { GET_FACES, GET_FACES_FAIL } from "../actionTypes";
import store from "../store";

// Create Card
export const getFaces = async () => {
  try {
    const res = await api.get("/faces");

    store.dispatch({
      type: GET_FACES,
      payload: res.data,
    });
  } catch (err) {
    store.dispatch({
      type: GET_FACES_FAIL,
      payload: err.response,
    });
  }
};
