import React from "react";
import Card from "./Card/Card";
import { connect } from "react-redux";
import SearchBar from "../Board/SearchBar";
import * as ActionTypes from "../../redux/actionTypes";
import { getFaces } from "../../redux/actions/faces";
import { getProfile } from "../../redux/actions/profile";
import Particles from "react-particles-js";
import { motion } from "framer-motion";

class SearchResults extends React.Component {
  state = {
    avatar: "",
    searchResults: [],
    cardArr:[],
    width: null,
  };

  componentDidMount() {
    getFaces();
    getProfile();

    window.scrollTo(0, 0);

    window.addEventListener("load", () => this.getCardArr());
    window.addEventListener("resize", () => this.getCardArr());
  }

  componentDidUpdate() {
    console.log(this.props);

    if (this.props.searchResults) {
      if (this.props.searchResults.length !== 0) {
        if (this.props.searchResults !== this.state.searchResults) {
          this.props.getSearchResultsReceived();

          this.getCardArr();

          // set state
          this.setState({
            widthChange: false,
            searchResults: this.props.searchResults,
          });
        }
      }
      else if (this.state.cardArr.length !== 0) this.setState({ cardArr: [] });


    }
  }
  /**
   * Get Widths for column, numCardDisplayed
   * Update state of colWidth and numCardDisplayed
   * @param {number} cardListWidth - width of CardList
   * @param {number} colWidth - width of column
   * @param {number} numCardDisplayed - number of cards displayed
   */
  setWidth = () => {
    let cardListWidth = window.innerWidth - 100;
    let numCardDisplayed, colWidth;
    if (cardListWidth < 768) {
      numCardDisplayed = 1;
      return (colWidth = cardListWidth / numCardDisplayed - 50);
    } else if (cardListWidth < 1024) {
      numCardDisplayed = 2;
    } else if (cardListWidth < 1280) {
      numCardDisplayed = 3;
    } else if (cardListWidth >= 1280) {
      numCardDisplayed = 4;
    }
    colWidth = cardListWidth / numCardDisplayed - 30;

    console.log(colWidth);

    return colWidth;
  };

  getCardArr = () => {
    console.log(this.props.searchResults);
    if (this.props.faces);
    if (this.props.searchResults)
      if (this.props.searchResults.length !== 0) {
        let widthCol = this.setWidth();
        let cardArr = [];

        cardArr = this.props.searchResults.map((card) => {
          if (card.title !== undefined) {
            return (
              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                exit={{
                  opacity: 1,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0,
                }}
              >
                <Card
                  searchQuery={this.props.searchQuery}
                  category={card.category}
                  title={card.title}
                  summary={card.summary}
                  dateAdd={card.dateAdd}
                  dateDue={card.dateDue}
                  tags={card.tags}
                  id={card._id}
                  clips={card.clips}
                  avatar={this.props.faces[this.props.avatar].avatar}
                  widthCol={widthCol}
                />
              </motion.div>
            );
          }
        });

        this.setState({ cardArr: cardArr });
      }
  };

  render() {
    const styleWrapper = {
      position: "relative",
      height: 1350,
    };

    const styleContent = {
      position: " relative",
      zIndex: "100",
      height: 1000,
    };

    const styleBackground = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "0",
    };

    let cardArrDisplay;

    if (this.state.cardArr == undefined) {
      cardArrDisplay = (
        <div
          className="flex flex-col items-center mx-10 mt-10"
          style={{ height: this.state.height }}
        >
          <div className="text-white font-bold tracking-wide text-5xl">
            No search results for
          </div>
          <div className="font-light text-white text-3xl mt-6">
            "<span>{this.props.searchQuery}</span>"
          </div>
        </div>
      );
    } else if (this.state.cardArr) {
      if (this.state.cardArr.length === 0) {
        cardArrDisplay = (
          <div
            className="flex flex-col items-center mx-10 mt-10"
            style={{ height: this.state.height }}
          >
            <div className="text-white font-bold tracking-wide text-5xl">
              No search results for
            </div>
            <div className="font-light text-white text-3xl mt-6">
              "<span>{this.props.searchQuery}</span>"
            </div>
          </div>
        );
      } else {
        cardArrDisplay = (
          <div className="my-10 text-center text-white font-bold tracking-wide text-3xl">
            Search results for "
            <span className="font-light text-white text-3xl">
              {this.props.searchQuery}
            </span>
            "
          </div>
        );
      }
    }

    return (
      <div style={styleWrapper}>
        <div className="mt-10" style={styleContent}>
          <motion.div
            initial={{
              y: 20,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.1,
              delay: 0,
            }}
            className="tracking-wide font-bold  text-white text-5xl mt-12 mb-8 ml-10 my-6 flex items-baseline "
          >
            <div className="mr-5" onClick={() => this.props.history.goBack()}>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 219.151 219.151"
                stroke="white"
                fill="white"
                className="w-10 h-10"
              >
                <g>
                  <path
                    d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575
    C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575
    c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"
                  />
                  <path
                    d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
    c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
    c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
    c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
            Search Results
          </motion.div>

          <motion.div
            initial={{
              y: 20,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.3,
              delay: 0,
            }}
            className="my-16"
          >
            <SearchBar />
          </motion.div>

          <motion.div
            initial={{
              y: 20,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0,
            }}
          >
            {cardArrDisplay}
          </motion.div>

          <motion.div
            initial={{
              y: 20,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.7,
              delay: 0,
            }}
            className="mx-10 flex flex-wrap "
            style={{
              height: 1000,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {this.state.cardArr}
          </motion.div>
        </div>
        <Particles
          width={"95vw"}
          height={1000}
          style={styleBackground}
        ></Particles>{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let avatar;

  if (state.profile)
    if (state.profile.profile)
      if (state.profile.profile.user) {
        if (state.profile.profile.user.avatar)
          avatar = state.profile.profile.user.avatar;
      }
  console.log(state);
  return {
    avatar: avatar,
    faces: state.faces.faces,
    profile: state.profile.profile,
    searchResults: state.search.searchResults,
    searchQuery: state.search.searchQuery,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchResultsReceived: () =>
      dispatch({ type: ActionTypes.GET_SEARCH_RESULTS_RECEIVED }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
