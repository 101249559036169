import { combineReducers } from "redux";
import calendarReducer from "./reducer/calendarReducer";
import auth from "./reducer/auth";
import profile from "./reducer/profile";
import calendar from "./reducer/calendar";
import card from "./reducer/card";
import faces from "./reducer/faces";
import search from "./reducer/search";
import calendarMonth from "./reducer/calendarMonth";
import route from "./reducer/route";
import cardColumn from './reducer/cardColumn'

const rootReducer = combineReducers({
  cardColumn,
  route,
  calendarReducer,
  auth,
  profile,
  calendar,
  card,
  faces,
  search,
  calendarMonth,
});

export default rootReducer;
