import React from 'react'

const Weekday = () => {
  const WeekdayEnum = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  // add week days
  let weekDayArr = []

  WeekdayEnum.forEach(weekday =>
    weekDayArr.push(
      <div
        key={weekday}
        className='text-white text-xl text-center border-opacity-20 bg-opacity-10 bg-white	border-white border h-10 py-1'
        style={{ backdropFilter: 'blur(5px)' }}
      >
        {weekday}
      </div>
    )
  )

  return <div className='grid gap-1 mb-1 grid-cols-7'>{weekDayArr}</div>
}

export default Weekday
