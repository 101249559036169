import React, { useState } from "react";
import TaskCard from "./TaskCard";
import { AnimatePresence, useIsPresent } from "framer-motion";
import * as ActionTypes from "../../../redux/actionTypes";
import { connect } from "react-redux";

const CalendarDay = ({
  tasks,
  day,
  month,
  year,
  displayCreateCard,
  setDay,

  taskCreateCard,
}) => {
  const [clicked, setClicked] = useState(false);

  const [x, setX] = useState();
  const [y, setY] = useState();

  if (clicked) {
    setClicked(false);
  }

  let tasksDay = [];
  if (tasks) {
    // loop tasks for day
    tasksDay = tasks.map((task) => {
      return (
        <TaskCard
          task={task}
          day={day}
          month={month}
          year={year}
          setClicked={setClicked}
        />
      );
    });
  }

  const createCard = () => {
    setDay(day);
    taskCreateCard();
  };

  return (
    <div
      style={{ height: "150px" }}
      className="text-white border-opacity-20  bg-white bg-opacity-10	border-white border  "
    >
      <div
        className="pl-3 pt-2 text-lg"
        onClick={() => {
          createCard();
        }}
      >
        {day}
      </div>

      <div className="overflow-y-scroll" style={{ maxHeight: 110 }}>
        {tasks ? tasksDay : ""}
        <div
          className="bg-white h-full w-full"
          onClick={() => {
            createCard();
          }}
        ></div>
      </div>
      {!displayCreateCard && (
        <div
          onClick={() => {
            createCard();
          }}
          className="h-full w-full"
        ></div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    taskCreateCard: () =>
      dispatch({
        type: ActionTypes.SHOW_CREATE_CARD,
        payload: true,
      }),
  };
};

export default connect(null, mapDispatchToProps)(CalendarDay);
