import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCalendarCategories } from "../../../redux/actions/calendar";
import CalendarDay from "./CalendarDay";
import OutsideAlerter from "../OutsideAlerter";
import CreateCard from "./CreateCard";
import { AnimatePresence } from "framer-motion";
import EditCalendarCard from "./EditCalendarCard";

import * as ActionTypes from "../../../redux/actionTypes";

const CalendarMonth = ({
  calendarDates,
  date,
  category,

  editMenu,
  createCard,

  taskEditMenu,
  taskCreateCard,
}) => {
  useEffect(() => {
    getCalendarCategories();
  }, []);

  const [displayCreateCard, setDisplayCreateCard] = useState(false);

  useEffect(() => {
    if (editMenu)
      if (editMenu.empty === false && showEditMenu !== true) {
        setShowEditMenu(true);
      }
  });

  useEffect(() => {
    if (createCard) {
      setDisplayCreateCard(true);
    } else if (createCard === false) {
      setDisplayCreateCard(false);
    }
  }, [createCard]);

  const [showEditMenu, setShowEditMenu] = useState(false);
  const [day, setDay] = useState();

  if (calendarDates) {
    let weekEnd;

    let monthDayArr = [];

    let key = 0;

    date = new Date(date);

    let year = date.getFullYear();
    let month = date.getMonth();
    date.setDate(1);
    let startDay = date.getDay();

    if (!weekEnd) weekEnd = 7 - startDay;

    date.setMonth(month + 1);
    date.setDate(0);
    let lastDate = date.getDate();
    date.setDate(1);
    startDay = date.getDay();

    for (let k = 0; k < startDay; k++) {
      monthDayArr.push(<div></div>);
    }

    let calendarDayArr = [];
    // loop calendar
    for (let i = 0; i <= 5; i++) {
      for (let j = 1; j <= 7; j++) {
        if (i * 7 + j > lastDate) break;

        let allTasksDay = [];

        // loop all tasks in month
        for (let index = 0; index < calendarDates.length; index++) {
          // get task for date
          let taskValue = false;
          let value = calendarDates[index];

          let time = new Date(value.date);
          let date = time.getDate();

          if (time.getMonth() === month) {
            if (date === i * 7 + j) {
              if (value.category === category) {
                taskValue = value;
              }
              if (category === null || category === undefined) {
                taskValue = value;
              }
            }
          }
          if (taskValue !== false) allTasksDay.push(taskValue);
        }

        calendarDayArr.push({
          month: { month },
          year: { year },
          day: i * 7 + j,
          allTasksDay: allTasksDay,
        });
      }
    }

    // loop calendar
    for (let i = 0; i <= 5; i++) {
      for (let j = 1; j <= 7; j++) {
        if (i * 7 + j > lastDate) break;

        key++;

        let date = i * 7 + j;
        let index = i * 7 + j - 1;
        let tasks = false;

        if (calendarDayArr[index])
          if (calendarDayArr[index].allTasksDay.length !== 0) {
            tasks = calendarDayArr[index].allTasksDay;
          }

        monthDayArr.push(
          <CalendarDay
            key={key}
            month={month}
            year={year}
            day={i * 7 + j}
            index={index}
            date={date}
            tasks={tasks}
            setDay={setDay}
            setDisplayCreateCard={setDisplayCreateCard}
            displayCreateCard={displayCreateCard}
          />
        );
      }
    }

    const hideShowEditMenu = () => {
      taskEditMenu();
      setShowEditMenu(false);
    };

    const hideCreateCard = () => {
      taskCreateCard();
    };

    return (
      <div style={{ height: "1000px" }}>
        <AnimatePresence>
          {showEditMenu && (
            <div className="fixed left-0 top-0 w-screen h-screen">
              <div className="flex h-screen items-center justify-center">
                {/*  Edit Card  */}
                <OutsideAlerter display={() => hideShowEditMenu()} style={{}}>
                  <div className=" overflow-y-scroll">
                    <EditCalendarCard
                      showEditMenu={() => hideShowEditMenu()}
                      card={editMenu}
                    />
                  </div>
                </OutsideAlerter>
              </div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {displayCreateCard && (
            <div className="fixed left-0 top-0 w-screen h-screen ">
              <div className="flex h-screen items-center justify-center ">
                {/*  Edit Card  */}
                <OutsideAlerter display={() => hideCreateCard()}>
                  <div className="overflow-y-scroll">
                    <CreateCard display={() => hideCreateCard()} day={day} />
                  </div>
                </OutsideAlerter>
              </div>
            </div>
          )}
        </AnimatePresence>

        <div className="grid gap-1 grid-cols-7">{monthDayArr}</div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    date: state.calendarMonth.date,
    editMenu: state.calendarMonth.editMenu,
    createCard: state.calendarMonth.createCard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMonth: () => dispatch({ type: "SET_MONTH" }),
    taskEditMenu: () =>
      dispatch({
        type: ActionTypes.SHOW_EDIT_CARD,
        payload: {
          day: "",
          summary: "",
          category: "",
          task: "",
          color: "",
          time: "",
          id: "",
          display: "",
          date: "",
          empty: true,
        },
      }),

    taskCreateCard: () =>
      dispatch({
        type: ActionTypes.SHOW_CREATE_CARD,
        payload: false,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMonth);
