import React from "react";
import {
  getAllCategories,
  getAllCards,
  categoryDataReceived,
  categoriesReceived,
} from "../../../redux/actions/card";
import { connect } from "react-redux";
import WindowDimensions from "./WindowDimensions";
import { AnimatePresence, motion } from "framer-motion";
import { sendCardArray, getCardAnimationDisplay } from "./CardAnimationDisplay";
import CreateColumn from "./Card/CreateColumn";
import OutsideAlerter from "./Card/OutsideAlerter";

class CardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colWidth: null,
      cardListWidth: null,
      numCardDisplayed: null,
      getCategories: false,

      currCard: 0,
      cardNextPrev: 0,

      height: null,

      categories: [],
      categoryData: [],

      createCardArraySent: false,
      createCardArray: false,

      cardArray: [],
      card: "",

      showCreateColumn: false,
      cardAnimationDisplay: [],
    };
    this.cardListRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener(
      "load",
      this.debounce(() => this.createCardAnimationDisplay(this.state.currCard, this.state.cardNextPrev), 100)
    );
    window.addEventListener(
      "resize",
      this.debounce(() => this.createCardAnimationDisplay(this.state.currCard, this.state.cardNextPrev), 100)
    );
    getAllCategories();

    let cardListWidth = window.innerWidth - 30;
    if (cardListWidth > 1280) {
      cardListWidth = (cardListWidth * 3) / 4;
    }
    this.setState({ cardListWidth: cardListWidth })
  }

  debounce = (func, time) => {
    var time = time || 100; // 100 by default if no param
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
    };
  };

  setDimensions = (height, width) => {
    this.setState({ height, width });
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props !== state.prevProps) {
      if (
        JSON.stringify(props.categoryData) !==
        JSON.stringify(state.categoryData)
      ) {
        return {
          prevProps: props,
          categoryData: props.categoryData,
          createCardArray: true,
        };
      }
    }
    return null;
  };

  componentDidUpdate() {

    if (this.props.showColumn) {
      this.showColumnMenu(true);
      this.props.hideShowColumn(false);
    }

    if (this.props.categories.length !== 0 && JSON.stringify(this.state.categories) !== JSON.stringify(this.props.categories)) {
      getAllCategories()
      this.setState({ getCards: false })
    }

    if (this.props.categoriesUpdated && this.props.categories.length !== 0 && this.state.getCards == false) {
      this.setState({
        getCards: true,
        categories: this.props.categories
      })

      categoriesReceived();
      getAllCards();
    }

    if (
      this.props.categoryData.length !== 0 &&
      this.props.categoryDataUpdated
    ) {
      categoryDataReceived();
      this.createCardAnimationDisplay(this.state.currCard, this.state.cardNextPrev)
    }

    if (this.props.categories.length === 0 && this.state.cardAnimationDisplay.length !== 0) {
      this.setState({ cardAnimationDisplay: [] })

    }
  }

  showColumnMenu = (show) => {
    this.setState({ showCreateColumn: show });
  };

  cardDisplayNext = () => {
    let currCard, cardNextPrev;
    if (this.state.currCard >= this.props.categories.length) {
      currCard = 1;
      cardNextPrev = 1;
    } else {
      currCard = this.state.currCard + 1;
      cardNextPrev = 1;
    }

    this.createCardAnimationDisplay(currCard, cardNextPrev);
  };

  cardDisplayPrev = () => {

    let currCard, cardNextPrev;
    if (this.state.currCard == 0) {
      currCard = this.props.categories.length - 1;
      cardNextPrev = -1;
    } else {
      currCard = this.state.currCard - 1;
      cardNextPrev = -1;
    }

    this.createCardAnimationDisplay(currCard, cardNextPrev);
  };

  createCardAnimationDisplay = (currCard, cardNextPrev) => {
    let cardAnimationDisplay = [];
    let createCardArray = []

    let cardListWidth = window.innerWidth - 30;
    let numCardDisplayed, colWidth;

    if (cardListWidth < 768) {
      numCardDisplayed = 1;
    } else if (cardListWidth < 1024) {
      numCardDisplayed = 2;
    } else if (cardListWidth > 1024 && cardListWidth < 1250) {
      numCardDisplayed = 3;
    } else if (cardListWidth > 1250 && cardListWidth <= 1450) {
      numCardDisplayed = 2;
      cardListWidth = (cardListWidth * 3) / 4;
    }    else if (cardListWidth > 1450) {
      numCardDisplayed = 3;
      cardListWidth = (cardListWidth * 3) / 4;
    }



    colWidth = cardListWidth / numCardDisplayed - 10;

    this.props.categoryData.forEach((category) => {
      // all column in card Array
      createCardArray.push({
        width: `${colWidth}px`,
        className: ["col-span-1"],
        data: category.data,
        colTitle: category.category,
        colTitleNum: category.data.length,
      });
    });

    sendCardArray(createCardArray);

    if (this.props.categories) {

      if (createCardArray.length === this.props.categories.length) {
        if (createCardArray.length !== 0) {

          cardAnimationDisplay = getCardAnimationDisplay(
            currCard,
            numCardDisplayed,
            cardNextPrev,
            cardListWidth,
            colWidth
          );
        }
      }
    }

    this.setState({
      cardListWidth: cardListWidth,
      numCardDisplayed: numCardDisplayed,
      cardAnimationDisplay: cardAnimationDisplay,
      currCard: currCard,
      cardNextPrev: cardNextPrev,
    });


  };

  render() {
    return (
      <motion.div
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          opacity: {
            duration: 0.2,
            delay: 1,
          },
        }}
        ref={this.cardListRef}
        style={{
          overflow: "hidden",
        }}
        className="w-full my-10 text-center"
        id="newCategory"
      >
        <div className="flex items-center justify-center">
          <div
            className="flex flex-col items-center md:flex-row justify-between m-2"
            style={{
              width: '95%',
            }}
          >
            <button
              onClick={() => this.cardDisplayPrev()}
              className="tracking-wide bg-primary-red-200 text-xl text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none flex-grow-0	px-10 py-2 m-3 sm:my-5 mx-3 w-full md:w-60 h-14"
            >
              Previous
            </button>
            <button
              onClick={() => this.showColumnMenu(true)}
              className="tracking-wide bg-primary-red-200 text-xl text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none px-10 py-2 m-3 sm:my-5 mx-3 w-full md:w-60 flex-shrink-0 h-14"
            >
              Create Column
            </button>
            <button
              onClick={() => this.cardDisplayNext()}
              className="tracking-wide bg-primary-red-200 text-xl text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none px-10 py-2 m-3 sm:my-5 mx-3 w-full md:w-60 h-14"
            >
              Next
            </button>
          </div>
        </div>

        <motion.div
          style={{
            height: "800px",
            overflow: "hidden",
          }}
        >
          <div className="relative">
            <div>
              <AnimatePresence>
                {this.state.showCreateColumn && (
                  <div
                    className="absolute z-50"
                    style={{ width: this.state.cardListWidth - 30 }}
                  >
                    <div className="flex h-1/2 justify-center items-center">
                      <OutsideAlerter
                        display={() => this.showColumnMenu(false)}
                      >
                        <CreateColumn
                          width={this.state.cardListWidth - 30}
                          showColumnMenu={this.showColumnMenu}
                        />
                      </OutsideAlerter>
                    </div>
                  </div>
                )}

              </AnimatePresence>
              {this.state.cardAnimationDisplay.length == 0 && (
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 0,
                  }}
                  exit={{
                    opacity: 0,
                    y: 0,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    x: 0,
                  }}
                  transition={{
                    opacity: {
                      duration: 1,
                      delay: 1.5,
                    },
                  }}
                >
                  {!this.state.showCreateColumn && (
                    <div className="mt-10 text-white text-3xl">
                      No category, create a column
                    </div>
                  )}
                </motion.div>
              )}
            </div>
            <div className="absolute z-30 top-0">
              <div
                className="flex justify-between"
                style={{ width: window.innerWidth - 30 }}
              >
                {this.state.cardAnimationDisplay}
              </div>
            </div>
          </div>
        </motion.div>

        <WindowDimensions setDimensions={this.setDimensions} />
      </motion.div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    setToken: state.card.setToken,
    categoryDataUpdated: state.card.categoryDataUpdated,
    categoryData: state.card.categoryData,
    categories: state.card.categories,
    categoriesUpdated: state.card.categoriesUpdated,
  };
};
export default connect(mapStateToProps)(CardList);
