import React, { useState } from "react";
import { motion } from "framer-motion";
import "./numbers.css";

const Numbers = () => {
  const [numbers, setNumbers] = useState(0);

  const numbersStyle = "text-7xl md:text-8xl tracking-wider";

  animateValue(1500);

  function animateValue(duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      let numbersVal = Math.floor((elapsed / duration) * 1139213);
      if (numbers <= 110) setNumbers(numbersVal);

      if (elapsed < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  return (
    
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="h-80 mx-12 flex justify-around items-center  text-white"
      >
        <div className="flex flex-col items-center">
          <div className={numbersStyle} style={{ fontFamily: "Poppins-Light" }}>
            {numbers.toLocaleString()}
          </div>
          <div className="m-10 text-xl uppercase tracking-wider">
            businesses grown
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Numbers;
