import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const SelectAvatar = ({ avatar, faces, setAvatar }) => {
  let avatarFaces;

  const [selectAvatar = avatar, setSelectAvatar] = useState();

  if (faces) {
    avatarFaces = faces.map((face, index) => {
      if (index == selectAvatar) {
        return (
          <div className="flex-shrink-0 mx-1 w-28 h-28 bg-white	overflow-hidden  text-white bg-transparent rounded-full  text-primary-red-200  hover:shadow-purple shadow-purple-lg duration-200 cursor-pointer  border-purple-500 border-4">
            <img className="w-28 h-28" src={face.avatar} />
          </div>
        );
      } else
        return (
          <div
            className="flex-shrink-0 mx-1 w-28 h-28 bg-white	overflow-hidden  text-white bg-transparent rounded-full hover:bg-white hover:text-primary-red-200 hover:shadow-white-lg duration-200 cursor-pointer border-white border-2"
            onClick={() => {
              setSelectAvatar(index);
              setAvatar(index);
            }}
          >
            <img className="w-28 h-28" src={face.avatar} />
          </div>
        );
    });

    avatarFaces.push(<div key={uuidv4()} className="pr-5 h-28"></div>);
  }

  return (
    <div className=" flex md:justify-center items-center h-36 overflow-x-auto overflow-y-hidden py-20 mx-1">
      {avatarFaces}
    </div>
  );
};

export default SelectAvatar;
