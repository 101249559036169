import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as ActionTypes from "../redux/actionTypes";
import { connect } from "react-redux";

const ReactRouter = ({ routeChange }) => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState();

  React.useEffect(() => {
    setPrevLocation(location.pathname);
    if (prevLocation !== location.pathname) {
      let currlocation = location.pathname;
      currlocation = currlocation.substring(1);
      currlocation = currlocation.toLowerCase();
      routeChange(currlocation);
    } else {
      let currlocation = "";
      routeChange(currlocation);
    }
  }, [location]);

  return null;
};

const mapDispatchToProps = (dispatch) => {
  return {
    routeChange: (location) =>
      dispatch({
        type: ActionTypes.REACT_ROUTE_CHANGE,
        payload: location,
      }),
  };
};

export default connect(null, mapDispatchToProps)(ReactRouter);
