import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_SENT,
  GET_ALL_CATEGORIES_FAIL,
  GET_CARDS,
  GET_CARDS_FAIL,
  GET_CARDS_SENT,
  FIRST_CATEGORY_CARDS_REQUEST,
  ALL_CATEGORY_CARDS_RECIEVED,
  GET_DISPLAY_CARDS,
  DELETE_CARD,
  DELETE_CARD_FAIL,
  CREATE_CARD,
  CREATE_CARD_FAIL,
  CREATE_COLUMN,
  CREATE_COLUMN_FAIL,
  EDIT_CARD,
  EDIT_CARD_FAIL,
  CATEGORY_DATA_RECIEVED,
  CATEGORIES_DATA_RECEIVED,
  EDIT_COLUMN,
  EDIT_COLUMN_FAIL,
  USER_CARDS,
  USER_CARDS_FAIL,
} from "../actionTypes";

const initialState = {
  categoryData: [],
  categories: [],
  userCards: [],
  categoryDataUpdated: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_CARDS:
      return {
        ...state,
        userCards: payload,
      };

    case CREATE_CARD:
      return {
        ...state,
        card: payload,
      };

    case CATEGORIES_DATA_RECEIVED:
      return {
        ...state,
        categoriesUpdated: true,
      };
    case CATEGORY_DATA_RECIEVED:
      return {
        ...state,
        categoryDataUpdated: false,
      };

    case GET_CARDS:
      let categoryData = state.categoryData;

      let newCategory = categoryData.some(
        (category) => category.category === payload.category
      );
      if (!newCategory) categoryData.push(payload);

      if (payload.length == state.categories.length) {
        return {
          ...state,
          categoryDataUpdated: true,
          categoryData: payload,
        };
      }
      if (categoryData.length == state.categories.length) {
        for (let i = categoryData.length - 1; i >= 0; i--) {
          for (let j = 1; j <= i; j++) {
            let dataIndexA = categoryData[j - 1].index;
            let dataIndexB = categoryData[j].index;
            if (dataIndexA > dataIndexB) {
              var temp = categoryData[j - 1];
              categoryData[j - 1] = categoryData[j];
              categoryData[j] = temp;
            }
          }
        }

        return {
          ...state,
          categoryDataUpdated: true,
          categoryData: categoryData,
        };
      }
    case EDIT_COLUMN:
      return {
        ...state,
      };
    case EDIT_CARD:
      return {
        ...state,
      };

    case DELETE_CARD:
      return {
        ...state,
      };
    case USER_CARDS_FAIL:
      return {
        ...state,
      };

    case CREATE_COLUMN:
      return {
        ...state,
      };

    case GET_DISPLAY_CARDS:
      return {
        ...state,
        cardWidth: payload.cardWidth,
        displayCards: payload.displayCards,
      };
    case FIRST_CATEGORY_CARDS_REQUEST:
      return {
        ...state,
        categoryData: [],
      };

    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: payload,
        categoriesUpdated: true,
      };
    case EDIT_COLUMN_FAIL:
    case GET_CARDS_SENT:
    case CREATE_COLUMN_FAIL:
    case DELETE_CARD_FAIL:
    case EDIT_CARD_FAIL:
    case CREATE_CARD_FAIL:
    case GET_CARDS_FAIL:
    case GET_ALL_CATEGORIES_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
