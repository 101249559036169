import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "./icons/ArrowIcon.svg";

const Account = ({ setDisplayAccount, faces, avatar, name, position }) => {
  if (faces && avatar && name && position)
    return (
      <div className={classNames("flex items-center my-1 ml-5")}>
        {/* picture */}
        <img
          className="rounded-full h-8 w-8 mr-4 border-white border-2"
          src={faces[avatar].avatar}
        />
        <div
          className="overflow-ellipsis overflow-hidden"
          style={{ width: 100 }}
        >
          {/* name */}
          <div className="tracking-wide text-sm font-bold text-white">
            {name}
          </div>
          {/* title */}
          <div className="tracking-wide text-sm text-white">{position}</div>
        </div>
        <div
          className="ml-3 py-4 px-4 hover:bg-white hover:bg-opacity-10 mr-3"
          onClick={() => setDisplayAccount()}
        >
          <ArrowIcon />
        </div>
      </div>
    );
  else return <div></div>;
};

const mapStateToProps = (state) => {
  let name = "";

  if (state.auth)
    if (state.auth.user)
      if (state.auth.user.name) {
        name = state.auth.user.name;
      } else if (state.auth.user.name.name) name = state.auth.user.name.name;

  let avatar = 0;
  let position;

  if (state.profile)
    if (state.profile.profile)
      if (state.profile.profile.user) {
        if (state.profile.profile.user.avatar)
          avatar = state.profile.profile.user.avatar;
        if (state.profile.profile.user.position) {
          position = state.profile.profile.user.position;
        }
      }

  if (typeof name !== "string") name = "";

  let ProfileName, ProfileAvatar, ProfilePosition, ProfileLocation;

  if (state.profile.profile)
    if (state.profile.profile.user) {
      if (state.profile.profile.user.user)
        ProfileName = state.profile.profile.user.user.name;
      ProfileAvatar = state.profile.profile.user.avatar;
      ProfilePosition = state.profile.profile.user.position;
      ProfileLocation = state.profile.profile.user.location;
      // console.log(
      //   "PROFLIE",
      //   ProfileAvatar,
      //   ProfileName,
      //   ProfilePosition,
      //   ProfileLocation
      // );
    }

  if (ProfileAvatar == undefined) ProfileAvatar = 0;

  return {
    name: name,
    position: ProfilePosition,
    avatar: ProfileAvatar,
    faces: state.faces.faces,
  };
};

export default connect(mapStateToProps)(Account);
