import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { getSearchResults } from "../../redux/actions/search";
import * as ActionTypes from "../../redux/actionTypes";
import { withRouter } from "react-router-dom";

class SearchBar extends React.Component {
  state = {
    search: "",
  };

  onFormSubmit = (e) => {
    e.preventDefault();

    console.log("SEARCH BAR");
    if (this.state.search.length >= 1) {
      console.log("SERACH BAR", this.state.search);
      getSearchResults(this.state.search);
      this.props.history.push("/SearchResults");
    }
  };

  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <div className="flex items-center rounded-2xl  h-16 ml-10 mr-12 my-5 p-3 bg-primary-purple-500 ">
          {/* search icon */}
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512.005 512.005"
            className="h-10 p-2 w-12 svg-search"
          >
            <g>
              <g>
                <path
                  d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667
			S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6
			c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z
			 M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>

          {/* search */}
          <input
            className="bg-primary-purple-500 h-10 align-middle text-white text-lg mx-1 pl-1 font-light placeholder-white outline-none placeholder-opacity-50 w-full"
            placeholder="Search"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <button
            className="tracking-wide text-lg font-light rounded-xl text-white text-opacity-50 border-transparent border hover:border-primary-purple-100 transition duration-300 focus:outline-none flex-grow-0 	p-3 hover:text-opacity-100 w-28 md:w-36"
            type="submit" 
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default withRouter(SearchBar);
