import React from "react";
import Task from "./Task/Task";
import { v4 as uuidv4 } from "uuid";

const CalendarDay = ({ width, tasks, month, date }) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let calendarDate = monthNames[month] + " " + date;



  let tasksArr = tasks.map(
    ({ category, color, summary, task, time, user, id }) => {
      return (
        <Task
          key={uuidv4()}
          id={id}
          user={user}
          width={width}
          time={time}
          task={task}
          summary={summary}
          color={color}
          category={category}
        />
      );
    }
  );

  return (
    <div key={month} className="flex-1 m-5">
      <div className="flex justify-between items-center">
        <div className="font-bold text-lg text-white opacity-60 capitalize">
          {calendarDate}
        </div>
      </div>

      {/* Tasks */}
      <div className="mt-3">{tasksArr}</div>
      <div className="mt-8 mx-1 border-white border-b border-opacity-20"></div>
    </div>
  );
};

export default CalendarDay;
