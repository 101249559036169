import React from "react";
import { motion } from "framer-motion";
import { getSearchResults } from "../../../redux/actions/search";
import { withRouter } from "react-router-dom";

import { ReactComponent as SearchIcon } from "./SearchIcon.svg";

class SearchBar extends React.Component {
  state = {
    search: "",
  };

  onFormSubmit = (e) => {
    e.preventDefault();

    console.log("SEARCH BAR");
    if (this.state.search.length >= 1) {
      console.log("SERACH BAR", this.state.search);
      getSearchResults(this.state.search);
      this.props.history.push("/SearchResults");
    }
  };

  render() {
    return (
      <motion.div
        initial={{
          y: 10,
          opacity: 0,
        }}
        exit={{
          opacity: 1,
        }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 1,
          delay: 0,
        }}
      >
        <form onSubmit={this.onFormSubmit}>
          <div className="flex items-center rounded-2xl  h-16 ml-10 mr-12 mt-5 p-3 bg-primary-purple-500 ">
            {/* search icon */}
            <SearchIcon />
            {/* search */}
            <input
              className="bg-primary-purple-500 h-10 align-middle text-white text-lg mx-1 pl-1 font-light placeholder-white outline-none placeholder-opacity-50 w-full"
              placeholder="Search"
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <button
              className="tracking-wide text-lg font-light rounded-xl text-white text-opacity-50 border-transparent border hover:border-primary-purple-100 transition duration-300 focus:outline-none flex-grow-0 	p-3 hover:text-opacity-100 w-28 md:w-36"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </motion.div>
    );
  }
}

export default withRouter(SearchBar);
