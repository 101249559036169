import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import "./chronologyStyle.css";
import { getCalendarCategories } from "../../../redux/actions/calendar";

const Categories = ({ categories, setCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    getCalendarCategories();
  });

  let categoriesDisplay;

  if (categories) {
    categoriesDisplay = categories.map((category, index) => (
      <div className="inline-block">
        <div className="relative">
          <div
            key={index}
            className={classNames(
              "p-3 m-1",
              `${selectedCategory === index ? "" : "hvr-sweep-to-right mb-1"}`
            )}
            onClick={() => {
              if (selectedCategory !== index) {
                setSelectedCategory(index);
                setCategory(category);
              }
              if (selectedCategory === index) {
                setSelectedCategory(null);
                setCategory(null);
              }
            }}
          >
            {selectedCategory !== index ? (
              <div className="flex items-center focus:outline-none w-auto">
                <div className="w-3 h-3 border-white border"></div>
                <div className=" ml-3 text-white text-lg">{category}</div>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="w-3 h-3 border-white border bg-primary-red-200"></div>
                <div className=" ml-3 text-white text-lg">{category}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  }

  console.log(categoriesDisplay);

  return (
    <div className="text-white w-full">
      <div className="text-2xl flex items-center">Categories</div>
      <div className="flex items-center">{categoriesDisplay}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.calendar.categories,
  };
};

export default connect(mapStateToProps)(Categories);
