import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import "./cardStyle.css";
import { createCalendarDate } from "../../../redux/actions/calendar";
import OutsideAlerter from "../OutsideAlerter";
import { connect } from "react-redux";

const CreateCard = ({ categories, date, day, display }) => {
  const [value, setValue] = useState("");
  const [summary, setSummary] = useState("");
  const [currDate, setCurrDate] = useState();
  const [category, setCategory] = useState("Create Category");
  const [currCategory, setCurrCategory] = useState("Select Category");

  const [currTime, setCurrTime] = useState();

  const [categoryRequired, setCategoryRequired] = useState(false);
  const [summaryRequired, setSummaryRequired] = useState(false);
  const [taskRequired, setTaskRequired] = useState(false);

  const [showCategory, setShowCategory] = useState(false);
  const [hover, setHover] = useState(0);
  const [click, setClick] = useState(0);
  const dateRef = useRef(null);
  const timeRef = useRef(null);

  useEffect(() => {
    date = new Date(date);
    date.setDate(day);

    let MyDateString =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + day).slice(-2);

    setCurrDate(MyDateString);

    // set time
    let time =
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + (date.getMinutes() + 8)).slice(-2);

    setCurrTime(time);
  }, []);

  const minTwoDigits = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const ColorsEnum = [
    "bg-primary-red-200",
    "bg-primary-orange-100",
    "bg-primary-yellow-100",
    "bg-blue-300",
  ];

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSummaryChange = (event) => {
    setSummary(event.target.value);
  };

  const changeDate = (event) => {
    setCurrDate(event.target.value);
  };

  const changeTime = (event) => {
    let time = event.target.value;

    setCurrTime(time);
  };

  const categoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (category != "") setCurrCategory(`${category}`);
      setShowCategory(false);
    }
  };

  let categoriesArr = [];

  if (categories) {
    categoriesArr.push(
      <div>
        <input
          onClick={() => setCategory("")}
          style={{ width: `${260}px` }}
          type="text"
          className="focus:outline-none text-lg m-1 p-2 bg-white bg-opacity-20 text-white hover:bg-opacity-50 "
          value={category}
          onChange={categoryChange}
          onKeyDown={handleKeyDown}
        />
        <div
          onClick={() => {
            setCurrCategory(`${"Select Category"}`);
            setShowCategory(false);
          }}
          className="m-1 p-2 text-lg bg-white bg-opacity-20 hover:bg-opacity-50"
        >
          ""
        </div>
      </div>
    );
    categories.forEach((category) =>
      categoriesArr.push(
        <div
          onClick={() => {
            setCurrCategory(`${category}`);
            setShowCategory(false);
          }}
          className="m-1 p-2 text-lg bg-white bg-opacity-20 hover:bg-opacity-50"
        >
          {category}
        </div>
      )
    );
  }

  const SubmitCard = (e) => {
    e.preventDefault();

    let date = new Date(currDate);

    let time = currTime;
    let hours = time.substring(0, 2);
    let minutes = time.substring(3, 5);

    date.setMinutes(minutes);
    date.setHours(hours);

    date.setDate(date.getDate() + 1);
    let dateISO = date.toISOString();

    if (value !== "" && summary !== "" && currCategory !== "") {
      createCalendarDate(value, summary, click, currCategory, dateISO);

      display();
    } else {
      if (value === "") {
        setTaskRequired(true);
      }
      if (summary === "") {
        setSummaryRequired(true);
      }
      if (currCategory === "" || currCategory === "Select Category") {
        setCategoryRequired(true);
      }
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: -100,
      }}
      exit={{
        opacity: 0,
        y: 20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        opacity: { duration: 0.1 },
      }}
      style={{ width: 320, height: 650, zIndex: 1000 }}
    >
      <div
        className="z-50 shadow-2xl border-white border border-opacity-20 rounded-3xl px-4 py-3 bg-primary-purple-300 bg-opacity-100 text-white"
        style={{ height: 650 }}
      >
        <div className="m-2 mt-3 mb-5 text-2xl font-bold">Create Card</div>
        <div className="m-1 mt-3">
          <div className="flex justify-between items-center">
            <div className="m-1 text-xl font-semibold">Task</div>
            {taskRequired && (
              <div className="font-light p-1 text-red-500 mr-1">
                Task is Required
              </div>
            )}
          </div>
          <input
            style={{ width: "270px" }}
            type="text"
            className="focus:outline-none m-1 p-2  text-lg bg-transparent border border-white border-opacity-30 rounded-md"
            value={value}
            onChange={(e) => {
              handleChange(e);
              setTaskRequired(false);
            }}
          />
        </div>
        <div className="m-1 mt-3">
          <div className="flex justify-between items-center">
            <div className="m-1 text-xl  font-semibold">Summary</div>
            {summaryRequired && (
              <div className="font-light p-1 text-red-500 mr-1">
                Summary is Required
              </div>
            )}
          </div>
          <textarea
            style={{ width: "270px" }}
            rows="2"
            className="resize-none text-lg focus:outline-none m-1 p-2 bg-transparent border border-white border-opacity-30 rounded-md"
            value={summary}
            onChange={(e) => {
              handleSummaryChange(e);
              setSummaryRequired(false);
            }}
          />
        </div>

        <div className="m-1 mt-3 relative">
          <div className="flex justify-between items-center">
            <div className="m-1 text-xl font-semibold">Category</div>
            {categoryRequired && (
              <div className="font-light p-1 text-red-500 mr-1">
                Category is Required
              </div>
            )}
          </div>

          <div
            style={{ width: "270px" }}
            className="focus:outline-none text-lg m-1 p-2 bg-primary-purple-300 bg-opacity-0 border border-white border-opacity-30 text-white text-opacity-60  rounded-md hover:bg-opacity-20 duration-200"
            onClick={() => {
              setShowCategory(true);
              setCategoryRequired(false);
            }}
          >
            {currCategory}
          </div>

          {showCategory && (
            <OutsideAlerter display={() => setShowCategory(false)}>
              <div
                style={{ zIndex: 1000 }}
                className="absolute ml-1  text-lg bg-primary-purple-300 border-white border-b border-opacity-30 rounded-b-lg"
              >
                {categoriesArr}
              </div>
            </OutsideAlerter>
          )}
        </div>

        <div className="flex items-center m-1 mt-2">
          <label for="date" className="m-1 text-xl font-semibold">
            Date:
          </label>
          <input
            className="bg-primary-purple-300
             border border-white border-opacity-30 text-lg m-1 ml-2 px-2 py-1 rounded-md text-white w-52"
            type="date"
            id="date"
            value={currDate}
            onChange={(e) => changeDate(e)}
            name="date"
            ref={dateRef}
          />
        </div>

        <div className="flex items-center m-1 ">
          <label for="time" className="m-1 text-xl font-semibold w-16"></label>
          <input
            style={{ width: `${300}px` }}
            className="bg-primary-purple-300 border border-white border-opacity-30 text-lg m-1 ml-2 px-2 py-1 rounded-md text-white"
            type="time"
            id="time"
            value={currTime}
            onChange={(e) => changeTime(e)}
            name="time"
            ref={timeRef}
          />
        </div>

        <div className="flex items-center justify-center my-5 mr-5 ">
          <div className="w-36">
            <div className="flex items-center justify-between">
              <div
                className={classNames(
                  "w-7 h-7 rounded-full border-transparent",
                  "transition duration-100 ease-in-out",
                  `${ColorsEnum[0]}`,
                  hover == 1 || click == 1 ? "border-red-500 border-4" : ""
                )}
                onMouseEnter={() => setHover(1)}
                onMouseLeave={() => setHover(0)}
                onClick={() => setClick(1)}
              ></div>
              <div
                className={classNames(
                  "w-7 h-7 ml-1 rounded-full  border-transparent",
                  "transition duration-100 ease-in-out",
                  `${ColorsEnum[1]}`,
                  hover == 2 || click == 2 ? "border-yellow-700 border-4" : ""
                )}
                onMouseEnter={() => setHover(2)}
                onMouseLeave={() => setHover(0)}
                onClick={() => setClick(2)}
              ></div>
              <div
                className={classNames(
                  "w-7 h-7 ml-1 rounded-full  border-transparent",
                  "transition duration-100 ease-in-out",
                  `${ColorsEnum[2]}`,
                  hover == 3 || click == 3 ? "border-yellow-100 border-4" : ""
                )}
                onMouseEnter={() => setHover(3)}
                onMouseLeave={() => setHover(0)}
                onClick={() => setClick(3)}
              ></div>
              <div
                className={classNames(
                  "w-7 h-7 ml-1 rounded-full  border-transparent",
                  "transition duration-100 ease-in-out",
                  `${ColorsEnum[3]}`,
                  hover == 4 || click == 4 ? "border-blue-500 border-4" : ""
                )}
                onMouseEnter={() => setHover(4)}
                onMouseLeave={() => setHover(0)}
                onClick={() => setClick(4)}
              ></div>
            </div>
          </div>
        </div>
        <button
          className="text-center tracking-wide bg-primary-red-200 text-lg font-bold py-2 m-1 ml-3 text-white rounded-xl shadow-red hover:shadow-red-lg transition duration-200 focus:outline-none"
          style={{ width: "270px" }}
          onClick={(e) => SubmitCard(e)}
        >
          Submit
        </button>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.calendar.categories,
    date: state.calendarMonth.date,
  };
};

export default connect(mapStateToProps)(CreateCard);
