import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGGED_IN,
  LOGIN_FAIL,
  LOGOUT,
  CHANGE_LOGIN,
  GET_USER,
  GET_USER_FAIL,
  SET_TOKEN,
} from "../actionTypes";
import { loadUser } from "../actions/auth";
import store from "../store";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_LOGIN:
      return {
        ...state,
        user: payload,
      };

    case SET_TOKEN: {
      // console.log("SET TOKEN");
      return {
        ...state,
        setToken: true,
      };
    }

    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case REGISTER_SUCCESS:
    case LOGGED_IN:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        passwordError: false,
        emailError: false,
        loading: false,
      };

    case LOGIN_FAIL:
      if (payload == "password") {
        return {
          ...state,
          passwordError: true,
          emailError: false,
          token: null,
          isAuthenticated: false,
          loading: false,
          user: null,
        };
      } else if (payload == "email") {
        return {
          ...state,
          emailError: true,
          passwordError: false,
          token: null,
          isAuthenticated: false,
          loading: false,
          user: null,
        };
      }

    case REGISTER_FAIL:
      localStorage.removeItem("token");
      if (payload == "email") {
        return {
          ...state,
          emailError: true,
          token: null,
          isAuthenticated: false,
          loading: false,
          user: null,
        };
      }

    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}
