import React from "react";

class PageScroll extends React.Component {
  state = {
    height: null,
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.setHeight);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      nextState.prevScrollPos !== this.state.prevScrollPos ||
      nextState.height !== this.state.height
    ) {
      return true;
    }
    return false;
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.setHeight);
  };

  handleScroll = () => {
    let currScrollPos = window.scrollY;
    let height = window.innerHeight;
    console.log(currScrollPos + height);
    if (
      currScrollPos + height > 800 &&
      this.props.showProductDescription !== true
    ) {
      this.props.setShowProductDescription(true);
    }

    if (
      currScrollPos + height > 1800 &&
      this.props.showProductCompanies !== true
    ) {
      this.props.setShowProductCompanies(true);
    }

    if (currScrollPos > 2100 && this.props.showProductServices !== true) {
      this.props.setShowProductServices(true);
    }
  };

  setHeight = () => {
    this.setState({ height: window.innerHeight });
  };

  render() {
    return <div></div>;
  }
}

export default PageScroll;
