import React from "react";
import classNames from "classnames";

const Task = ({ index, time, task, summary, color, width }) => {
  const ColorsEnum = [
    "bg-primary-red-200",
    "bg-primary-orange-100",
    "bg-primary-yellow-100",
    "bg-blue-300",
  ];

  // time am/pm
  const dateTime = new Date(time);
  let hour = dateTime.getHours();
  let hourlyTime = hour > 12 ? hour - 12 : hour;
  let mintues = ("0" + dateTime.getMinutes()).slice(-2);
  let ampm = dateTime.getHours > 12 ? "p.m." : "a.m.";

  return (
    <div
      key={index}
      style={{ minWidth: `${width}px` }}
      className={classNames("inline-block rounded-lg", "grid grid-cols-2 my-1")}
    >
      {/* Time */}
      <div className="self-center	 flex items-center">
        <div className="font-bold text-lg text-white">
          {hourlyTime + ":" + mintues}
          <span className="text-white font-normal	 opacity-50">
            {" " + ampm}
          </span>
        </div>
      </div>

      <div className="self-center	 flex items-center h-full">
        {/* Task */}
        <div
          className={classNames(
            "rounded-full h-full",
            `${ColorsEnum[color - 1]}`
          )}
          style={{ width: 10 }}
        />

        <div className="ml-5 inline-block w-full">
          <div className="block text-lg text-white opacity-50">{task}</div>
          <div className="block text-lg font-bold text-white">{summary}</div>
        </div>
      </div>
    </div>
  );
};

export default Task;



/**
 * 
 * text wrap ssummar ad task
 */