import api from "../../utils/api";
import {
  GET_CARDS,
  GET_CARDS_SENT,
  GET_CARDS_FAIL,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_FAIL,
  FIRST_CATEGORY_CARDS_REQUEST,
  EDIT_CARD,
  EDIT_CARD_FAIL,
  CREATE_COLUMN,
  CREATE_COLUMN_FAIL,
  CREATE_CARD,
  CREATE_CARD_FAIL,
  CATEGORY_DATA_RECIEVED,
  DELETE_CARD,
  DELETE_CARD_FAIL,
  DELETE_COLUMN,
  DELETE_COLUMN_FAIL,
  CATEGORIES_DATA_RECEIVED,
  EDIT_COLUMN,
  EDIT_COLUMN_FAIL,
  ADD_CLIP,
  ADD_CLIP_FAIL,
  USER_CARDS,
  USER_CARDS_FAIL,
} from "../actionTypes";
import store from "../store";
import { getSearchResults } from "./search";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Create Card
export const createCard = async (category, title, summary, dateDue, tags) => {
  const body = JSON.stringify({
    category,
    title,
    summary,
    dateDue,
    tags,
  });

  try {
    const res = await api.post("/card", body);
    store.dispatch({
      type: CREATE_CARD,
      payload: res.data,
    });

    console.log("CREATE CARD");

    getAllCategories();
    getAllCards();
  } catch (err) {
    store.dispatch({
      type: CREATE_CARD_FAIL,
      payload: err.response,
    });
  }
};

// Edit Card
export const editCard = async (
  category,
  title,
  summary,
  dateDue,
  tags,
  id,
  search,
  query
) => {
  const body = JSON.stringify({
    category,
    title,
    summary,
    dateDue,
    tags,
    id,
  });

  try {
    const res = await api.post("/card/edit", body);
    store.dispatch({
      type: EDIT_CARD,
      payload: res.data,
    });

    if (search) getSearchResults(query);

    console.log("EDTI CARD");

    getAllCategories();
    getAllCards();
  } catch (err) {
    store.dispatch({
      type: EDIT_CARD_FAIL,
      payload: err.response,
    });
  }
};

// Create column
export const createColumn = async (category) => {
  const body = JSON.stringify({
    category,
  });

  try {
    const res = await api.post("/card/column", body);

    store.dispatch({
      type: CREATE_COLUMN,
      payload: res.data,
    });

    getAllCategories();
  } catch (err) {
    store.dispatch({
      type: CREATE_COLUMN_FAIL,
      payload: err.response,
    });
  }
};

let maxFailedGetCategoriesRequests = 10;
let totalFailedGetCategoriesRequests = 0;
let callGetCategoriesRequests = true;

// Get all categories
export const getAllCategories = async () => {
  if (totalFailedGetCategoriesRequests < maxFailedGetCategoriesRequests) {
    if (callGetCategoriesRequests) {
      let date = store.getState().calendarReducer.date;
      date = new Date(date);
      date.setDate(1);
      date.setUTCHours(0);
      const body = JSON.stringify({
        month: date,
      });

      try {
        const res = await api.post("/card/categories", body);

        store.dispatch({
          type: GET_ALL_CATEGORIES,
          payload: res.data,
        });
      } catch (err) {
        store.dispatch({
          type: GET_ALL_CATEGORIES_FAIL,
        });
        totalFailedGetCategoriesRequests = totalFailedGetCategoriesRequests + 1;
      }
      callGetCategoriesRequests = false;
      await sleep(300);
      callGetCategoriesRequests = true;
    }
  }
};

let maxFailedGetCardsRequests = 10;
let totalFailedGetCardsRequests = 0;
let callGetCardsRequests = true;

// Get all cards
export const getAllCards = async () => {
  if (totalFailedGetCardsRequests < maxFailedGetCardsRequests) {
    if (callGetCardsRequests) {
      firstCategoryCardsRequest();

      if (store.getState().card.categories.length == 0) {
        store.dispatch({
          type: GET_CARDS,
          payload: [],
        });
      }
      store.getState().card.categories.map(({ category, index }) => {
        getAllCardsFromCategory(category, index);
      });

      callGetCardsRequests = false;
      await sleep(300);
      callGetCardsRequests = true;
    }
  }
};

// Get all cards from category & month
export const getAllCardsFromCategory = async (category, index) => {
  let date = store.getState().calendarReducer.date;
  date = new Date(date);
  date.setDate(1);
  date.setUTCHours(0);
  date.setUTCMinutes(0);

  const body = JSON.stringify({
    month: date,
  });

  try {
    store.dispatch({
      type: GET_CARDS_SENT,
    });

    const res = await api.post(`/card/category/${category}`, body);

    let resData = {
      data: res.data,
      category: category,
      index: index,
    };

    store.dispatch({
      type: GET_CARDS,
      payload: resData,
    });
  } catch (err) {
    store.dispatch({
      type: GET_CARDS_FAIL,
    });

    totalFailedGetCardsRequests = totalFailedGetCardsRequests + 1;
  }
};

// First category cards request sent
export const firstCategoryCardsRequest = async () => {
  try {
    store.dispatch({
      type: FIRST_CATEGORY_CARDS_REQUEST,
    });
  } catch (err) {}
};

// delete card
export const deleteCard = async (id) => {
  try {
    const res = await api.delete(`/card/${id}`);
    store.dispatch({
      type: DELETE_CARD,
      payload: res.data,
    });

    getAllCategories();
    getAllCards();
  } catch (err) {
    store.dispatch({
      type: DELETE_CARD_FAIL,
    });
  }
};

// delete column
export const deleteColumn = async (id) => {
  let date = store.getState().calendarMonth.date;
  date = new Date(date);

  date.setDate(1);
  date.setUTCHours(0);
  date.setUTCMinutes(0);

  const body = JSON.stringify({
    month: date.toJSON(),
  });

  try {
    const res = await api.post(`/card/deleteColumn/${id}`, body);
    store.dispatch({
      type: DELETE_COLUMN,
      payload: res.data,
    });
    getAllCategories();
  } catch (err) {
    store.dispatch({
      type: DELETE_COLUMN_FAIL,
    });
  }
};

export const categoryDataReceived = async () => {
  try {
    store.dispatch({
      type: CATEGORY_DATA_RECIEVED,
    });
  } catch (err) {}
};

export const categoriesReceived = async () => {
  try {
    store.dispatch({
      type: CATEGORIES_DATA_RECEIVED,
    });
  } catch (err) {}
};

export const editColumn = async (originalColumn, editColumn) => {
  let date = store.getState().calendarReducer.date;
  date = new Date(date);
  date.setDate(1);
  date.setUTCHours(0);
  date.setUTCMinutes(0);

  const body = JSON.stringify({
    month: date.toJSON(),
    editColumn: editColumn,
    originalColumn: originalColumn,
  });

  try {
    const res = await api.post(`/card/editColumn`, body);
    store.dispatch({
      type: EDIT_COLUMN,
      payload: res.data,
    });

    getAllCategories();
  } catch (err) {
    store.dispatch({
      type: EDIT_COLUMN_FAIL,
      payload: err.response,
    });
  }
};

export const addClip = async (id, search, query) => {
  try {
    const res = await api.get(`/card/clip/${id}`);
    store.dispatch({
      type: ADD_CLIP,
      payload: res.data,
    });
    getAllCategories();
    getAllCards();
    if (search) getSearchResults(query);
  } catch (err) {
    store.dispatch({
      type: ADD_CLIP_FAIL,
    });
  }
};

export const removeClip = async (id, search, query) => {
  try {
    const res = await api.get(`/card/unclip/${id}`);
    store.dispatch({
      type: ADD_CLIP,
      payload: res.data,
    });
    getAllCategories();
    getAllCards();
    if (search) getSearchResults(query);
  } catch (err) {
    store.dispatch({
      type: ADD_CLIP_FAIL,
    });
  }
};

export const getAllCardsUser = async (user) => {
  try {
    const body = JSON.stringify({
      user: user,
    });

    console.log("CARDS FROM");

    const res = await api.post("/card/user-cards", body);

    console.log("CARDS FROM USER", user, res.data);

    store.dispatch({
      type: USER_CARDS,
      payload: res.data,
    });
  } catch (err) {
    store.dispatch({
      type: USER_CARDS_FAIL,
      payload: err.response.data,
    });
  }
};
