import api from "../../utils/api";
import { SEARCH_RESULTS, SEARCH_RESULTS_FAIL } from "../actionTypes";
import store from "../store";

export const getSearchResults = async (search) => {
  try {
    const body = JSON.stringify({
      query: search,
    });

    console.log("SERACH ACIUTON")

    const res = await api.post("/search", body);
    console.log(res.data);
    store.dispatch({
      type: SEARCH_RESULTS,
      payload: {
        searchQuery: search,
        data: res.data,
      },
    });
  } catch (err) {
    store.dispatch({
      type: SEARCH_RESULTS_FAIL,
    });
  }
};
