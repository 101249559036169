import React, { useState, useEffect } from "react";
import classNames from "classnames";
import OutsideAlerter from "../../OutsideAlerter";
import { deleteCalendarDate } from "../../../../redux/actions/calendar";
import { connect } from "react-redux";
import * as ActionTypes from "../../../../redux/actionTypes";

const Task = ({
  id,
  index,
  date,
  task,
  time,
  category,
  summary,
  color,
  width,

  editTask,
}) => {
  const ColorsEnum = [
    "border-primary-red-200",
    "border-primary-orange-100",
    "border-primary-yellow-100",
    "border-blue-300",
  ];

  const dateTime = new Date(date);
  let hour = dateTime.getHours();

  let hourlyTime = hour > 12 ? hour - 12 : hour;
  let minutes = dateTime.getMinutes();
  let minutesTime = minutes >= 10 ? minutes : "0" + minutes;

  let ampm = dateTime.getHours() > 12 ? "p.m." : "a.m.";

  let calendarWidth, calendarMargin;
  if (width < 768) {
    calendarWidth = width - 100;
  } else if (width >= 768) {
    calendarWidth = width / 2 - 70;
    calendarMargin = index % 2 > 0 ? "ml-2" : "ml-0";
  }

  const [showMenu, setShowMenu] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [isOnClickShown, setIsOnClickShown] = useState(false);
  const [isShown, setIsShown] = useState(true);
  const [taskWidth, setTaskWidth] = useState();
  const [taskWidthWithoutMenu, setTaskWidthWithoutMenu] = useState();

  const resize = () => {
    if (window.innerWidth > 768) {
      setTaskWidth(window.innerWidth / 6 - 60);
    } else {
      setTaskWidth((window.innerWidth - 500) / 2 + 100);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    window.addEventListener("load", resize);
  }, []);

  return (
    <div
      key={index}
      className={classNames(
        "inline-block p-3 border-opacity-10 border-white border rounded-lg duration-150",
        "grid grid-cols-2   my-1",
        calendarMargin,
        `${isShown ? "hover:bg-white hover:bg-opacity-20 " : ""}`
      )}
      onMouseEnter={() => {
        if (isShown) setIsOnClickShown(true);
      }}
      onMouseLeave={() => {
        if (isShown) setIsOnClickShown(false);
      }}
      onClick={() => {
        if (isOnClickShown) {
          setIsShown(false);
          setShowMenu(true);
        }
      }}
    >
      {/* Time */}
      <div className="self-center	flex items-center">
        <div className="font-bold text-lg text-white">
          {" "}
          {hourlyTime + ":" + minutesTime}
          <span className="font-normal	text-opacity-50">{" " + ampm}</span>
        </div>
      </div>

      <div className="flex items-center justify-between">
        {/* Task */}
        <div className={classNames("border-l-2", `${ColorsEnum[color - 1]}`)}>
          <div className="ml-5 overflow-x-scroll" style={{ width: taskWidth }}>
            <div className="text-lg text-white text-opacity-50">{task}</div>
            <div className="text-lg font-bold text-white">{summary}</div>
          </div>
        </div>

        {showMenu && (
          <OutsideAlerter
            display={() => {
              setIsShown(true);
              setShowMenu(false);
            }}
          >
            <div className="w-16">
              <div
                onClick={() => {
                  console.log("DELETE CALEDNAR ", id);
                  deleteCalendarDate(id);
                  setShowMenu(false);
                  setIsShown(true);
                }}
                className="hover:bg-opacity-30 duration-150 cursor-pointer  hover:text-opacity-100 text-lg text-opacity-80 text-white border-white border-b border-opacity-30 bg-white bg-opacity-5 h-8 w-16 rounded-t-lg  flex items-center  py-1 px-2"
              >
                Delete
              </div>
              <div
                className="hover:bg-opacity-30 duration-150 cursor-pointer  hover:text-opacity-100 text-lg text-opacity-80 text-white bg-white bg-opacity-5 h-8 w-16 rounded-b-lg flex items-center justify-center py-1 px-2"
                onClick={() => {
                  editTask(summary, category, task, color, time, id, date);
                  setShowMenu(false);
                  setIsShown(true);
                }}
              >
                Edit
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    editTask: (summary, category, task, color, time, id, date) =>
      dispatch({
        type: ActionTypes.SHOW_EDIT_TASK,
        payload: {
          summary: summary,
          category: category,
          task: task,
          color: color,
          time: time,
          id: id,

          date: date,
          empty: false,
        },
      }),
  };
};

export default connect(null, mapDispatchToProps)(Task);
