import api from "../../utils/api";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGGED_IN,
  LOGIN_FAIL,
  CHANGE_LOGIN,
  CHANGE_LOGIN_FAIL,
  LOGOUT,
  INITAL_STATE,
} from "../actionTypes";
import store from "../store";
import { getProfile } from "./profile";
import setAuthToken from "../../utils/setAuthToken";
import { ContactsOutlined } from "@material-ui/icons";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let maxFailedLoadUserRequests = 10;
let totalFailedLoadUserRequests = 0;
let callLoadUserRequests = true;

// Load User
export const loadUser = async () => {
  if (totalFailedLoadUserRequests < maxFailedLoadUserRequests) {
    if (callLoadUserRequests) {
      try {
        const res = await api.get("/auth");

        localStorage.setItem("name", res.data.name);
        localStorage.setItem("email", res.data.email);

        store.dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      } catch (err) {
        // request failed
        store.dispatch({
          type: AUTH_ERROR,
        });

        totalFailedLoadUserRequests = totalFailedLoadUserRequests + 1;
      }

      callLoadUserRequests = false;
      await sleep(300);
      callLoadUserRequests = true;
    }
  }
};

let maxFailedRegisterUserRequests = 10;
let totalFailedRegisterUserRequests = 0;
let callRegisterUserRequests = true;

// Register User
export const register = async ({ name, email, password }) => {
  if (totalFailedRegisterUserRequests < maxFailedRegisterUserRequests) {
    if (callRegisterUserRequests) {
      const body = JSON.stringify({ name, email, password });
      try {
        const res = await api.post("/users", body);
        store.dispatch({
          type: INITAL_STATE,
        });
        store.dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });

        setAuthToken(res.data.token);
      } catch (err) {
        if (err.response) {
          if (err.response.data.error === "email") {
            store.dispatch({
              type: REGISTER_FAIL,
              payload: err.response.data.error,
            });
          }
        }
        store.dispatch({
          type: REGISTER_FAIL,
        });
        totalFailedRegisterUserRequests = totalFailedRegisterUserRequests + 1;
      }
      callRegisterUserRequests = false;
      await sleep(300);
      callRegisterUserRequests = true;
    }
  }
};

let maxFailedLoginRequests = 10;
let totalFailedLoginRequests = 0;
let callLoginRequests = true;

// Login User
export const login = async ({ email, password, rememberMe }) => {
//  console.log("LOGIN USER", email, password)

  if (totalFailedLoginRequests < maxFailedLoginRequests) {
    if (callLoginRequests) {
      if (rememberMe) {
        localStorage.setItem("password", password);
      } else {
        localStorage.removeItem("password");
      }
      const body = JSON.stringify({ email, password });
      try {

// console.log("LOGIN", body)

        const res = await api.post("/auth", body);
        store.dispatch({
          type: INITAL_STATE,
        });

        // console.log("RES", res.data)
        store.dispatch({
          type: LOGGED_IN,
          payload: res.data,
        });
        setAuthToken(res.data.token);
      } catch (err) {
        if (err.response) {

          // console.log(err.response)

          const errorArr = err.response.data.errors;
          if (errorArr) {
            errorArr.forEach((error) => {
              if (error.error) {
                if (error.error === "password") {
                  store.dispatch({
                    type: LOGIN_FAIL,
                    payload: error.error,
                  });
                }
                if (error.error === "email") {
                  store.dispatch({
                    type: LOGIN_FAIL,
                    payload: error.error,
                  });
                }
              }
            });
          }
          if (err.response) {
            const error = err.response.data.msg;
          }
        }
        store.dispatch({
          type: LOGIN_FAIL,
        });
        totalFailedLoginRequests = totalFailedLoginRequests + 1;
      }
      callLoginRequests = false;
      await sleep(300);
      callLoginRequests = true;
    }
  }
};

let maxFailedChangePasswordRequests = 10;
let totalFailedChangePasswordRequests = 0;
let callChangePasswordRequests = true;

// Change password
export const changeLogin = async (name, email, password) => {
  if (totalFailedChangePasswordRequests < maxFailedChangePasswordRequests) {
    if (callChangePasswordRequests) {
      const body = JSON.stringify({ email, password, name });

      // console.log("CHAGNGE LOGIN");

      try {
        const res = await api.post("/auth/change", body);

        store.dispatch({
          type: CHANGE_LOGIN,
          payload: {
            name: res.data,
          },
        });
      } catch (err) {
        store.dispatch({
          type: CHANGE_LOGIN_FAIL,
        });

        totalFailedChangePasswordRequests =
          totalFailedChangePasswordRequests + 1;
      }
      callChangePasswordRequests = false;
      await sleep(300);
      callChangePasswordRequests = true;
    }
  }
};

// Logout
export const logout = async () => {
  try {
    store.dispatch({ type: LOGOUT });
  } catch (err) {}
};
