export const GET_COLUMNS = "GET_COLUMNS";
export const GET_COLUMN_DATA = "GET_COLUMN_DATA";
export const GET_CALENDAR_DATA = "GET_CALENDAR_DATA";

export const GET_CARD_DATA = "GET_CARD_DATA";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_MONTH_TASKS = "GET_MONTH_TASKS";

export const GET_DATE = "GET_DATE";
export const SET_SCROLL = "SET_SCROLL";

export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CURR_DISPLAY = "SET_CURR_DISPLAY";

export const SET_MONTH = "SET_MONTH";
export const SET_WEEK = "SET_WEEK";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGGED_IN = "LOGGED_IN";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const REMEMBER_ME = "REMEMBER_ME";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_FAIL = "CREATE_PROFILE_FAIL";
export const PROFILE_UPDATED = "PROFILE_UPDATED";

export const CHANGE_LOGIN = "CHANGE_LOGIN";
export const CHANGE_LOGIN_FAIL = "CHANGE_LOGIN_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const CREATE_CALENDAR_DATE = "CREATE_CALENDAR_DATE";
export const CREATE_CALENDAR_DATE_FAIL = "CREATE_CALENDAR_DATE_FAIL";

export const GET_ALL_CALENDAR_DATES = "GET_ALL_CALENDAR_DATES";
export const GET_ALL_CALENDAR_DATES_FAIL = "GET_ALL_CALENDAR_DATES_FAIL";

export const GET_CALENDAR_DATE = "GET_CALENDAR_DATE";
export const GET_CALENDAR_DATE_FAIL = "GET_CALENDAR_DATE_FAIL";

export const GET_ALL_CALENDAR_DATES_SENT = "GET_ALL_CALENDAR_DATES_SENT";
export const GET_ALL_CALENDAR_DATES_REQ = "GET_ALL_CALENDAR_DATES_REQ";
export const CALENDAR_DATES_CREATED = "CALENDAR_DATES_CREATED";

export const DELETE_CALENDAR_DATE = "DELETE_CALENDAR_DATE";
export const DELETE_CALENDAR_DATE_FAIL = "DELETE_CALENDAR_DATE_FAIL";

export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_SENT = "GET_CARDS_SENT";
export const GET_CARDS_FAIL = "GET_CARDS_FAIL";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SENT = "GET_ALL_CATEGORIES_SENT";
export const GET_ALL_CATEGORIES_FAIL = "GET_ALL_CATEGORIES_FAIL";

export const FIRST_CATEGORY_CARDS_REQUEST = "FIRST_CATEGORY_CARDS_REQUEST";
export const ALL_CATEGORY_CARDS_REQUEST = "ALL_CATEGORY_CARDS_REQUEST";

export const GET_DISPLAY_CARDS = "GET_DISPLAY_CARDS";

export const DELETE_CARD = "DELETE_CARD";
export const DELETE_CARD_FAIL = "DELETE_CARD_FAIL";

export const ALL_CATEGORY_CARDS_RECIEVED = "ALL_CATEGORY_CARDS_RECIEVED";

export const CREATE_CARD = "CREATE_CARD";
export const CREATE_CARD_FAIL = "CREATE_CARD_FAIL";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const GET_FACES = "GET_FACES";
export const GET_FACES_FAIL = "GET_FACES_FAIL";

export const EDIT_CARD = "EDIT_CARD";
export const EDIT_CARD_FAIL = "EDIT_CARD_FAIL";

export const CREATE_COLUMN = "CREATE_COLUMN";
export const CREATE_COLUMN_FAIL = "CREATE_COLUMN_FAIL";

export const CATEGORY_DATA_RECIEVED = "CATEGORY_DATA_RECIEVED";

export const DELETE_COLUMN = "DELETE_COLUMN";
export const DELETE_COLUMN_FAIL = "DELETE_COLUMN_FAIL";

export const CATEGORIES_DATA_RECEIVED = "CATEGORIES_DATA_RECEIVED";

export const EDIT_COLUMN = "EDIT_COLUMN";
export const EDIT_COLUMN_FAIL = "EDIT_COLUMN_FAIL";

export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const SEARCH_RESULTS_FAIL = "SEARCH_RESULTS_FAIL";

export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SEARCH_RESULTS_RECEIVED = "GET_SEARCH_RESULTS_RECEIVED";

export const ADD_CLIP = "ADD_CLIP";
export const ADD_CLIP_FAIL = "ADD_CLIP_FAIL";

export const CALENDAR_CATEGORIES = "CALENDAR_CATEGORIES";
export const CALENDAR_CATEGORIES_FAIL = "CALENDAR_CATEGORIES_FAIL";

export const EDIT_CALENDAR_DATE = "EDIT_CALENDAR_DATE";
export const EDIT_CALENDAR_DATE_FAIL = "EDIT_CALENDAR_DATE_FAIL";

export const SHOW_EDIT_CARD = "SHOW_EDIT_CARD";
export const SHOW_CREATE_CARD = "SHOW_CREATE_CARD";

export const SHOW_EDIT_TASK = "SHOW_EDIT_TASK";

export const PROFILE_INPUTS = "PROFILE_INPUTS";
export const PROFILE_ERRORS = "PROFILE_ERRORS";
export const ALL_PROFILE_INPUTS = "ALL_PROFILE_INPUTS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const SET_TOKEN = "SET_TOKEN";
export const REACT_ROUTE_CHANGE = "REACT_ROUTE_CHANGE";

export const INITAL_STATE = "INITAL_STATE";

export const SHOW_COLUMN_EDIT = "SHOW_COLUMN_EDIT";
export const SHOW_CARD_CREATE = "SHOW_CARD_CREATE";

export const GET_PROFILE_ID = "GET_PROFILE_ID";
export const GET_PROFILE_ID_FAIL = "GET_PROFILE_ID_FAIL";

export const USER_CARDS_FAIL = "USER_CARDS_FAIL";
export const USER_CARDS = "USER_CARDS";
