import React from 'react'

class WindowDimensions extends React.Component {
  state = {
    height: null,
    width: null
  }

  componentDidMount = () => {
    // console.log('window mount')
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  componentDidUpdate = () => {
    // console.log('window updates')
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      nextState.height !== this.state.height ||
      nextState.width !== this.state.width
    ) {
      return true
    }
    return false
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if (this.props.height) {
      this.props.height(window.innerHeight)
    }
    if (this.props.width) {
      this.props.width(window.innerWidth)
    }
    if (this.props.setDimensions) {
      this.props.setDimensions(window.innerHeight, window.innerWidth)
    }
  }

  render () {
    return <div></div>
  }
}

export default WindowDimensions
