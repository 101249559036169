import React, { Fragment, unstable_Profiler as Profiler } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { logout } from "../../redux/actions/auth";
import OutsideAlerter from "./OutsideAlerter";
import { getProfile } from "../../redux/actions/profile";
import { getFaces } from "../../redux/actions/faces";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom";
import Account from "./Account";

import { ReactComponent as NavLinkBoard } from "./icons/NavLinkBoard.svg";
import { ReactComponent as NavLinkChronology } from "./icons/NavLinkChronology.svg";
import { ReactComponent as NavLinkMap } from "./icons/NavLinkMap.svg";
import { ReactComponent as NavLinkProducts } from "./icons/NavLinkProducts.svg";
import { ReactComponent as NavLinkPanel } from "./icons/NavLinkPanel.svg";

import { ReactComponent as SideNavLinkBoard } from "./icons/SideNavLinkBoard.svg";
import { ReactComponent as SideNavLinkChronology } from "./icons/SideNavLinkChronology.svg";
import { ReactComponent as SideNavLinkMap } from "./icons/SideNavLinkMap.svg";
import { ReactComponent as SideNavLinkPanel } from "./icons/SideNavLinkPanel.svg";
import { ReactComponent as SideNavLinkProducts } from "./icons/SideNavLinkProducts.svg";
import { ReactComponent as ArrowIcon } from "./icons/ArrowIcon.svg";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      position: "",
      avatar: "",

      avatarNum: "",
      width: "",
      ResponsiveNav: false,
      isListOpen: false,
      displayLinks: null,
      AccountResponsive: false,
      position: "",
      displayAccountPage: false,

      productLinkWidth: null,
      mapLinkWidth: null,
      boardLinkWidth: null,
      chronologyLinkWidth: null,
      panelLinkWidth: null,
      navlinkWidthArr: [],
      navWidthArr: [],

      sentRequest: false,

      scrollHeight: null,
    };
  }

  close = () => {
    this.setState({
      isListOpen: false,
      ResponsiveNav: false,
    });
  };

  setDisplayAccount = () => {
    this.setState({ displayAccountPage: !this.state.displayAccountPage });
  };

  componentDidUpdate(props) {
    if (this.state.sentRequest !== true && this.props.setToken) {
      // console.log("COMPONENT DID");
      getFaces();
      getProfile();

      this.setState({ sentRequest: true });
    }

    if (this.props.name !== "" && this.state.name === "")
      this.setState({ name: this.props.name });

    if (
      this.props.position !== "" &&
      this.props.position !== undefined &&
      this.state.position === ""
    )
      this.setState({ position: this.props.position });

    if (
      this.props.avatar !== "" &&
      this.props.avatar !== undefined &&
      this.state.avatar === "" &&
      this.props.faces !== undefined
    ) {
      let avatar = this.props.avatar;
      // console.log(avatar);
      if (this.props.faces.length !== 0)
        this.setState({
          avatar: this.props.faces[avatar].avatar,
        });
    }

    const { isListOpen } = this.state;

    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);

    // console.log();
    if (document.body.scrollHeight > this.state.scrollHeight)
      this.setState({ scrollHeight: document.body.scrollHeight });
  }

  navigationWidthDisplayLinks = (navWidthArr) => {
    let width = window.innerWidth - 700;

    if (navWidthArr == null) navWidthArr = this.state.navWidthArr;

    let index;
    if (navWidthArr) {
      for (let i = 0; i < 5; i++) {
        if (width < navWidthArr[i]) {
          index = i - 1;
          break;
        }
      }
    }
    this.setState({ displayLinks: index });
  };

  componentDidMount() {
    getFaces();
    getProfile();

    var timeOutFunctionId;
    this.updateWindowDimensions();
    this.setState({ width: window.outerWidth });

    window.addEventListener("resize", () => {
      clearTimeout(timeOutFunctionId);
      timeOutFunctionId = setTimeout(this.updateWindowDimensions, 100);
    });

    let navLinkArr = [];
    navLinkArr.length = 5;

    // build array
    let navArr = [170, 135, 146, 192, 143];
    let navWidthArr = [];

    for (let i = 0; i < 5; i++) {
      let navWidth = 0;
      for (let j = 0; j <= i; j++) {
        navWidth = navWidth + navArr[j];
      }
      navWidthArr.push(navWidth);
    }

    this.setState({ navWidthArr: navWidthArr });
    this.navigationWidthDisplayLinks(navWidthArr);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (Math.abs(window.outerWidth - this.state.width) > 20) {
      let width = Math.ceil(window.outerWidth / 20) * 20;
      this.setState({ width: width });

      if (width >= 700 && this.state.AccountResponsive !== true) {
        this.setState({ AccountResponsive: true });
      } else if (width < 700 && this.state.AccountResponsive !== false) {
        this.setState({ AccountResponsive: false });
      }
    }

    this.navigationWidthDisplayLinks();
  };

  setResponsiveTopNav = (display) => {
    if (display == false) {
      this.setState({ ResponsiveNav: display, displayAccountPage: false });
    }
    this.setState({ ResponsiveNav: display });
  };

  render() {
    let NavigationLinkNotAuth = (
      <div className="hidden lg:inline-flex" key={uuidv4()}>
        <Link
          key={uuidv4()}
          to="/Products"
          className="flex items-center px-5 border-t-2 border-transparent hoverNavigation"
        >
          <div className="tracking-wide mr-3 text-lg font-bold text-white">
            Products
          </div>
        </Link>

        <Link
          key={uuidv4()}
          to="/Map"
          className="flex items-center px-5 border-t-2 border-transparent hoverNavigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            className="h-16 p-2.5 w-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            />
          </svg>

          <div className="tracking-wide mr-3 text-lg font-bold text-white">
            Map
          </div>
        </Link>
      </div>
    );

    let NavigationLinkAuthenticated = [
      <Link
        key={uuidv4()}
        to="/Products"
        className="flex items-center px-5 border-t-2 border-transparent hoverNavigation"
      >
        <NavLinkProducts />
        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Products
        </div>
      </Link>,
      <Link
        key={uuidv4()}
        to="/Map"
        className="flex items-center px-5 border-t-2 border-transparent hoverNavigation"
      >
        <NavLinkMap />
        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Map
        </div>
      </Link>,
      <Link
        key={uuidv4()}
        to="/Board"
        className="flex items-center px-5 border-t-2 border-transparent hoverNavigation h-16"
      >
        <NavLinkBoard />
        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Board
        </div>
      </Link>,
      <Link
        key={uuidv4()}
        to="/Chronology"
        className="flex items-center px-5 border-t-2 border-transparent hoverNavigation h-16"
      >
        <NavLinkChronology />
        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Chronology
        </div>
      </Link>,
      <Link
        key={uuidv4()}
        to="/Panel"
        className="flex items-center px-5 border-t-2 border-transparent hoverNavigation h-16"
      >
        <NavLinkPanel />
        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Panel
        </div>
      </Link>,
    ];

    let NavigationLinks = [
      <Link
        key={uuidv4()}
        onClick={() => this.setResponsiveTopNav(false)}
        to="/Products"
        className="flex items-center p-3 my-1 border-t-2 border-transparent bg-white bg-opacity-0 hover:bg-opacity-5"
      >
        <SideNavLinkProducts />
        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Products
        </div>
      </Link>,
      <Link
        key={uuidv4()}
        onClick={() => this.setResponsiveTopNav(false)}
        to="/Map"
        className="flex items-center p-3 my-1 border-t-2 border-transparent bg-white bg-opacity-0 hover:bg-opacity-5"
      >
        <SideNavLinkMap />

        <div className="tracking-wide mr-3 text-lg font-bold text-white">
          Map
        </div>
      </Link>,

      <div key={uuidv4()}>
        {this.props.isAuthenticated && (
          <Link
            key={uuidv4()}
            onClick={() => this.setResponsiveTopNav(false)}
            to="/Board"
            className="flex items-center p-3 my-1 border-t-2 border-transparent bg-white bg-opacity-0 hover:bg-opacity-5"
          >
            <SideNavLinkBoard />
            <div className="tracking-wide mr-3 text-lg font-bold text-white">
              Board
            </div>
          </Link>
        )}
      </div>,

      <div key={uuidv4()}>
        {this.props.isAuthenticated && (
          <Link
            key={uuidv4()}
            onClick={() => this.setResponsiveTopNav(false)}
            to="/Chronology"
            className="flex items-center p-3 my-1 border-t-2 border-transparent bg-white bg-opacity-0 hover:bg-opacity-5"
          >
            <SideNavLinkChronology />

            <div className="tracking-wide mr-3 text-lg font-bold text-white">
              Chronology
            </div>
          </Link>
        )}
      </div>,
      <div key={uuidv4()}>
        {this.props.isAuthenticated && (
          <Link
            key={uuidv4()}
            onClick={() => this.setResponsiveTopNav(false)}
            to="/Panel"
            className="flex items-center p-3 my-1 border-t-2 border-transparent bg-white bg-opacity-0 hover:bg-opacity-5"
          >
            <SideNavLinkPanel />

            <div
              className="tracking-wide mr-3 
            text-lg font-bold text-white"
            >
              Panel
            </div>
          </Link>
        )}
      </div>,
    ];

    let NavigationSideBar = [];
    let NavigationBar = [];
    let linkIndexMax;

    if (this.state.displayLinks === undefined) linkIndexMax = 4;
    else linkIndexMax = this.state.displayLinks;

    for (let i = 0; i <= linkIndexMax; i++) {
      NavigationBar.push(NavigationLinkAuthenticated[i]);
    }

    if (this.state.displayLinks !== null) {
      NavigationSideBar.push(
        <div key={uuidv4()}>
          {this.props.name !== "" &&
            this.props.isAuthenticated &&
            !this.state.AccountResponsive && (
              <div className="flex flex-col my-2" key={uuidv4()}>
                <div
                  className={classNames(
                    "flex flex-col",
                    `${
                      this.state.displayAccountPage
                        ? "bg-white bg-opacity-5"
                        : ""
                    }`
                  )}
                  key={uuidv4()}
                >
                  <Account setDisplayAccount={this.setDisplayAccount} />

                  {this.state.displayAccountPage && (
                    <OutsideAlerter
                      display={this.setDisplayAccount}
                      style={{ zIndex: 100 }}
                      className="absolute"
                    >
                      <Link
                        onClick={() => {
                          this.setDisplayAccount();
                          this.setResponsiveTopNav(false);
                        }}
                        to="/Account"
                        className="flex text-lg items-center justify-center  border-white border-b border-t border-opacity-20 h-10 w-56 tracking-wide bg-white bg-opacity-5 hover:bg-opacity-20 text-white"
                      >
                        Account
                      </Link>
                      <Link
                        to="/"
                        onClick={() => {
                          logout();
                          this.setDisplayAccount();
                          this.setResponsiveTopNav(false);
                        }}
                        className="flex items-center justify-center  text-lg h-10 w-56 tracking-wide bg-white bg-opacity-5 hover:bg-opacity-20 text-white"
                      >
                        Logout
                      </Link>
                    </OutsideAlerter>
                  )}
                </div>
              </div>
            )}
        </div>
      );
      for (let i = this.state.displayLinks + 1; i < 5; i++) {
        NavigationSideBar.push(NavigationLinks[i]);
      }
    }

    if (this.props.faces)
      return (
        <div>
          <div className="h-16 flex justify-between bg-primary-purple-500">
            {/* Logo */}
            <Link to="/" className="flex items-center">
              {/* menu button */}
              <svg
                className="h-16 p-6 fill-white bg-primary-purple-300"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                enableBackground="new 0 0 512 512"
              >
                <path d="M186.2,139.6h139.6V0H186.2V139.6z M372.4,0v139.6H512V0H372.4z M0,139.6h139.6V0H0V139.6z M186.2,325.8h139.6V186.2H186.2 V325.8z M372.4,325.8H512V186.2H372.4V325.8z M0,325.8h139.6V186.2H0V325.8z M186.2,512h139.6V372.4H186.2V512z M372.4,512H512 V372.4H372.4V512z M0,512h139.6V372.4H0V512z" />
              </svg>

              {/* logo */}
              <div className="relative ml-8 mr-4 w-4">
                <div className="absolute -top-3 w-4 h-4 bg-primary-red-200 border-primary-red-200 rounded border"></div>
                <div className="absolute -top-1 left-2 w-4 h-4 border-primary-yellow-100 rounded border filter-blur"></div>
              </div>

              {/* Todoser */}
              <div className="tracking-wide text-lg font-bold text-white">
                Todo<span className="text-primary-purple-100 text-lg">ser</span>
              </div>
            </Link>
            <div className="inline-flex flex-wrap justify-center overflow-hidden mx-10">
              {/* Navigation */}
              {!this.props.isAuthenticated && NavigationLinkNotAuth}

              {/* Authenticated Navigation */}
              {this.props.isAuthenticated && NavigationBar}
            </div>
            {NavigationSideBar.length !== 0 && (
              <div
                style={{
                  width: `${
                    this.props.isAuthenticated
                      ? "200px"
                      : this.state.displayLinks === undefined
                      ? "200px"
                      : "0"
                  }`,
                }}
                className="flex justify-end"
              >
                {this.props.name !== "" &&
                  this.state.AccountResponsive &&
                  this.props.isAuthenticated && (
                    <div
                      style={{
                        right: `${
                          this.state.displayLinks === undefined ? "0" : "60px"
                        }`,
                      }}
                      className="absolute right-0"
                    >
                      <div className="">
                        <div className="relative">
                          <div
                            className={classNames(
                              "flex items-center ml-3 h-16",
                              `${
                                this.state.displayAccountPage
                                  ? "bg-white bg-opacity-5"
                                  : ""
                              }`
                            )}
                          >
                            <Account
                              setDisplayAccount={this.setDisplayAccount}
                            />

                          </div>
                        </div>

                        <div
                          className="absolute right-1  top-16"
                          style={{ width: 200, zIndex: 1000 }}
                        >
                          {this.state.displayAccountPage && (
                            <OutsideAlerter display={this.setDisplayAccount}>
                              <div className="bg-primary-purple-500">
                                <Link
                                  to="/Account"
                                  onClick={() => {
                                    this.setDisplayAccount();
                                  }}
                                  className="flex text-lg items-center justify-center  border-white border-b border-t border-opacity-20 h-10 tracking-wide bg-white bg-opacity-5 hover:bg-opacity-20 text-white"
                                >
                                  Account
                                </Link>
                                <Link
                                  to="/"
                                  onClick={() => {
                                    logout();
                                    this.setDisplayAccount();
                                  }}
                                  className="flex items-center justify-center text-lg  h-10 tracking-wide bg-white bg-opacity-5 hover:bg-opacity-20 text-white"
                                >
                                  Logout
                                </Link>
                              </div>
                            </OutsideAlerter>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Login */}
                {this.props.isAuthenticated == false && (
                  <Link
                    to="/Login"
                    className="absolute"
                    style={{
                      width: "150px",
                      right: `${
                        this.state.displayLinks === undefined ? "0" : "50px"
                      }`,
                    }}
                  >
                    <div
                      className="flex items-center px-5 border-t-2 border-transparent hoverNavigation w-36 h-16"
                      style={{ width: "150px" }}
                    >
                      <svg
                        height="490pt"
                        viewBox="0 -10 490.66667 490"
                        width="490pt"
                        stroke="white"
                        fill="white"
                        className="h-12 p-2.5 w-12"
                      >
                        <path d="m325.332031 251h-309.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h309.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                        <path d="m240 336.332031c-4.097656 0-8.191406-1.554687-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.636719l74.027344-74.023437-74.027344-74.027344c-6.25-6.25-6.25-16.386719 0-22.636719 6.253906-6.25 16.386719-6.25 22.636719 0l85.332031 85.335938c6.25 6.25 6.25 16.382812 0 22.632812l-85.332031 85.332032c-3.136719 3.160156-7.230469 4.714843-11.328125 4.714843zm0 0" />
                        <path d="m256 469.667969c-97.089844 0-182.804688-58.410157-218.410156-148.824219-3.242188-8.191406.808594-17.492188 9.023437-20.734375 8.191407-3.199219 17.515625.789063 20.757813 9.046875 30.742187 78.058594 104.789062 128.511719 188.628906 128.511719 111.742188 0 202.667969-90.925781 202.667969-202.667969s-90.925781-202.667969-202.667969-202.667969c-83.839844 0-157.886719 50.453125-188.628906 128.511719-3.265625 8.257812-12.566406 12.246094-20.757813 9.046875-8.214843-3.242187-12.265625-12.542969-9.023437-20.734375 35.605468-90.414062 121.320312-148.824219 218.410156-148.824219 129.386719 0 234.667969 105.28125 234.667969 234.667969s-105.28125 234.667969-234.667969 234.667969zm0 0" />
                      </svg>
                      <div
                        className="tracking-wide mr-3 text-lg font-bold text-white"
                        style={{ width: "50px" }}
                      >
                        Login
                      </div>
                    </div>
                  </Link>
                )}

                {this.state.displayLinks !== undefined && (
                  <div
                    style={{
                      width: `${!this.state.ResponsiveNav ? "0" : "200px"}`,
                    }}
                    className="overflow-hidden"
                  >
                    {NavigationSideBar.length !== 0 &&
                      !this.state.ResponsiveNav && (
                        <svg
                          onClick={() => this.setResponsiveTopNav(true)}
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 256 256"
                          className={classNames(
                            "absolute right-0",
                            `${
                              NavigationSideBar.length !== 0
                                ? "block"
                                : "hidden"
                            }`,

                            `${
                              NavigationSideBar.length === 4 &&
                              !this.props.isAuthenticated
                                ? "hidden"
                                : ""
                            }`,
                            "h-16 p-5 fill-white bg-primary-purple-300"
                          )}
                        >
                          <g>
                            <g>
                              <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093 		" />
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      )}

                    {this.state.ResponsiveNav && (
                      <AnimatePresence
                        className="overflow-hidden absolute bg-white right-0"
                        style={{
                          zIndex: 2000,
                          width: "300px",
                          height: `${this.state.scrollHeight}px`,
                        }}
                      >
                        <motion.div
                          initial={{
                            x: 300,
                            opacity: 0,
                          }}
                          exit={{
                            opacity: 0,
                            x: 300,
                          }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{
                            stiffness: 50,
                            duration: 0.3,
                            delay: 0,
                          }}
                          className="overflow-hidden"
                          style={{
                            height: `${this.state.scrollHeight}px`,
                            width: "300px",
                            marginRight: "-65px",
                          }}
                        >
                          <OutsideAlerter
                            display={(display) =>
                              this.setResponsiveTopNav(display)
                            }
                          >
                            <div
                              style={{
                                height: `${this.state.scrollHeight}px`,
                                width: "250px",
                                zIndex: 1000,
                              }}
                              className=" overflow-hidden absolute right-0  bg-gradient-to-l from-primary-purple-300 via-primary-purple-300 to-transparent z-30"
                            ></div>
                            <div
                              className="overflow-hidden absolute right-0 bg-primary-purple-300 z-50"
                              style={{
                                height: `${this.state.scrollHeight}px`,
                                width: "200px",
                                zIndex: 2000,
                              }}
                            >
                              {NavigationSideBar}
                            </div>
                          </OutsideAlerter>
                        </motion.div>
                      </AnimatePresence>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  let name = "";

  if (state.auth)
    if (state.auth.user)
      if (state.auth.user.name) {
        name = state.auth.user.name;
      } else if (state.auth.user.name.name) name = state.auth.user.name.name;

  let avatar = 0;
  let position;

  if (state.profile)
    if (state.profile.profile)
      if (state.profile.profile.user) {
        if (state.profile.profile.user.avatar)
          avatar = state.profile.profile.user.avatar;
        if (state.profile.profile.user.position) {
          position = state.profile.profile.user.position;
        }
      }

  if (typeof name !== "string") name = "";

  let ProfileName, ProfileAvatar, ProfilePosition, ProfileLocation;

  if (state.profile.profile)
    if (state.profile.profile.user) {
      if (state.profile.profile.user.user)
        ProfileName = state.profile.profile.user.user.name;
      ProfileAvatar = state.profile.profile.user.avatar;
      ProfilePosition = state.profile.profile.user.position;
      ProfileLocation = state.profile.profile.user.location;
      // console.log(
      //   "PROFLIE",
      //   ProfileAvatar,
      //   ProfileName,
      //   ProfilePosition,
      //   ProfileLocation
      // );
    }

  if (ProfileAvatar == undefined) ProfileAvatar = 0;

  return {
    name: name,
    position: ProfilePosition,
    avatar: ProfileAvatar,
    isAuthenticated: state.auth.isAuthenticated,
    faces: state.faces.faces,
    setToken: state.auth.setToken,
    error: state.profile.error,
  };
};

export default connect(mapStateToProps)(withRouter(NavigationBar));
