import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { PROFILE_UPDATED } from "../../redux/actionTypes";
import Particles from "react-particles-js";

import { ReactComponent as AccountIcon } from "./AccountIcon.svg";

const Account = () => {
  const profileUpdated = useSelector((state) => state.profile.profileUpdated);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (profileUpdated) {
    dispatch({ type: PROFILE_UPDATED });
  }

  const styleWrapper = {
    position: "relative",
    height: 800,
  };

  const styleContent = {
    position: " relative",
    zIndex: "100",
  };

  const styleBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "0",
  };

  return (
    <div style={styleWrapper}>
      <div style={styleContent}>
        <div className="flex justify-center h-full mx-10 mt-10 mb-16">
          {/* Logo */}
          <motion.div
            initial={{
              y: 10,
              scale: 1.5,
              opacity: 0,
            }}
            exit={{
              opacity: 1,
            }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            className="flex items-center mt-10"
          >
            {/* logo */}
            <div className="relative mr-4 w-20 h-8">
              <div className="absolute -top-6 w-16 h-16 bg-primary-red-200 border-primary-red-200 rounded-2xl border"></div>
              <div className="absolute top-1 left-8 w-16 h-16 border-primary-yellow-100 rounded-2xl border-2 filter-blur"></div>
            </div>
            {/* Todoser */}
            <div className="ml-5 tracking-wide font-bold text-7xl text-white">
              Todo<span className="text-primary-purple-100">ser</span>
            </div>
          </motion.div>
        </div>

        <div className="tracking-wide font-bold  text-white text-5xl mt-10 mb-10 ml-12 my-6">
          Account
        </div>

        <div className="m-12">
          <Link
            to="/Profile"
            className="flex items-start justify-between rounded-2xl  border-opacity-5 bg-opacity-5 bg-white border-white border hover:border-opacity-10 hover:bg-opacity-10 duration-200 cursor-pointer"
          >
            <div className="m-10">
              <div className="tracking-wide font-semibold text-white text-2xl">
                Personal Information
              </div>
              <div className="mt-5  text-white text-opacity-60 text-lg flex-shrink">
                Basic information, like your name and photo.
              </div>
            </div>
            <div>
              <AccountIcon />
            </div>
          </Link>
        </div>
      </div>

      <Particles style={styleBackground}></Particles>
    </div>
  );
};

export default Account;
