import React from "react";
import { connect } from "react-redux";
import CalendarDay from "../CalendarDay";
import AddCalendarTask from "./AddCalendarTask";
import "../calendarStyle.css";
import { getCalendarDates } from "../../../../redux/actions/calendar";
import OutsideAlerter from "../../OutsideAlerter";
import { v4 as uuidv4 } from "uuid";
import EditCalendarTask from "./EditCalendarTask";
import * as ActionTypes from "../../../../redux/actionTypes";
import { AnimatePresence } from "framer-motion";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthTasksArr: [],
      width: "",
    };
  }

  componentDidMount() {
    getCalendarDates();

    window.addEventListener(
      "load",
      this.setState({ width: window.innerWidth - 100 })
    );
    window.addEventListener(
      "resize",
      this.setState({ width: window.innerWidth - 100 })
    );
  }

  componentDidUpdate() {
    if (this.props.calendarDates === []) {
      getCalendarDates();
    }
    if (this.props.calendarDates) {
      this.createMonthTasks();
    }
  }

  createMonthTasks = () => {
    let dayArr = [];

    // arr of calendar dates
    for (let date of this.props.calendarDates) {
      if (
        this.props.category === date.category ||
        this.props.category === null
      ) {
        const time = new Date(date.date);

        let monthInArr;
        let monthIndex;

        dayArr.map((month, index) => {
          if (time.getMonth() === month.month) {
            monthInArr = true;
            monthIndex = index;
          }
        });

        // if month in arr
        if (monthInArr) {
          let dayInArray = false;

          // day in array
          dayArr[monthIndex].day.map((day) => {
            if (day.day === time.getDate()) {
              day.tasks.push({
                category: date.category,
                color: date.color,
                summary: date.summary,
                task: date.task,
                time: date.time,
                date: date.date,
                user: date.user,
                id: date._id,
              });

              dayInArray = true;
            }
          });

          // add day to array
          if (!dayInArray) {
            dayArr[monthIndex].day.push({
              day: time.getDate(),
              tasks: [
                {
                  category: date.category,
                  color: date.color,
                  summary: date.summary,
                  task: date.task,
                  time: date.time,
                  date: date.date,
                  user: date.user,
                  id: date._id,
                },
              ],
            });
          }
        } else {
          // add month
          dayArr.push({
            month: time.getMonth(),
            day: [
              {
                day: time.getDate(),
                tasks: [
                  {
                    category: date.category,
                    color: date.color,
                    summary: date.summary,
                    task: date.task,
                    date: date.date,
                    time: date.time,
                    user: date.user,
                    id: date._id,
                  },
                ],
              },
            ],
          });
        }
      }
    }

    let calendarArray = [];
    let monthArray = [];

    // arr of calendar tasks
    dayArr.map((month) => {
      monthArray.push(month.month);

      calendarArray.push(
        month.day.map((tasks) => {
          return (
            <CalendarDay
              key={uuidv4()}
              width={this.state.width}
              tasks={tasks.tasks}
              month={month.month}
              date={tasks.day}
            />
          );
        })
      );
    });

    return calendarArray;
  };

  render() {
    let calendarArray = [];

    if (this.props.calendarDates) {
      calendarArray = this.createMonthTasks();
    }

    return (
      <div
        ref={this.calendarRef}
        style={{
          height: `${1200}px`,
          overflowY: "scroll",
        }}
        className="my-10"
      >
        {calendarArray.length === 0 && (
          <div className="ml-10 font-bold text-white opacity-50 capitalize text-xl">
            No calendar dates
          </div>
        )}

        {/* Add Calendar Task */}
        <div className="absolute w-screen">
          <AnimatePresence>
            {this.props.showAddCalendarTask && (
              <div className=" flex justify-center">
                <OutsideAlerter
                  display={() => this.props.setShowAddCalendarTask()}
                >
                  <AddCalendarTask
                    display={() => this.props.setShowAddCalendarTask()}
                  />
                </OutsideAlerter>
              </div>
            )}
          </AnimatePresence>
        </div>

        {/* Edit Calendar Task */}
        <div className="absolute w-screen">
          <AnimatePresence>
            {this.props.editTask.empty === false && (
              <div className=" flex justify-center">
                <OutsideAlerter display={() => this.props.updateEditTask()}>
                  <EditCalendarTask
                    display={() => this.props.updateEditTask()}
                    task={this.props.editTask}
                  />
                </OutsideAlerter>
              </div>
            )}
          </AnimatePresence>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 mx-5 mt-5 mb-10">
          {calendarArray}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    calendarDates: state.calendar.calendarDates,
    editTask: state.calendarMonth.editTask,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEditTask: () =>
      dispatch({
        type: ActionTypes.SHOW_EDIT_TASK,
        payload: {
          summary: "",
          category: "",
          task: "",
          color: "",
          time: "",
          id: "",
          date: "",
          empty: true,
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
